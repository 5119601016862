import utils from "@/stores/utils";
import axios from '@/plugins/axios';
import storeUtils from '@/stores/utils';
import Decimal from 'decimal.js';
import Moment from 'moment';

const state = {
    invoice: null,
    invoice_file_url: null,
    saving_invoice_errors: {},
    saving_invoice: false,
    parsing_invoice: false,
    parsing_invoice_errors: null,
    parsing_mismatch: false,
    invoice_data: null,
};

const mutations = {

    updateInvoice(state, invoice) {
        state.invoice = invoice;
        state.invoice_file_url = invoice?.invoice_file;
        state.parse_invoice = false;
        state.parsing_invoice_errors = null;
        state.parsing_mismatch = false;
        state.saving_invoice = false;
        state.saving_invoice_errors = {};
    },

    updateSavingInvoice(state) {
        state.saving_invoice = true;
        state.saving_invoice_errors = {};
    },

    updateSavingInvoiceErrors(state, saving_invoice_errors) {
        state.saving_invoice_errors = saving_invoice_errors;
        state.saving_invoice = false;
    },

    updateInvoiceFile(state, invoice_file) {
        if (invoice_file) {
            state.invoice.invoice_file = invoice_file;
            state.invoice_file_url = URL.createObjectURL(invoice_file);
        } else {
            if (state.invoice_file_url) {
                state.remove_invoice_file = true;
            }
            state.invoice.invoice_file = null;
            state.invoice_file_url = null;
        }
    },

    updateParsingInvoice(state, parsing_invoice) {
        state.parsing_invoice = parsing_invoice;
    },

    updateParsingInvoiceErrors(state, parsing_invoice_errors) {
        state.parsing_invoice_errors = parsing_invoice_errors;
    },

    updateInvoiceData(state, invoice_data) {
        let newinvoice = { ...state.invoice };
        newinvoice[invoice_data.key] = invoice_data.value;

        if (state.invoice['total_excl_vat'] && state.invoice['total_vat']) {
            newinvoice['total_incl_vat'] = (new Decimal(state.invoice['total_excl_vat']).plus(new Decimal(state.invoice['total_vat']))).toNumber();
        } else {
            newinvoice['total_incl_vat'] = null;
        }
        state.invoice = newinvoice;
    },

    async forceupdateInvoiceWithParsedData(state, parsed) {
        let newinvoice = { ...state.invoice };

        if (state.invoice_data.invoice_id) {
            newinvoice.invoice_id = state.invoice_data.invoice_id;
        }
        if (state.invoice_data.invoice_date) {
            newinvoice.invoice_date = state.invoice_data.invoice_date;
        }
        if (state.invoice_data.due_date) {
            newinvoice.due_date = state.invoice_data.due_date;
        }
        if (state.invoice_data.total_excl_vat) {
            newinvoice.total_excl_vat = state.invoice_data.total_excl_vat;
        }
        if (state.invoice_data.total_incl_vat) {
            newinvoice.total_incl_vat = state.invoice_data.total_incl_vat;
        }
        if (state.invoice_data.total_vat) {
            newinvoice.total_vat = state.invoice_data.total_vat;
        }
        state.invoice = newinvoice;
    },

    async updateInvoiceWithParsedData(state, invoice_data) {
        state.invoice_data = invoice_data;
        state.parsing_mismatch = false;

        for (let key of ['total_excl_vat', 'total_incl_vat', 'total_vat', 'invoice_id', 'invoice_date', 'due_date']) {
            if (invoice_data[key] && state.invoice[key]) {
                if (invoice_data[key] != state.invoice[key]) {
                    state.parsing_mismatch = true;
                    return true;
                }
            }
        }
        let new_invoice = { ...state.invoice };
        for (let key of ['total_excl_vat', 'total_incl_vat', 'total_vat', 'invoice_id', 'invoice_date', 'due_date']) {
            if (invoice_data[key]) {
                new_invoice[key] = invoice_data[key];
            }
        }
        state.invoice = new_invoice;
    },
};


const actions = {

    async saveInvoice({ commit, dispatch, state, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.change_invoice") === -1) {
            return;
        }

        let method = state.invoice.id ? axios.patch : axios.post;
        let url = `/api/customerorders/${state.invoice.order}/invoices/`;
        if (state.invoice.id) {
            url = `${url}${state.invoice.id}/`;
        }

        let data = new FormData();
        let options = {};
        let invoice = { ...state.invoice };

        if (invoice.invoice_file && typeof invoice.invoice_file !== 'string') {
            options.headers = { 'Content-Type': 'multipart/form-data' };
            data.append('invoice_file', invoice.invoice_file);
        }
        for (let key of ['invoice_id', 'billed', 'invoice_date', 'due_date', 'total_excl_vat', 'total_vat', 'total_incl_vat', 'biller', 'order', 'notes',]) {
            if (invoice[key] !== null && invoice[key] !== undefined) {
                data.append(key, invoice[key]);
            }
        }


        try {
            commit('updateSavingInvoice');
            const response = await method(url, data, options);

            dispatch('session/fetchStats', null, { root: true });
            dispatch('billingstats/fetchAllStats', null, { root: true });
            commit('updateSavingInvoice', false);
            return response.data;
        } catch (xhr_error) {
            const error = storeUtils.handleError(xhr_error);
            commit('updateSavingInvoiceErrors', error.details);
            throw error;
        }
    },



    async parseInvoiceFile({ commit, dispatch, state }, params) {
        commit("updateInvoiceFile", params.invoice_file);
        commit("updateParsingInvoice", true);
        let url = "/api/billing/parseinvoice/";
        try {
            let data = new FormData();
            data.append("invoice_file", params.invoice_file);
            let response = await axios.post(url, data);
            commit("updateParsingInvoice", false);
            await commit("updateInvoiceWithParsedData", response.data);
            return state.parsing_mismatch;

        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateParsingInvoiceErrors", error.details);
            commit("updateParsingInvoice", false);
            throw error;
        }
    },

    async init({ commit, dispatch, state }, params) {
        await commit('updateInvoice', {
            invoice_id: null,
            invoice_date: null,
            due_date: null,
            ...(params?.invoice || {})
        });
        commit('updateParsingInvoice', false);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
