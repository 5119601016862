<template>
    <ModalContent :help-content-id="`popup-deliverysite-${current_entity_profile}`">
        <template slot="title">{{ title }}</template>
        <template slot="content" v-if="deliverysite">
            <Warning :elevation="2" v-if="deliverysite.customer_data?.external && isNew">
                {{ $translate("external_customer_deliverysite_instructions") }}
            </Warning>

            <v-alert
                outlined
                border="left"
                type="warning"
                class="pa-5"
                v-if="deliverysite.entity_data && deliverysite.entity_data.archived"
            >
                {{
                    $gettext(
                        "Customer has been archived, this delivery site cannot be modified"
                    )
                }}
            </v-alert>
            <v-alert outlined border="left" type="warning" class="pa-5" v-if="readonly">
                <template
                    v-if="
                        deliverysite.entity && deliverysite.entity !== current_entity_id
                    "
                >
                    {{ $translate("readonly_other_entity") }}
                </template>
                <template
                    v-else-if="
                        deliverysite.customer &&
                        deliverysite.customer_data.owner !== current_entity_id
                    "
                >
                    {{ $translate("readonly_customer") }}
                </template>
                <template v-else-if="deliverysite?.stock">
                    {{ $translate("readonly_stock") }}
                </template>
                <template v-else-if="deliverysite?.archived">
                    {{ $translate("readonly_archived") }}
                </template>
            </v-alert>
            <h6 class="text-overline font-weight-bold">Information</h6>

            <v-text-field
                v-model="deliverysite.name"
                autofocus
                :error-messages="deliverysite_errors.name"
                :label="$gettext('Name (*)')"
                :disabled="readonly"
            />
            <v-textarea
                v-model="deliverysite.instructions"
                :error-messages="deliverysite_errors.instructions"
                :label="$translate('instructions')"
                name="instruction"
                rows="3"
                :disabled="readonly"
            />
            <AddressForm
                v-model="deliverysite.address"
                class="mt-8"
                :title="$gettext('Address')"
                :errors="deliverysite_errors.address"
                :readonly="readonly"
            />
        </template>
        <template slot="bottom">
            <template v-if="!readonly">
                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                    ghost
                    :text="$gettext('Cancel')"
                    @click="hideModal"
                />
                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                    :text="save_text"
                    @click="saveInformationClicked"
                />
            </template>
            <template v-else>
                <Button
                    ghost
                    :text="$gettext('Close')"
                    @click="hideModal"
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                />
            </template>
        </template>
    </ModalContent>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    computed: {
        ...mapState("editdeliverysite", [
            "deliverysite",
            "deliverysite_saving",
            "deliverysite_errors",
        ]),
        readonly() {
            if (!this.deliverysite) {
                return true;
            }
            return !!(
                this.deliverysite.stock ||
                this.deliverysite.archived ||
                (this.deliverysite.entity &&
                    this.deliverysite.entity !== this.current_entity_id) ||
                (this.deliverysite.customer_data?.owner &&
                    this.deliverysite.customer_data.owner !== this.current_entity_id)
            );
        },
        typesChoices() {
            return [
                { text: this.$gettext("Company"), value: "company" },
                { text: this.$gettext("Individual"), value: "individual" },
            ];
        },
        isNew() {
            return !this.deliverysite.id;
        },
        save_text() {
            if (!this.isNew) {
                return this.$gettext("Save informations");
            }
            return this.$gettext("Create site");
        },
        title() {
            return this.params.deliverysite.id
                ? this.$gettext("Edit delivery address")
                : this.$gettext("New delivery site");
        },
    },
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        await this.init({ deliverysite: this.params.deliverysite });
    },
    methods: {
        ...mapMutations("editdeliverysite", ["updateDeliverySite"]),
        ...mapActions("editdeliverysite", ["saveDeliverySite", "init"]),
        failedSavingInformations(error) {
            this.$swal({
                title: this.$gettext("Failed saving delivery site"),
                html: this.$utils.resultToSwalContent(this, error),
                type: "error",
            });
        },
        informationsSaved(response) {
            if (response.status == 201) {
                this.addAlert(this.$gettext("Delivery site created"));
            }
            this.hideModal({ result: response.data });
        },
        saveInformationClicked() {
            this.saveDeliverySite({ instance: this.deliverysite })
                .then(this.informationsSaved)
                .catch(this.failedSavingInformations);
        },
    },
    i18n: {
        messages: {
            en: {
                readonly_stock:
                    "This delivery site is linked to a warehouse, informations cannot be updated. Please update the warehouse instead.",
                readonly_archived:
                    "This delivery site cannot be modified because it has been archived.",
                readonly_customer:
                    "This delivery site cannot be modified because it is managed by your customer. Contact him to make changes.",
                readonly_other_entity:
                    "This delivery site cannot be modified because it is managed by your customer. Contact him to make changes.",
                external_customer_deliverysite_instructions:
                    "The customer manages their delivery sites. You should avoid creating a delivery site yourself and instead ask the customer to create it on their end.",
                instructions: "Delivery instructions",
            },
            fr: {
                readonly_stock:
                    "Cette adresse de livraison est lié à un entrepôt, les informations ne peuvent pas être modifiées directement. Veuillez modifier l'entrepôt à la place.",
                readonly_archived:
                    "Cette adresse de livraison ne peut pas être modifié car il a été archivé.",
                readonly_customer:
                    "Cette adresse de livraison ne peut pas être modifié car elle n'est géré par votre entreprise.",
                readonly_other_entity:
                    "Cette adresse de livraison ne peut pas être modifié car il ne vous appartient pas. Contactez son propriétaire pour effectuer des modifications.",
                external_customer_deliverysite_instructions:
                    "Le client gère ses adresses de livraison. Vous devriez éviter de créer une adresse de livraison vous-même et demander au client de la créer de son côté.",
                instructions: "Instructions de livraison",
            },
        },
    },
};
</script>
