<template>
    <v-row class="align-center">
        <v-col cols="12" md="6">
            <div class="d-flex align-center">
                <v-autocomplete
                    v-if="editable"
                    :clearable="canPriceBeEdited"
                    :disabled="!!productprovider.id"
                    :error-messages="errors.provider"
                    :items="providersChoices"
                    :label="$gettext('Provider')"
                    :menu-props="{ closeOnClick: true }"
                    :required="true"
                    :value="productprovider.provider"
                    @input="updateProviderId($event)"
                    class="flex-grow-1"
                    hide-details
                    ref="provider"
                />

                <Field
                    v-else
                    :distabled="true"
                    class="flex-grow-1"
                    :value="productprovider?.provider_data?.name"
                    :label="$gettext('Provider')"
                    :disabled="true"
                    :error-messages="errors.provider"
                    :options="providersChoices"
                />
                <v-btn
                    text
                    v-if="productprovider.provider"
                    @click="
                        routerPush({
                            name: 'provider',
                            params: { provider_id: productprovider.provider },
                        })
                    "
                    ><v-icon>mdi-open-in-new</v-icon></v-btn
                >
            </div>
        </v-col>
        <v-col cols="12" md="6">
            <div class="d-flex align-center">
                <v-text-field
                    class="flex-grow-1"
                    :value="productprovider.price"
                    :label="$gettext('Buying price (in euros, optional)')"
                    type="number"
                    step="0.1"
                    :clearable="canPriceBeEdited"
                    hide-details=""
                    :disabled="!canPriceBeEdited"
                    :error-messages="errors.price"
                    @change="updateProviderProductPrice(parseFloat($event))"
                />
                <v-btn text @click="$emit('remove')" v-if="canBeDeleted"
                    ><v-icon>delete</v-icon></v-btn
                >
            </div>
        </v-col>
        <template v-if="errors.non_field_errors">
            <Warning :key="error" v-for="error in errors.non_field_errors">
                {{ error }}
            </Warning>
        </template>
    </v-row>
</template>

<script>
import ProviderEdit from "@/modals/provider";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        productprovider: {
            type: Object,
            required: true,
        },
        productproviders: {
            type: Array,
            required: true,
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
        providers: {
            type: Array,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        allowCreateProvider: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        updateProviderId(provider_id) {
            if (provider_id == "new") {
                this.showModal({
                    component: ProviderEdit,
                    params: {
                        provider: {},
                    },
                }).then((provider) => {
                    if (provider) {
                        this.$emit("provider-created", provider);
                    } else {
                        this.$refs.provider.internalValue = this.productprovider.provider;
                        return;
                    }
                    this.$emit("input", {
                        ...this.productprovider,
                        provider: provider.id,
                    });
                });
            } else {
                this.$emit("input", { ...this.productprovider, provider: provider_id });
            }
        },
        updateProviderProductPrice(price) {
            if (!price) {
                price = null;
            }
            this.$emit("input", { ...this.productprovider, price });
        },
    },
    data() {
        return {
            provider_id: this.productprovider.provider,
        };
    },
    computed: {
        providersChoices() {
            if (this.productprovider?.id) {
                return [
                    {
                        isDisabled: true,
                        value: this.productprovider.provider,
                        text: this.productprovider.provider_data.name,
                    },
                ];
            }

            let choices = this.providers.map((item) => ({
                value: item.id,
                text: item.name,
            }));

            if (this.allowCreateProvider) {
                return [
                    {
                        value: "new",
                        text: this.$gettext("Create new provider"),
                    },
                ].concat(choices);
            }
            return choices;
        },
        canBeDeleted() {
            return this.editable && this.userHasPermission("catalog.delete_catalogentry");
        },
        canPriceBeEdited() {
            if (this.productprovider.id) {
                return (
                    this.editable && this.userHasPermission("catalog.change_catalogentry")
                );
            }
            return (
                this.editable &&
                this.productprovider.provider &&
                this.userHasPermission("catalog.add_catalogentry")
            );
        },
    },
};
</script>
