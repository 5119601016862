var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center w-100 h-100"
  }, [_c('File', {
    ref: "file",
    on: {
      "input": function input($event) {
        return _vm.fileSelected($event);
      }
    }
  }), _c('DropZone', {
    staticClass: "h-100 w-100",
    attrs: {
      "max-files": 1,
      "dragText": _vm.dragText
    },
    on: {
      "files-dropped": _vm.filesSelected,
      "drop-error": function dropError($event) {
        return _vm.dropErrorMessage($event);
      }
    }
  }, [_vm.loading ? _c('Loading', {
    staticClass: "w-100",
    attrs: {
      "absolute": false,
      "size": 200,
      "label": _vm.loadingText
    }
  }) : !_vm.value ? _c('Placeholder', {
    attrs: {
      "no-padding": true,
      "title": _vm.placeholderTitle,
      "description": _vm.placeholderDescription,
      "actions": [{
        text: _vm.placeholderActionText,
        action: 'selectfile',
        main: true
      }]
    },
    on: {
      "selectfile": _vm.selectFileClicked
    }
  }) : _c('div', {
    staticClass: "w-100"
  }, [_c('iframe', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.value,
      "height": "100%",
      "width": "100%"
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }