<template>
    <v-alert
        border="left"
        colored-border
        :color="defaultColor"
        :elevation="elevation"
        class="text-body-2 mb-4"
        :type="type"
        :icon="icon || 'info'"
    >
        <div class="d-md-flex d-block pb-1">
            <div class="flex-grow-1 mb-2 pr-0">
                <slot />
            </div>
            <div class="ml-0 ml-sm-4">
                <slot name="button" />
            </div>
        </div>
    </v-alert>
</template>

<script>
export default {
    props: {
        elevation: {
            type: Number,
            default: 0,
        },
        icon: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
    },
    computed: {
        defaultColor() {
            if (this.color) {
                return this.color;
            }
            return this.type || "primary";
        },
    },
};
</script>
