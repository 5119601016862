var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h6', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Product metadata")]), _c('v-row', [_c('v-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('Select', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.product.producttype,
      "label": _vm.$gettext('Product type'),
      "clearable": _vm.isCustomer,
      "disabled": !_vm.isCustomer,
      "error-messages": _vm.productSavingErrors.producttype,
      "options": _vm.producttypesChoices
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('producttype-changed', $event);
      }
    }
  })], 1), _vm.producttype ? _vm._l(_vm.getProductTypeFields(), function (field) {
    return _c('v-col', {
      key: field.code,
      staticClass: "pt-2",
      attrs: {
        "cols": "6",
        "sm": "4"
      }
    }, [field.type == 'choices' ? _c('Select', {
      "class": field.required ? 'font-weight-bold' : null,
      attrs: {
        "value": _vm.product.metadata[field.code],
        "label": _vm.fieldLabel(field),
        "clearable": _vm.isCustomer,
        "disabled": !_vm.isCustomer,
        "required": field.required,
        "error-messages": _vm.productSavingErrors.metadata ? _vm.productSavingErrors.metadata[field.code] : null,
        "options": _vm._f("fieldOptions")(field)
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('metadata-changed', {
            code: field.code,
            value: $event
          });
        }
      }
    }) : field.type == 'boolean' ? _c('v-select', {
      attrs: {
        "value": _vm.product.metadata[field.code],
        "label": _vm.fieldLabel(field),
        "clearable": _vm.isCustomer,
        "disabled": !_vm.isCustomer,
        "required": field.required,
        "error-messages": _vm.productSavingErrors.metadata ? _vm.productSavingErrors.metadata[field.code] : null,
        "items": [{
          text: _vm.$gettext('Yes'),
          value: true
        }, {
          text: _vm.$gettext('No'),
          value: false
        }]
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('metadata-changed', {
            code: field.code,
            value: $event
          });
        }
      }
    }) : _c('v-text-field', {
      "class": field.required ? 'font-weight-bold' : null,
      attrs: {
        "value": _vm.product.metadata[field.code],
        "label": _vm.fieldLabel(field),
        "clearable": _vm.isCustomer,
        "disabled": !_vm.isCustomer,
        "required": field.required,
        "format": _vm.fieldFormatForField(field),
        "type": _vm.fieldTypeForField(field),
        "error-messages": _vm.productSavingErrors.metadata ? _vm.productSavingErrors.metadata[field.code] : null
      },
      on: {
        "input": function input($event) {
          return _vm.$emit('metadata-changed', {
            code: field.code,
            value: $event
          });
        }
      }
    })], 1);
  }) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }