import qs from "qs";
import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import documents from "./submodules/documents";
import invitations from "./submodules/invitations";
import orders from "./submodules/orders";
import invoices from "./submodules/invoices";
import store from "@/stores/store";

const state = {
    provider: null,
    provider_loading: false,
    provider_loading_errors: null,
    provider_stats: null,

    section: null,

    receipts: [],
    receipts_count: 0,
    receipts_filters: { limit: 20, offset: 0, ordering: "-receipt_date" },
    receipts_loaded: false,
    receipts_loading: false,
    receipts_loading_errors: null,

    purchaseprices: [],
    purchaseprices_count: 0,
    purchaseprices_filters: { offset: 0, limit: 20 },
    purchaseprices_loaded: false,
    purchaseprices_loading: false,
    purchaseprices_loading_errors: null,

    importing_products: [],

    provider_deleting: false,

    creating_sales_catalog: false,
    assignedcatalogs: null,
};

const mutations = {
    updateProvider(state, provider) {
        if (state.provider && provider && state.provider.id == provider.id) {
            state.provider = provider;
            return;
        }
        state.provider = provider;
        state.provider_stats = null;

        state.receipts = [];
        state.receipts_count = 0;
        state.receipts_loaded = false;
        state.receipts_loading = false;
        state.receipts_loading_errors = null;

        state.purchaseprices = [];
        state.purchaseprices_count = 0;
        state.purchaseprices_loaded = false;
        state.purchaseprices_loading = false;
        state.purchaseprices_loading_errors = null;

        if (!provider) {
            state.receipts_filters = { offset: 0, limit: state.receipts_filters.limit };
            state.purchaseprices_filters = {
                offset: 0,
                limit: state.purchaseprices_filters.limit,
            };
            return;
        }

        state.receipts_filters = {
            provider: provider.id,
            offset: 0,
            limit: state.receipts_filters.limit,
            ordering: "-receipt_date",
        };
        state.purchaseprices_filters = {
            provider: provider.id,
            offset: 0,
            include_archived_providers: provider.archived,
            limit: state.purchaseprices_filters.limit,
            ordering: "name",
        };
    },

    updateReceipts(state, receipts) {
        state.receipts = receipts;
    },
    updateReceiptsCount(state, count) {
        state.receipts_count = count;
    },
    updateReceiptsFilters(state, filters) {
        state.receipts_filters = Object.assign(filters || {}, {
            provider: state.provider.id,
        });
    },

    updateReceiptsLoading(state, loading) {
        state.receipts_loading = loading;
    },
    updateReceiptsLoadingErrors(state, errors) {
        state.receipts_loading_errors = errors;
    },

    updateProviderLoading(state, loading) {
        state.provider_loading = loading;
    },
    updateProviderLoadingErrors(state, errors) {
        state.provider_loading = false;
        state.provider_loading_errors = errors;
    },

    updateProviderDeleting(state, deleting) {
        state.provider_deleting = deleting;
    },

    updatePurchasePrices(state, purchaseprices) {
        state.purchaseprices = purchaseprices;
    },
    updatePurchasePricesFilters(state, purchaseprices_filters) {
        state.purchaseprices_filters = Object.assign(
            { provider: state.provider.id },
            purchaseprices_filters
        );
    },
    updatePurchasePricesCount(state, purchaseprices_count) {
        state.purchaseprices_count = purchaseprices_count;
    },
    updatePurchasePricesLoading(state, loading) {
        state.purchaseprices_loading = loading;
    },
    updatePurchasePricesLoadingErrors(state, errors) {
        state.purchaseprices_loading_errors = errors;
    },

    updateProviderStats(state, provider_stats) {
        state.provider_stats = provider_stats;
    },
    updateSection(state, section) {
        state.section = section;
    },
    addImportingProducts(state, products) {
        state.importing_products = state.importing_products.concat(products);
    },
    removeImportingProducts(state, products) {
        state.importing_products = state.importing_products.filter((product) => {
            return !products.includes(product);
        });
    },

    updateCreatingSalesCatalog(state, creating) {
        state.creating_sales_catalog = creating;
    },

    updateAssignedCatalogs(state, assignedcatalogs) {
        state.assignedcatalogs = assignedcatalogs;
    },
};

const actions = {
    exportToCSV({ commit, dispatch, state }, params) {

        let filters = {
            format: "csv",
            limit: 1000,
            provider: state.provider.id,
        };
        const queryparams = qs.stringify(filters, { arrayFormat: "repeat" });

        window.open(`/api/providercatalogentries/?${queryparams}`, "_blank");
    },

    fetchPurchasePrices({ commit, dispatch, state }, params) {


        commit("updatePurchasePricesLoading", true);
        commit("updatePurchasePricesLoadingErrors", null);

        const catalog_type = state.provider.entity ? "sell" : "buy";
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/providercatalogentries/`, {
                    params: { ...state.purchaseprices_filters, provider: state.provider.id, catalog_type },
                })
                .then((response) => {
                    commit("updatePurchasePrices", response.data.results);
                    commit("updatePurchasePricesCount", response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updatePurchasePricesLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updatePurchasePricesLoading", false);
                });
        });
    },

    fetchReceipts({ commit, dispatch, state }, params) {

        commit("updateReceiptsLoading", true);
        commit("updateReceiptsLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/receipts/", {
                    params: state.receipts_filters,
                })
                .then((response) => {
                    commit("updateReceiptsLoading", false);

                    commit("updateReceipts", response.data.results);
                    commit("updateReceiptsCount", response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updateReceiptsLoading", false);
                    commit("updateReceiptsLoadingErrors", error.details);
                    reject(error);
                });
        });
    },

    fetchProvider({ commit, dispatch, state }, params) {

        commit("updateProviderLoading", true);
        commit("updateProviderLoadingErrors", null);

        let url = `/api/providers/${state.provider.id}/`;

        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((response) => {
                    commit("updateProvider", response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateProviderLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch("fetchProviderStats");
                    commit("updateProviderLoading", false);
                });
        });
    },

    deleteProvider({ commit, dispatch, state }, params) {

        commit("updateProviderDeleting", true);

        const url = `/api/providers/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios
                .delete(url)
                .then((response) => {
                    commit("updateProviderDeleting", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    commit("updateProviderDeleting", false);
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },


    fetchProviderStats({ commit, dispatch, state }, params) {
        axios.get(`/api/providers/${state.provider.id}/stats/`).then((response) => {
            commit("updateProviderStats", response.data);
        });
    },


    updateProductsPrices({ commit, dispatch, state }, prices) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/providers/${state.provider.id}/productprices/`, prices)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    async createSalesCatalog({ commit, dispatch, state }, params) {
        commit("updateCreatingSalesCatalog", true);
        try {
            const response = await axios.post(`/api/providers/${state.provider.id}/createsalescatalog/`);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit("updateCreatingSalesCatalog", false);
            dispatch("fetchAssignedCatalogs");
        }
    },

    async inviteProvider({ commit, dispatch, state }, params) {

        try {
            await dispatch("invitations/saveInvitation", {
                data: {
                    provider: state.provider.id,
                    email_or_safetee_id: params.email_or_safetee_id,
                }
            });
        } finally {

            dispatch("invitations/fetchInvitations");
        }
    },

    async cancelProviderInvitation({ commit, dispatch, state }, params) {

        try {
            await dispatch("invitations/deleteInvitation", {
                instance: state.invitations.invitations[0],
            });
        } finally {

            dispatch("invitations/fetchInvitations");
        }
    },

    async deleteCatalogEntry({ commit, dispatch, state }, params) {

        try {
            await axios.delete(`/api/providercatalogentries/${params.catalogentry.id}/`);
        } finally {
            dispatch("fetchPurchasePrices");
        }
    },

    async unarchiveProvider({ commit, dispatch, state }, params) {

        try {
            await axios.post(`/api/providers/${state.provider.id}/unarchive/`);
        } finally {
            dispatch("fetchProvider");
        }
    },

    async createSaleProduct({ commit, dispatch, state }, params) {
        commit("addImportingProducts", params.catalogentries);
        try {
            const response = await axios.post(`/api/importproducts/`, {
                products: params.products
            });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            dispatch("fetchPurchasePrices");
            commit("removeImportingProducts", params.products);
        }
    },

    async fetchAssignedCatalogs({ commit, dispatch, state }) {
        try {
            const response = await axios.get(`/api/catalogs/`, {
                params: { provider: state.provider.id, },
            });
            commit("updateAssignedCatalogs", response.data.results);
            return response;
        }
        catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },



    async init({ commit, dispatch, state }, params) {
        commit("updateProvider", params?.provider);
        commit("updateProviderStats", null);
        commit('updateSection', params?.section || null);
        if (!params.provider) {
            return;
        }
        await dispatch("documents/init", {
            tags: `provider:${params.provider.id}`,
            create_tags: [`provider:${params.provider.id}`],
        });
        dispatch("documents/fetchDocuments");
        await dispatch("orders/init", { url: "/api/providerorders/", filters: { seller: params.provider.id } });
        dispatch("orders/fetchOrders");
        await dispatch("invoices/init", { url: "/api/providerinvoices/", filters: { provider: params.provider.id } });
        dispatch("invoices/fetchInvoices");
        await dispatch("invitations/init", { filters: { provider: params.provider.id } });
        if (!state.provider.entity) {
            dispatch("invitations/fetchInvitations");
        }
        await commit("updateAssignedCatalogs", null);
        dispatch("fetchAssignedCatalogs");

    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
        invitations: invitations(),
        orders: orders(),
        invoices: invoices(),
    },
};
