var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "datetime"
  }, [!_vm.fixed ? _c('v-menu', {
    ref: "menu",
    attrs: {
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "background-color": _vm.backgroundColor,
            "clearable": _vm.clearable,
            "dense": _vm.dense,
            "error-messages": _vm.errorMessages,
            "flat": _vm.flat,
            "label": _vm.required ? _vm.label + ' (*)' : _vm.label,
            "outlined": _vm.outlined,
            "prepend-inner-icon": _vm.prependInnerIcon,
            "value": _vm.pretty_date,
            "hide-details": !_vm.errorMessages,
            "placeholder": _vm.placeholder,
            "disabled": _vm.disabled
          },
          on: {
            "change": function change($event) {
              return _vm.setNewDate($event);
            }
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 4021946558),
    model: {
      value: _vm.display,
      callback: function callback($$v) {
        _vm.display = $$v;
      },
      expression: "display"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "view-mode": "year",
      "no-title": "",
      "scrollable": "",
      "first-day-of-week": 1,
      "min": _vm.minDate,
      "max": _vm.maxDate,
      "width": _vm.width,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        _vm.display = false;
      }
    },
    model: {
      value: _vm.inputVal,
      callback: function callback($$v) {
        _vm.inputVal = $$v;
      },
      expression: "inputVal"
    }
  })], 1) : [_c('v-date-picker', {
    key: _vm.inputVal,
    staticClass: "px-0",
    attrs: {
      "value": _vm.inputVal || _vm.$moment().format('YYYY-MM-DD'),
      "view-mode": "year",
      "no-title": "",
      "scrollable": "",
      "first-day-of-week": 1,
      "min": _vm.minDate,
      "max": _vm.maxDate,
      "width": _vm.width,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        _vm.inputVal = $event;
      }
    }
  })]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }