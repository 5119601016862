var render = function render(){
  var _vm$entity_saving_err, _vm$entity_saving_err2, _vm$entity_saving_err3, _vm$entity_saving_err4, _vm$entity_saving_err5, _vm$entity_saving_err6, _vm$entity_saving_err7, _vm$entity_saving_err8, _vm$entity_saving_err9, _vm$entity_saving_err10, _vm$entity_saving_err11;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-entity-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$translate("title")))]), _vm.entity ? _c('template', {
    slot: "content"
  }, [_c('Info', {
    attrs: {
      "elevation": 3
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("instructions")) + " ")]), _c('h6', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Information")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "autofocus": "",
      "error-messages": (_vm$entity_saving_err = _vm.entity_saving_errors) === null || _vm$entity_saving_err === void 0 ? void 0 : _vm$entity_saving_err.siren,
      "label": _vm.$translate('siren')
    },
    model: {
      value: _vm.entity.siren,
      callback: function callback($$v) {
        _vm.$set(_vm.entity, "siren", $$v);
      },
      expression: "entity.siren"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$entity_saving_err2 = _vm.entity_saving_errors) === null || _vm$entity_saving_err2 === void 0 ? void 0 : (_vm$entity_saving_err3 = _vm$entity_saving_err2.billing_address) === null || _vm$entity_saving_err3 === void 0 ? void 0 : _vm$entity_saving_err3.email,
      "label": _vm.$translate('email')
    },
    model: {
      value: _vm.entity.billing_address.email,
      callback: function callback($$v) {
        _vm.$set(_vm.entity.billing_address, "email", $$v);
      },
      expression: "entity.billing_address.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$entity_saving_err4 = _vm.entity_saving_errors) === null || _vm$entity_saving_err4 === void 0 ? void 0 : (_vm$entity_saving_err5 = _vm$entity_saving_err4.billing_address) === null || _vm$entity_saving_err5 === void 0 ? void 0 : _vm$entity_saving_err5.line1,
      "label": _vm.$translate('line1')
    },
    model: {
      value: _vm.entity.billing_address.line1,
      callback: function callback($$v) {
        _vm.$set(_vm.entity.billing_address, "line1", $$v);
      },
      expression: "entity.billing_address.line1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$entity_saving_err6 = _vm.entity_saving_errors) === null || _vm$entity_saving_err6 === void 0 ? void 0 : (_vm$entity_saving_err7 = _vm$entity_saving_err6.billing_address) === null || _vm$entity_saving_err7 === void 0 ? void 0 : _vm$entity_saving_err7.city,
      "label": _vm.$translate('city')
    },
    model: {
      value: _vm.entity.billing_address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.entity.billing_address, "city", $$v);
      },
      expression: "entity.billing_address.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$entity_saving_err8 = _vm.entity_saving_errors) === null || _vm$entity_saving_err8 === void 0 ? void 0 : (_vm$entity_saving_err9 = _vm$entity_saving_err8.billing_address) === null || _vm$entity_saving_err9 === void 0 ? void 0 : _vm$entity_saving_err9.postal_code,
      "label": _vm.$translate('postalCode')
    },
    model: {
      value: _vm.entity.billing_address.postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.entity.billing_address, "postal_code", $$v);
      },
      expression: "entity.billing_address.postal_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$entity_saving_err10 = _vm.entity_saving_errors) === null || _vm$entity_saving_err10 === void 0 ? void 0 : (_vm$entity_saving_err11 = _vm$entity_saving_err10.billing_address) === null || _vm$entity_saving_err11 === void 0 ? void 0 : _vm$entity_saving_err11.country,
      "label": _vm.$translate('country')
    },
    model: {
      value: _vm.entity.billing_address.country,
      callback: function callback($$v) {
        _vm.$set(_vm.entity.billing_address, "country", $$v);
      },
      expression: "entity.billing_address.country"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('template', {
    slot: "bottom"
  }, [!_vm.params.readonly ? [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "ghost": "",
      "text": _vm.$gettext('Cancel')
    },
    on: {
      "click": _vm.hideModal
    }
  }), _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.$translate('continue'),
      "disabled": !_vm.canSave || _vm.entity_saving,
      "loading": _vm.entity_saving
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })] : [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "ghost": "",
      "text": _vm.$gettext('Close')
    },
    on: {
      "click": _vm.hideModal
    }
  })]], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }