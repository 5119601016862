var render = function render(){
  var _vm$producttypes;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.product ? _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-product-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('template', {
    slot: "content"
  }, [!_vm.isOperator ? [_vm.product.archived ? [_c('Warning', {
    attrs: {
      "elevation": 1
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("archivedProduct")) + " ")])] : _vm._e()] : [_c('Info', {
    attrs: {
      "elevation": 1
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("productNotOwned")) + " ")])], _c('h6', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$translate("productInfo")) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.product_saving_errors.name,
      "label": _vm.$gettext('Name'),
      "disabled": _vm.product.archived || _vm.isOperator,
      "required": "",
      "autofocus": ""
    },
    model: {
      value: _vm.product.name,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.product_saving_errors.upc,
      "label": _vm.$gettext('UPC (automatically generated if not provided)'),
      "disabled": _vm.product.archived || _vm.isOperator,
      "required": ""
    },
    model: {
      value: _vm.product.upc,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "upc", $$v);
      },
      expression: "product.upc"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.product_saving_errors.manufacturer,
      "label": _vm.$gettext('Manufacturer'),
      "disabled": _vm.product.archived || _vm.isOperator
    },
    model: {
      value: _vm.product.manufacturer,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "manufacturer", $$v);
      },
      expression: "product.manufacturer"
    }
  })], 1), !_vm.isOperator ? [_c('v-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "6",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.product_saving_errors.packaging,
      "label": _vm.$gettext('Packaging'),
      "type": "number",
      "format": "number",
      "disabled": _vm.product.archived || _vm.isOperator
    },
    model: {
      value: _vm.product.packaging,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "packaging", $$v);
      },
      expression: "product.packaging"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "6",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": Number.isFinite(_vm.product.vat_rate) ? _vm.product.vat_rate * 100 : null,
      "error-messages": _vm.product_saving_errors.packaging,
      "label": _vm.$gettext('Force VAT rate (%)'),
      "placeholder": _vm.current_entity_settings.default_vat_rate * 100 + ' %',
      "type": "number",
      "format": "number",
      "disabled": _vm.product.archived || _vm.isOperator
    },
    on: {
      "input": function input($event) {
        _vm.product.vat_rate = Number.isFinite(parseFloat($event)) ? parseFloat($event) / 100 : null;
      }
    }
  })], 1)] : _vm._e()], 2), (_vm$producttypes = _vm.producttypes) !== null && _vm$producttypes !== void 0 && _vm$producttypes.length ? [_c('ProductMetadata', {
    key: _vm.product.producttype,
    attrs: {
      "product": _vm.product,
      "producttypes": _vm.producttypes,
      "product-saving-errors": _vm.product_saving_errors
    },
    on: {
      "producttype-changed": _vm.setProductType,
      "metadata-changed": _vm.setMetadata
    }
  })] : _vm._e(), _c('h5', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold mt-6"
  }, [_vm._v(" Additional information ")]), _vm.isCustomer ? [_c('h6', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Stock settings")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "input-value": _vm.product.stock_tracking || false,
      "label": _vm.$translate('enableStockTracking'),
      "hide-details": "",
      "single-line": "",
      "persistent-hint": "",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        _vm.product.stock_tracking = $event;
        _vm.product.requires_stock_to_be_ordered = false;
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "disabled": !_vm.product.stock_tracking || _vm.product.archived || _vm.isOperator,
      "error-messages": _vm.product_saving_errors.low_stock_alert_treshold,
      "label": _vm.$gettext('Low stock alert'),
      "dense": "",
      "min": 0,
      "hide-details": "",
      "type": "number"
    },
    model: {
      value: _vm.product.low_stock_alert_treshold,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "low_stock_alert_treshold", $$v);
      },
      expression: "product.low_stock_alert_treshold"
    }
  })], 1)], 1), _c('h6', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold mt-4"
  }, [_vm._v(" Sell settings ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "input-value": _vm.product.available_for_sale || false,
      "label": _vm.$translate('availableForSale'),
      "hide-details": "",
      "single-line": "",
      "persistent-hint": "",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        _vm.product.available_for_sale = $event;
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "label": _vm.$gettext('Requires stock to be ordered'),
      "hide-details": "",
      "single-line": "",
      "persistent-hint": "",
      "outlined": "",
      "disabled": !_vm.product.available_for_sale || !_vm.product.stock_tracking
    },
    on: {
      "change": function change($event) {
        _vm.product.requires_stock_to_be_ordered = $event;
      }
    },
    model: {
      value: _vm.product.requires_stock_to_be_ordered,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "requires_stock_to_be_ordered", $$v);
      },
      expression: "product.requires_stock_to_be_ordered"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-0",
    attrs: {
      "input-value": _vm.product.requires_allocation || false,
      "label": _vm.$gettext('Requires allocation'),
      "hide-details": "",
      "single-line": "",
      "persistent-hint": "",
      "outlined": "",
      "disabled": !_vm.product.available_for_sale
    },
    on: {
      "change": function change($event) {
        _vm.product.requires_allocation = $event;
      }
    }
  })], 1)], 1), _vm.isNew ? [_c('Block', {
    staticClass: "mt-4"
  }, [_c('v-row', {
    key: JSON.stringify(_vm.product.providers)
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ProductProviders', {
    key: JSON.stringify(_vm.product.providers),
    attrs: {
      "limit": _vm.product.provider ? 1 : null,
      "value": _vm.product.providers || [{}],
      "providers": _vm.providers,
      "editable": _vm.isCustomer,
      "allow-create-provider": _vm.userHasPermission('core.add_provider'),
      "errors": _vm.product_saving_errors.providers
    },
    on: {
      "input": _vm.updateProductProviders,
      "provider-created": _vm.providerCreated
    }
  })], 1)], 1)], 1)] : _vm._e()] : _vm._e()], 2), _c('template', {
    slot: "bottom"
  }, [!_vm.product.archived ? [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Cancel'),
      "ghost": ""
    },
    on: {
      "click": _vm.cancelEditClicked
    }
  }), _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.saveText
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })] : [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Close'),
      "ghost": ""
    },
    on: {
      "click": _vm.cancelEditClicked
    }
  })]], 2)], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }