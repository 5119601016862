<template>
    <ModalContent
        v-if="product"
        :help-content-id="`popup-product-${current_entity_profile}`"
    >
        <template slot="title">{{ title }}</template>

        <template slot="content">
            <template v-if="!isOperator">
                <template v-if="product.archived">
                    <Warning :elevation="1">
                        {{ $translate("archivedProduct") }}
                    </Warning>
                </template>
            </template>
            <template v-else>
                <Info :elevation="1">
                    {{ $translate("productNotOwned") }}
                </Info>
            </template>

            <h6 class="text-overline font-weight-bold">
                {{ $translate("productInfo") }}
            </h6>

            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="product.name"
                        :error-messages="product_saving_errors.name"
                        :label="$gettext('Name')"
                        :disabled="product.archived || isOperator"
                        required
                        autofocus
                    />
                </v-col>

                <v-col cols="12" sm="6" class="pt-2">
                    <v-text-field
                        v-model="product.upc"
                        :error-messages="product_saving_errors.upc"
                        :label="$gettext('UPC (automatically generated if not provided)')"
                        :disabled="product.archived || isOperator"
                        required
                    />
                </v-col>

                <v-col cols="12" sm="6" class="pt-2">
                    <v-text-field
                        v-model="product.manufacturer"
                        :error-messages="product_saving_errors.manufacturer"
                        :label="$gettext('Manufacturer')"
                        :disabled="product.archived || isOperator"
                    />
                </v-col>
                <template v-if="!isOperator">
                    <v-col cols="6" sm="3" class="pt-2">
                        <v-text-field
                            v-model="product.packaging"
                            :error-messages="product_saving_errors.packaging"
                            :label="$gettext('Packaging')"
                            type="number"
                            format="number"
                            :disabled="product.archived || isOperator"
                        />
                    </v-col>
                    <v-col cols="6" sm="3" class="pt-2">
                        <v-text-field
                            :value="
                                Number.isFinite(product.vat_rate)
                                    ? product.vat_rate * 100
                                    : null
                            "
                            @input="
                                product.vat_rate = Number.isFinite(parseFloat($event))
                                    ? parseFloat($event) / 100
                                    : null
                            "
                            :error-messages="product_saving_errors.packaging"
                            :label="$gettext('Force VAT rate (%)')"
                            :placeholder="
                                current_entity_settings.default_vat_rate * 100 + ' %'
                            "
                            type="number"
                            format="number"
                            :disabled="product.archived || isOperator"
                        />
                    </v-col>
                </template>
            </v-row>

            <template v-if="producttypes?.length">
                <ProductMetadata
                    :product="product"
                    :producttypes="producttypes"
                    :product-saving-errors="product_saving_errors"
                    :key="product.producttype"
                    @producttype-changed="setProductType"
                    @metadata-changed="setMetadata"
                />
            </template>
            <h5 class="text-overline font-weight-bold mt-6" v-translate>
                Additional information
            </h5>
            <template v-if="isCustomer">
                <h6 class="text-overline font-weight-bold" v-translate>Stock settings</h6>

                <v-row>
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            class="mt-0"
                            :input-value="product.stock_tracking || false"
                            :label="$translate('enableStockTracking')"
                            hide-details
                            single-line
                            persistent-hint
                            outlined
                            @change="
                                product.stock_tracking = $event;
                                product.requires_stock_to_be_ordered = false;
                            "
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            :disabled="
                                !product.stock_tracking || product.archived || isOperator
                            "
                            v-model="product.low_stock_alert_treshold"
                            :error-messages="
                                product_saving_errors.low_stock_alert_treshold
                            "
                            :label="$gettext('Low stock alert')"
                            dense
                            :min="0"
                            hide-details
                            type="number"
                        />
                    </v-col>
                </v-row>
                <h6 class="text-overline font-weight-bold mt-4" v-translate>
                    Sell settings
                </h6>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            class="mt-0"
                            :input-value="product.available_for_sale || false"
                            :label="$translate('availableForSale')"
                            hide-details
                            single-line
                            persistent-hint
                            outlined
                            @change="product.available_for_sale = $event"
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                            class="mt-0"
                            v-model="product.requires_stock_to_be_ordered"
                            :label="$gettext('Requires stock to be ordered')"
                            hide-details
                            single-line
                            persistent-hint
                            outlined
                            :disabled="
                                !product.available_for_sale || !product.stock_tracking
                            "
                            @change="product.requires_stock_to_be_ordered = $event"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-checkbox
                            class="mt-0"
                            :input-value="product.requires_allocation || false"
                            :label="$gettext('Requires allocation')"
                            hide-details
                            single-line
                            persistent-hint
                            outlined
                            :disabled="!product.available_for_sale"
                            @change="product.requires_allocation = $event"
                        />
                    </v-col>
                </v-row>
                <template v-if="isNew">
                    <Block class="mt-4">
                        <v-row :key="JSON.stringify(product.providers)">
                            <v-col cols="12">
                                <ProductProviders
                                    :limit="product.provider ? 1 : null"
                                    :key="JSON.stringify(product.providers)"
                                    :value="product.providers || [{}]"
                                    :providers="providers"
                                    :editable="isCustomer"
                                    :allow-create-provider="
                                        userHasPermission('core.add_provider')
                                    "
                                    :errors="product_saving_errors.providers"
                                    @input="updateProductProviders"
                                    @provider-created="providerCreated"
                                />
                            </v-col>
                        </v-row>
                    </Block>
                </template>
            </template>
        </template>
        <template slot="bottom">
            <template v-if="!product.archived">
                <Button
                    :text="$gettext('Cancel')"
                    @click="cancelEditClicked"
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                    ghost
                />

                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                    :text="saveText"
                    @click="saveInformationClicked"
                />
            </template>
            <template v-else>
                <Button
                    :text="$gettext('Close')"
                    @click="cancelEditClicked"
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                    ghost
                />
            </template>
        </template>
    </ModalContent>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import GlobalMixins from "@/mixins/global";
import ProductProviders from "@/components/common/productproviders";
import ProductMetadata from "@/components/common/product-metadata";

export default {
    mixins: [GlobalMixins],
    components: { ProductProviders, ProductMetadata },
    props: {
        owner: {
            type: Boolean,
            default: true,
        },
        params: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState("editproduct/producttypes", ["producttypes"]),
        ...mapState("editproduct", ["product", "product_saving_errors"]),
        ...mapState("editproduct/providers", ["providers"]),
        saveText() {
            if (this.isNew) {
                return this.$gettext("Create product");
            }
            return this.$gettext("Save");
        },
        isNew() {
            return !this.params.product.id;
        },
        title() {
            return this.params.product.id
                ? this.$gettext("Edit product")
                : this.$gettext("Create new product");
        },
    },
    created() {
        this.init({ product: this.params.product });
    },
    methods: {
        ...mapMutations("editproduct", [
            "updatePurchasePrices",
            "updateProductType",
            "updateProductProviders",
        ]),
        ...mapActions("editproduct", ["saveProduct", "init"]),
        ...mapActions("editproduct/providers", ["fetchProviders"]),

        providerCreated(provider) {
            this.fetchProviders();
        },

        setProductType(producttype) {
            this.updateProductType(producttype);
            this.$forceUpdate();
        },

        failedSavingInformations(errors) {
            this.$swal({
                title: this.$gettext("Failed saving product"),
                html: this.$utils.resultToSwalContent(this, errors),
                type: "error",
            });
        },
        informationsSaved(response) {
            if (response.status == 201) {
                this.addAlert(this.$gettext("Product created"));
            } else {
                this.addAlert(this.$gettext("Product saved"));
            }
            this.hideModal({ result: response.data });
        },
        cancelEditClicked() {
            this.hideModal();
        },
        saveInformationClicked() {
            this.saveProduct()
                .then(this.informationsSaved)
                .catch(this.failedSavingInformations);
        },
        setMetadata({ code, value }) {
            this.product.metadata[code] = value;
        },
    },
    i18n: {
        messages: {
            en: {
                availableForSale: "Available for sale",
                enableStockTracking: "Enable stock tracking",
                archivedProduct: "This product has been archived and cannot be modified.",
                productNotOwned:
                    "The product information can only be edited by the client, but you can edit the stock-related information.",
                productInfo: "Product informations",
            },
            fr: {
                availableForSale: "Disponible à la vente",
                enableStockTracking: "Activer le suivi de stock",
                archivedProduct: "Ce produit a été archivé et ne peut pas être modifié.",
                productNotOwned:
                    "Les informations du produit ne peuvent être modifiées que par le client, mais vous pouvez modifier les informations liées au stock.",
                productInfo: "Informations produit",
            },
        },
    },
};
</script>
