<template>
    <ModalContent :help-content-id="`popup-entity-${current_entity_profile}`">
        <template slot="title">{{ $translate("title") }}</template>
        <template slot="content" v-if="entity">
            <Info :elevation="3">
                {{ $translate("instructions") }}
            </Info>
            <h6 class="text-overline font-weight-bold">Information</h6>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="entity.siren"
                        autofocus
                        :error-messages="entity_saving_errors?.siren"
                        :label="$translate('siren')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="entity.billing_address.email"
                        :error-messages="entity_saving_errors?.billing_address?.email"
                        :label="$translate('email')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="entity.billing_address.line1"
                        :error-messages="entity_saving_errors?.billing_address?.line1"
                        :label="$translate('line1')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="entity.billing_address.city"
                        :error-messages="entity_saving_errors?.billing_address?.city"
                        :label="$translate('city')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="entity.billing_address.postal_code"
                        :error-messages="
                            entity_saving_errors?.billing_address?.postal_code
                        "
                        :label="$translate('postalCode')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="entity.billing_address.country"
                        :error-messages="entity_saving_errors?.billing_address?.country"
                        :label="$translate('country')"
                    />
                </v-col>
            </v-row>
        </template>
        <template slot="bottom">
            <template v-if="!params.readonly">
                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                    ghost
                    :text="$gettext('Cancel')"
                    @click="hideModal"
                />
                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                    :text="$translate('continue')"
                    :disabled="!canSave || entity_saving"
                    :loading="entity_saving"
                    @click="saveInformationClicked"
                />
            </template>
            <template v-else>
                <Button
                    ghost
                    :text="$gettext('Close')"
                    @click="hideModal"
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                />
            </template>
        </template>
    </ModalContent>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    computed: Object.assign(
        mapState("editentity", ["entity", "entity_saving", "entity_saving_errors"]),
        {
            canSave() {
                return (
                    this.entity.siren &&
                    this.entity.billing_address.city &&
                    this.entity.billing_address.country &&
                    this.entity.billing_address.email &&
                    this.entity.billing_address.line1 &&
                    this.entity.billing_address.postal_code
                );
            },
        }
    ),
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.init({ entity: this.params.entity });
    },
    methods: Object.assign(
        mapMutations("editentity", ["updateEntity"]),
        mapActions("editentity", ["saveEntity", "init"]),
        {
            saveInformationClicked() {
                this.saveEntity({ instance: this.entity })
                    .then((response) => {
                        this.hideModal({ result: response.data });
                    })
                    .catch((error) => {
                        this.$swal({
                            title: this.$translate("failed"),
                            html: this.$utils.resultToSwalContent(this, error),
                            type: "error",
                        });
                    });
            },
        }
    ),
    i18n: {
        messages: {
            en: {
                title: "Complete your informations",
                instructions:
                    "Please complete the informations about your company before continuing.",
                failed: "Failed saving informations",
                save: "Save informations",
                siren: "Your company SIREN",
                email: "Contact email",
                line1: "Address",
                city: "City",
                postalCode: "Postal code",
                country: "Country",
                continue: "Continue",
                required: "This field is required",
            },
            fr: {
                title: "Complétez vos informations",
                instructions:
                    "Veuillez compléter les informations relatives à votre entreprise avant de continuer.",
                failed: "Échec de l'enregistrement des informations",
                save: "Enregistrer les informations",
                siren: "SIREN de votre entreprise",
                email: "Email de contact",
                line1: "Adresse de votre entreprise",
                city: "Ville",
                postalCode: "Code postal",
                country: "Pays",
                continue: "Continuer",
                required: "Ce champ est requis",
            },
        },
    },
};
</script>
