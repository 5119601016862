<template>
    <div class="d-flex align-center providername" v-if="provider">
        <v-tooltip top>
            <template v-slot:activator="{ on }"
                ><div v-on="isConnected ? on : null" class="d-flex align-center">
                    <span v-if="!link">{{ provider.name }}</span>
                    <lookup v-else-if="lookup" object="provider" :params="lookup">
                        {{ provider.name }}
                    </lookup>
                    <router-link
                        v-else-if="routeExists('provider')"
                        :to="{
                            name: 'provider',
                            params: {
                                provider_id: provider.id,
                            },
                        }"
                        ><Stroke :strike="provider.archived">
                            {{ provider.name }}
                        </Stroke>
                    </router-link>
                    <span v-else>{{ provider.name }}</span>
                    <v-icon
                        v-if="isConnected"
                        :color="link ? 'primary' : null"
                        size="20"
                        class="ml-1 icon"
                        >mdi-link</v-icon
                    >
                </div>
            </template>
            <template v-if="isConnected">
                {{ $translate("providerIsConnected") }}
            </template>
        </v-tooltip>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        provider: {
            required: true,
            type: Object,
        },
        lookup: {
            required: false,
            type: Object,
        },
        link: {
            required: false,
            type: Boolean,
            default: true,
        },
        connected: {
            required: false,
            type: Boolean,
            default: null,
        },
    },
    i18n: {
        messages: {
            en: {
                providerIsConnected: "This provider is connected",
            },
            fr: {
                providerIsConnected: "Ce fournisseur est connecté",
            },
        },
    },
    computed: {
        isConnected() {
            return this.connected === true || this.provider.entity;
        },
    },
};
</script>

<style scoped>
.providername .icon {
    /* opacity: 0;
    transition: opacity 0.3s; */
}
.providername:hover .icon {
    opacity: 1;
}
</style>
