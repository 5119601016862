var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchObject', {
    ref: "search",
    attrs: {
      "additional-params": Object.assign({
        ordering: 'name'
      }, _vm.additionalParams),
      "allow-create": _vm.allowCreate,
      "autofocus": _vm.autofocus,
      "base-url": _vm.baseUrl,
      "create-label": _vm.$translate('create_a_product'),
      "disabled": _vm.disabled,
      "hide-details": _vm.hideDetails,
      "item-text": _vm.itemText,
      "label": _vm.label || _vm.$translate('select_product'),
      "readonly": _vm.readonly,
      "required": _vm.required,
      "value": _vm.value,
      "dense": _vm.dense
    },
    on: {
      "create": _vm.createProduct,
      "input": function input($event) {
        return _vm.$emit('input', $event);
      },
      "select": function select($event) {
        return _vm.$emit('select', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ProductName', {
          staticClass: "my-1 text-body-3",
          attrs: {
            "type": "providerproduct",
            "product": item,
            "upc": true,
            "inline-upc": true
          }
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }