<template>
    <ModalContent :help-content-id="`popup-provider-${current_entity_profile}`">
        <template slot="title">{{ title }}</template>
        <template slot="content">
            <Warning :elevation="2" v-if="isExternal" class="mb-2">
                {{ $translate("instructionsExternal") }}
            </Warning>

            <h5 class="text-overline font-weight-bold mb-4">
                {{ $translate("providerConfiguration") }}
            </h5>

            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="provider.name"
                        :error-messages="provider_saving_errors.name"
                        :label="$translate('labels.name')"
                        autofocus
                        required
                        :dense="true"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="provider.code"
                        :error-messages="provider_saving_errors.code"
                        :label="$translate('labels.code')"
                        :placeholder="$translate('codePlaceholder')"
                        :dense="true"
                        name="code"
                    />
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="provider.siren"
                        :error-messages="provider_saving_errors.siren"
                        :disabled="isExternal"
                        :label="$gettext('SIREN')"
                        :dense="true"
                /></v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="provider.vat_number"
                        :error-messages="provider_saving_errors.vat_number"
                        :label="$gettext('VAT number')"
                        :disabled="isExternal"
                        :dense="true"
                /></v-col>
                <v-col cols="12" sm="6" v-if="!isExternal">
                    <v-text-field
                        :value="
                            Number.isFinite(provider.vat_rate)
                                ? provider.vat_rate * 100
                                : null
                        "
                        @input="
                            provider.vat_rate = Number.isFinite(parseFloat($event))
                                ? $event / 100
                                : null
                        "
                        :error-messages="provider_saving_errors.vat_rate"
                        :label="$gettext('VAT rate (%)')"
                        :disabled="isExternal"
                        :dense="true"
                /></v-col>

                <v-col cols="12" class="pt-0">
                    <AddressForm
                        :title="$gettext('Address')"
                        :value="provider.address || {}"
                        @input="provider.address = $event"
                        :errors="provider_saving_errors.address"
                        :readonly="isExternal"
                        :dense="true"
                    />
                </v-col>
            </v-row>
        </template>
        <template slot="bottom">
            <Button
                :text="$gettext('Cancel')"
                @click="cancelEditClicked"
                class="my-2 my-sm-4 w-100 w-sm-auto"
                ghost
            />

            <Button
                class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                :text="saveText"
                :disabled="canSave || provider_saving"
                :loading="provider_saving"
                @click="saveInformationClicked"
            />
        </template>
    </ModalContent>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import utils from "@/utils";
import EditEntity from "@/modals/entity";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    computed: Object.assign(
        mapState("editprovider", [
            "provider",
            "provider_saving",
            "provider_saving_errors",
        ]),
        {
            canSave() {
                if (this.isExternal) {
                    return false;
                }
                return !this.provider.name;
            },
            isExternal() {
                return !!this.provider.entity;
            },

            isNew() {
                return !this.provider.id;
            },
            saveText() {
                if (this.isNew) {
                    return this.$translate("buttons.createProvider");
                }
                return this.$gettext("Save");
            },
            title() {
                return this.params.provider.id
                    ? this.$translate("title.editProvider")
                    : this.$translate("title.createProvider");
            },
        }
    ),
    create() {
        this.init({ provider: this.params.provider || {} });
    },
    methods: Object.assign(
        mapActions("editprovider", [
            "fetchProvider",
            "deleteProvider",
            "saveProvider",
            "init",
        ]),
        mapMutations("editprovider", ["updateProviderSavingErrors"]),
        {
            failedSavingProvider(errors) {
                this.$swal({
                    title: this.$gettext("Error"),
                    html: this.$utils.resultToSwalContent(this, errors),
                    type: "error",
                });
            },

            informationsSaved(response) {
                if (response.status == 201) {
                    this.addAlert(this.$gettext("Provider created"));
                } else {
                    this.addAlert(this.$gettext("Provider updated"));
                }
                this.hideModal({ result: response.data });
            },
            async saveInformationClicked() {
                let errors = {};
                if (!this.provider.name) {
                    errors.name = [this.$gettext("This field is required")];
                }

                if (Object.keys(errors).length) {
                    this.updateProviderSavingErrors(errors);
                    return;
                }

                this.saveProvider({
                    instance: this.provider,
                })
                    .then(this.informationsSaved)
                    .catch(this.failedSavingProvider);
            },
            cancelEditClicked() {
                this.hideModal();
            },
        }
    ),
    created() {
        this.init(this.params);
    },
    i18n: {
        messages: {
            en: {
                instructionsExternal:
                    "The provider is linked to an external company. Its information cannot be modified.",
                codePlaceholder: "Provider code in your system",
                buttons: {
                    createProvider: "Create provider",
                },
                title: {
                    editProvider: "Edit provider",
                    createProvider: "Create provider",
                },
                labels: {
                    code: "Provider code",
                    name: "Name (*)",
                },
                providerConfiguration: "Provider configuration",
            },
            fr: {
                instructionsExternal:
                    "Le fournisseur est lié à une entreprise externe. Ses informations ne peuvent pas être modifiées.",
                codePlaceholder: "Code du fournisseur dans votre système",
                buttons: {
                    createProvider: "Créer le fournisseur",
                },
                title: {
                    editProvider: "Modifier le fournisseur",
                    createProvider: "Créer le fournisseur",
                },
                labels: {
                    code: "Code fournisseur",
                    name: "Nom (*)",
                },

                providerConfiguration: "Configuration du fournisseur",
            },
        },
    },
};
</script>
