<template>
    <div>
        <h6 class="text-overline font-weight-bold" v-translate>Product metadata</h6>

        <v-row>
            <v-col cols="12" sm="4" class="pt-2">
                <Select
                    class="flex-grow-1"
                    :value="product.producttype"
                    @input="$emit('producttype-changed', $event)"
                    :label="$gettext('Product type')"
                    :clearable="isCustomer"
                    :disabled="!isCustomer"
                    :error-messages="productSavingErrors.producttype"
                    :options="producttypesChoices"
                />
            </v-col>
            <template v-if="producttype">
                <v-col
                    cols="6"
                    sm="4"
                    class="pt-2"
                    v-for="field in getProductTypeFields()"
                    :key="field.code"
                >
                    <Select
                        v-if="field.type == 'choices'"
                        :value="product.metadata[field.code]"
                        @input="
                            $emit('metadata-changed', { code: field.code, value: $event })
                        "
                        :label="fieldLabel(field)"
                        :class="field.required ? 'font-weight-bold' : null"
                        :clearable="isCustomer"
                        :disabled="!isCustomer"
                        :required="field.required"
                        :error-messages="
                            productSavingErrors.metadata
                                ? productSavingErrors.metadata[field.code]
                                : null
                        "
                        :options="field | fieldOptions"
                    />
                    <v-select
                        v-else-if="field.type == 'boolean'"
                        :value="product.metadata[field.code]"
                        @input="
                            $emit('metadata-changed', { code: field.code, value: $event })
                        "
                        :label="fieldLabel(field)"
                        :clearable="isCustomer"
                        :disabled="!isCustomer"
                        :required="field.required"
                        :error-messages="
                            productSavingErrors.metadata
                                ? productSavingErrors.metadata[field.code]
                                : null
                        "
                        :items="[
                            { text: $gettext('Yes'), value: true },
                            { text: $gettext('No'), value: false },
                        ]"
                    />
                    <v-text-field
                        v-else
                        :value="product.metadata[field.code]"
                        @input="
                            $emit('metadata-changed', { code: field.code, value: $event })
                        "
                        :class="field.required ? 'font-weight-bold' : null"
                        :label="fieldLabel(field)"
                        :clearable="isCustomer"
                        :disabled="!isCustomer"
                        :required="field.required"
                        :format="fieldFormatForField(field)"
                        :type="fieldTypeForField(field)"
                        :error-messages="
                            productSavingErrors.metadata
                                ? productSavingErrors.metadata[field.code]
                                : null
                        "
                    />
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        product: {
            required: true,
            type: Object,
        },
        producttypes: {
            required: true,
            type: Array,
        },
        productSavingErrors: {
            required: true,
            type: Object,
        },
    },
    filters: {
        fieldOptions(field) {
            if (!field || !field.choices) {
                return [];
            }
            return field.choices.map((item) => {
                return {
                    text: item,
                    value: item,
                };
            });
        },
    },
    methods: {
        getProductTypeFields() {
            if (!this.product.producttype) {
                return null;
            }
            if (!this.producttypes) {
                return {};
            }
            let producttype = this.producttypes.find(
                (item) => this.product.producttype == item.id
            );
            let required_fields = producttype?.fields.filter((item) => item.required);
            let optionnal_fields = producttype?.fields
                .filter((item) => !item.required)
                .sort((a, b) => a.label.localeCompare(b.label));

            return required_fields.concat(optionnal_fields);
        },
        fieldTypeForField(field) {
            switch (field.type) {
                case "choices":
                    return "text";
                case "integer":
                    return "number";
                case "float":
                    return "float";
                default:
                    return "text";
            }
        },
        fieldFormatForField(field) {
            switch (field.type) {
                case "choices":
                    return "string";
                case "integer":
                    return "integer";
                case "float":
                    return "float";
                default:
                    return "string";
            }
        },
        fieldLabel(field) {
            let label = field.label;

            if (field.unit) {
                label += ` (${field.unit})`;
            }
            if (field.required) {
                label += ` - ${this.$gettext("required")}`;
            }

            return label;
        },
    },
    computed: {
        producttypesChoices() {
            return this.producttypes.map((item) => {
                return {
                    text: item.label,
                    value: item.id,
                };
            });
        },
        producttype() {
            return this.producttypes.find((item) => item.id == this.product.producttype);
        },
    },
};
</script>
