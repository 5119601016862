var render = function render(){
  var _vm$order_saving_erro, _vm$order_saving_erro2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-order-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('template', {
    slot: "content"
  }, [_c('div', {
    staticClass: "b-block d-sm-flex"
  }, [_c('div', {
    staticClass: "flex-shrink-1"
  }, [_c('DateTime', {
    key: _vm.delivery_date,
    staticClass: "mb-3 justify-center d-flex",
    attrs: {
      "value": _vm.delivery_date,
      "error-messages": (_vm$order_saving_erro = _vm.order_saving_errors) === null || _vm$order_saving_erro === void 0 ? void 0 : _vm$order_saving_erro.delivery_date,
      "label": _vm.$gettext('Delivery date'),
      "only-date": true,
      "fixed": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateDeliveryDate($event);
      }
    }
  })], 1), _c('div', {
    staticClass: "flex-grow-1 mt-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('SearchCustomer', {
    attrs: {
      "value": _vm.buyer_id,
      "error-messages": _vm.order_saving_errors.customer,
      "allow-create": true
    },
    on: {
      "input": function input($event) {
        return _vm.setBuyerAction($event);
      }
    }
  })], 1), _vm.order_type == 'deliverysite' && _vm.buyer_id ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    key: _vm.deliverysite_id,
    attrs: {
      "value": _vm.deliverysite_id,
      "error-messages": _vm.order_saving_errors.deliverysite,
      "label": _vm.$gettext('Delivery site'),
      "disabled": !_vm.buyer_id || _vm.deliverysiteschoices.length == 0 || _vm.deliverysites_loading,
      "items": _vm.deliverysiteschoices,
      "loading": _vm.deliverysites_loading,
      "dense": true
    },
    on: {
      "input": function input($event) {
        return _vm.setDeliverySiteIdAction($event);
      }
    }
  }), _vm.deliverysiteschoices.length == 0 ? _c('div', {
    staticClass: "grey--text font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$translate("noDeliverySite")) + " ")]) : _vm.buyer.entity ? _c('div', {
    staticClass: "grey--text font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$translate("customer_connected_deliverysite_warning")) + " ")]) : _vm.activeDeliverySite ? _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('a', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.editSite
    }
  }, [_vm._v("Edit site")])]) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('DateTime', {
    key: _vm.order.delivery_date,
    attrs: {
      "value": _vm.delivery_date,
      "error-messages": (_vm$order_saving_erro2 = _vm.order_saving_errors) === null || _vm$order_saving_erro2 === void 0 ? void 0 : _vm$order_saving_erro2.delivery_date,
      "label": _vm.$gettext('Delivery date'),
      "only-date": true,
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateDeliveryDate($event);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Field', {
    attrs: {
      "error-messages": _vm.order_saving_errors.comments,
      "label": _vm.$gettext('Comments'),
      "dense": true
    },
    model: {
      value: _vm.order.comments,
      callback: function callback($$v) {
        _vm.$set(_vm.order, "comments", $$v);
      },
      expression: "order.comments"
    }
  })], 1)], 1)], 1)])]), _c('template', {
    slot: "bottom"
  }, [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Cancel'),
      "ghost": ""
    },
    on: {
      "click": _vm.hideModal
    }
  }), _c('Button', {
    key: JSON.stringify(_vm.order),
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.saveText,
      "disabled": !_vm.canSave
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }