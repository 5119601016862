<template>
    <div>
        <!-- localSearch : {{ localSearch }}<br />
        value : {{ value }}<br />
        search : {{ search }}<br />
        items : {{ items.length }} <br />
        hasMore : {{ hasMore }} <br />
        activeItem {{ !!activeItem }} <br /> -->
        <v-autocomplete
            ref="activator"
            :value="value"
            @input="onSearchChange"
            :items="allItems"
            :loading="isLoading"
            :label="labelText"
            :disabled="disabled"
            :readonly="readonly"
            :required="required"
            :autofocus="autofocus"
            :hide-details="hideDetails"
            :clearable="true"
            :search-input.sync="localSearch"
            :no-filter="true"
            :item-text="itemText"
            :item-value="selectedKey"
            :attach="attach"
            :menu-props="{ closeOnClick: false, closeOnContentClick: false }"
            return-object
            @update:menu="onMenuChange"
            @change="selectItem"
            @click:clear="clearSelection"
            v-scroll="onScroll"
            :dense="dense"
        >
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $translate("no_results") }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template v-slot:append-item>
                <v-list-item v-if="hasMore && !isLoading" @click="loadMore">
                    <v-list-item-content>
                        <v-list-item-title class="text-body-2">
                            <b>{{ $translate("load_more") }}</b>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
            </template>

            <template slot="item" scope="{ item }">
                <slot name="item" :item="item">
                    <v-list-item-title>{{ itemText(item) }}__</v-list-item-title>
                </slot>
            </template>
            <template v-slot:content>
                fasdfasdfas
                <v-card
                    class="overflow-y-auto"
                    :style="cardStyle"
                    @scroll.passive="onScroll"
                >
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in items"
                            :key="item[selectedKey] || index"
                            @click="selectItem(item)"
                            >LLL
                            <v-list-item-content>
                                <v-list-item-title> </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    name: "InfiniteSelect",
    props: {
        // Array of items to display.
        items: {
            type: Array,
            default: () => [],
        },
        // Translation key for the text field label.
        label: {
            type: String,
            required: false,
        },
        // The current value (typically the identifier of the selected item).
        value: {
            type: [String, Number],
            default: null,
        },
        // Function to extract display text from an item.
        itemText: {
            type: Function,
        },
        // The key used to identify an item in the items array.
        selectedKey: {
            type: String,
            default: "id",
        },
        // Attach prop for the menu.
        attach: {
            type: [Boolean, String, Object],
            default: false,
        },
        // Custom class for the menu content.
        contentClass: {
            type: String,
            default: "",
        },
        // Flag indicating if there are more items to load.
        hasMore: {
            type: Boolean,
            default: true,
        },
        // Loading flag (covers both initial load and load-more).
        loading: {
            type: Boolean,
            default: false,
        },
        // Default maximum height (in pixels) of the menu.
        defaultMaxHeight: {
            type: Number,
            default: 300,
        },
        // Optional initial search query.
        search: {
            type: String,
            default: "",
        },
        // The threshold (in pixels) from the bottom to trigger loading more.
        scrollThreshold: {
            type: Number,
            default: 10,
        },
        // Nudge value (in pixels) to adjust vertical position.
        nudgeTop: {
            type: Number,
            default: 20,
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menu: false,
            internalMaxHeight: this.defaultMaxHeight,
            localSearch: this.search,
            debouncedSearch: null,
            menuWidth: null,
            activeItem: null,
        };
    },
    computed: {
        allItems() {
            if (this.activeItem) {
                return [this.activeItem, ...this.items];
            }
            return this.items;
        },
        // Returns true if loading is active.
        isLoading() {
            return this.loading;
        },
        // Style for the card (scrollable container).
        cardStyle() {
            return {
                maxHeight: this.internalMaxHeight + "px",
                overflowY: "auto",
                width: this.menuWidth ? this.menuWidth + "px" : "auto",
            };
        },
        // Computes the default text to display when no search is active.
        displayText() {
            if (this.localSearch !== "") {
                return this.localSearch;
            }
            if (this.value != null) {
                const selItem = this.items.find(
                    (item) => item[this.selectedKey] === this.value
                );
                if (selItem) {
                    return this.itemText(selItem);
                }
            }
            return "";
        },
        labelText() {
            return this.label || this.$translate("select_an_item");
        },
    },
    methods: {
        reset() {
            this.clearSelection();
        },

        // Helper to extract plain text from VNodes.
        extractTextFromVNodes(nodes) {
            let text = "";
            if (nodes && Array.isArray(nodes)) {
                nodes.forEach((node) => {
                    if (node.children && Array.isArray(node.children)) {
                        text += this.extractTextFromVNodes(node.children);
                    } else if (node.text) {
                        text += node.text;
                    }
                });
            }
            return text;
        },
        // Simple debounce helper.
        debounce(func, wait) {
            let timeout;
            return function (...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        },
        // Called when the menu opens; calculates available space below the activator and sets menu width.
        onMenuChange(isOpen) {
            if (isOpen) {
                this.$nextTick(() => {
                    const activatorEl = this.$refs.activator.$el || this.$refs.activator;
                    const rect = activatorEl.getBoundingClientRect();
                    const spaceBelow = window.innerHeight - rect.bottom - 10;
                    this.internalMaxHeight =
                        spaceBelow > this.defaultMaxHeight
                            ? this.defaultMaxHeight
                            : spaceBelow;
                    this.menuWidth = rect.width;
                });
            }
        },
        // When an item is selected, emit events and clear the search query.
        selectItem(item) {
            if (item) {
                this.$emit("input", item[this.selectedKey]);
            } else {
                this.$emit("input", null);
            }
            this.$emit("select", item);
            this.localSearch = "";
            this.activeItem = item;
            this.menu = false;
        },
        // Clears the selection and search.
        clearSelection() {
            this.$emit("input", null);
            this.activeItem = null;
            this.$emit("clear");
            this.localSearch = "";
            this.$emit("search-changed", "");
        },
        // Called when the search text changes.
        // If the menu is closed, open it; then debounce the search-changed event.
        onSearchChange(newVal) {
            this.$emit("input", null);
            this.activeItem = null;
            if (!this.menu) {
                this.menu = true;
            }
            console.log("XX1");
            this.debouncedSearch(newVal);
        },
        // Scroll handler: if near the bottom, emit the "load-more" event.
        onScroll(e) {
            const el = e.target;
            if (
                el.scrollHeight - el.scrollTop - el.clientHeight <=
                this.scrollThreshold
            ) {
                if (!this.isLoading && this.hasMore) {
                    this.loadMore();
                }
            }
        },
        // Emit "load-more" event for the parent to load additional items.
        loadMore() {
            this.$emit("load-more");
        },
        // When the text field is clicked, select all its text.
        selectText() {
            this.$nextTick(() => {
                const input = this.$refs.activator.$el.querySelector("input");
                if (input) {
                    input.select();
                }
            });
        },
        focus() {
            this.$refs.activator.focus();
        },
    },
    watch: {
        // Sync localSearch with external search prop.
        search(newVal) {
            if (newVal !== this.localSearch) {
                this.localSearch = newVal;
            }
        },
        localSearch(newVal, oldVal) {
            if (this.disabled) {
                return;
            }
            this.debouncedSearch(newVal);
        },
        // Update internal max height if defaultMaxHeight changes.
        defaultMaxHeight(newVal) {
            this.internalMaxHeight = newVal;
        },
        menu(newVal) {
            if (newVal && this.search === "" && !this.products?.length) {
                this.$emit("load-more");
            }
        },
    },
    created() {
        // Initialize debounced search with a 300ms delay.
        this.debouncedSearch = this.debounce(function (val) {
            this.$emit("search-changed", val);
        }, 300);
    },
    i18n: {
        messages: {
            en: {
                select_an_item: "Select an item",
                loading_in_progress: "Loading...",
                no_results: "No results found",
                load_more: "Load more...",
            },
            fr: {
                select_an_item: "Sélectionnez un article",
                loading_in_progress: "Chargement en cours...",
                no_results: "Aucun résultat",
                load_more: "Charger plus...",
            },
        },
    },
};
</script>

<style scoped>
/* Adjust additional styles as needed */
</style>
