var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchObject', {
    ref: "search",
    attrs: {
      "additional-params": Object.assign({
        ordering: 'order_id'
      }, _vm.additionalParams),
      "allow-create": _vm.allowCreate,
      "autofocus": _vm.autofocus,
      "base-url": _vm.baseUrl,
      "create-label": _vm.$translate('create_a_order'),
      "disabled": _vm.disabled,
      "hide-details": _vm.hideDetails,
      "item-text": _vm.itemText,
      "label": _vm.label || _vm.$translate('select_order'),
      "readonly": _vm.readonly,
      "required": _vm.required,
      "value": _vm.value,
      "dense": _vm.dense
    },
    on: {
      "create": _vm.createOrder,
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.seller_entity == _vm.current_entity_id ? [_vm._v(" " + _vm._s(item.order_id) + " - " + _vm._s(item.buyer_data.name) + " - " + _vm._s(_vm.$utils.price(item.total_price_incl_vat)) + " ")] : [_vm._v(" " + _vm._s(item.order_id) + " - " + _vm._s(item.seller_data.name) + " - " + _vm._s(_vm.$utils.price(item.total_price_incl_vat)) + " ")]];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }