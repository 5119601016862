<template>
    <SearchObject
        class="mt-0"
        :additional-params="{ ordering: 'name', ...additionalParams }"
        :allow-create="allowCreate"
        :autofocus="autofocus"
        :base-url="baseUrl"
        :create-label="$translate('create_a_customer')"
        :disabled="disabled"
        :hide-details="hideDetails"
        :item-text="itemText"
        :label="label || $translate('select_customer')"
        :readonly="readonly"
        :required="required"
        :value="value"
        @create="createCustomer"
        @input="$emit('input', $event)"
        @select="$emit('select', $event)"
        ref="search"
        :dense="dense"
    >
        <template slot="item" slot-scope="{ item }">
            <CustomerName class="my-1" :customer="item" :link="false" />
        </template>
    </SearchObject>
</template>

<script>
import EditCustomer from "@/modals/customer";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    name: "SearchProvider",
    props: {
        // The base URL to fetch items from.
        baseUrl: {
            type: String,
            default: "/api/customers/",
        },
        // Additional GET parameters to include in the API request.
        additionalParams: {
            type: Object,
            default: () => ({}),
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        // v-model binding (selected item ID).
        value: {
            type: [String, Number],
            default: null,
        },
        // Label for the autocomplete.
        label: {
            type: String,
            default: "",
        },
        // Optional limit per request.
        limit: {
            type: Number,
            default: 20,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        itemText(item) {
            return item.name;
        },
        focus() {
            this.$refs.search.focus();
        },
        async createCustomer() {
            const result = await this.showModal({
                component: EditCustomer,
                params: {
                    customer: {},
                },
            });
            if (result) {
                this.$emit("input", result);
                this.$refs.search.update();
            } else {
                this.$refs.search.reset();
            }
        },
    },
    i18n: {
        messages: {
            en: {
                select_customer: "Select a customer",
                create_a_customer: "Create a customer",
            },
            fr: {
                select_customer: "Sélectionnez un client",
                create_a_customer: "Créer un client",
            },
        },
    },
};
</script>
