var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-customer-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.customer !== null ? _c('template', {
    slot: "content"
  }, [_vm.isExternal ? _c('Warning', {
    attrs: {
      "elevation": 2
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("instructionsExternal")) + " ")]) : _vm._e(), _c('h5', {
    staticClass: "text-overline font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$translate("customerConfiguration")) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.customer_saving_errors.name,
      "label": _vm.$gettext('Name'),
      "name": "name",
      "required": "",
      "autofocus": "",
      "dense": true
    },
    model: {
      value: _vm.customer.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "name", $$v);
      },
      expression: "customer.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.customer_saving_errors.code,
      "label": _vm.$gettext('Code (automatically generated if not provided)'),
      "name": "code",
      "dense": true
    },
    model: {
      value: _vm.customer.code,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "code", $$v);
      },
      expression: "customer.code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "required": "",
      "label": _vm.$gettext('Type'),
      "error-messages": _vm.customer_saving_errors.type,
      "items": _vm.typesChoices,
      "value": _vm.customer.type || 'company',
      "disabled": _vm.isExternal,
      "hide-details": false,
      "dense": true
    },
    on: {
      "input": function input($event) {
        _vm.customer.type = $event;
        _vm.$forceUpdate();
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "required": "",
      "label": _vm.$gettext('Business activity'),
      "error-messages": _vm.customer_saving_errors.persona,
      "items": _vm.personasChoices,
      "value": _vm.customer.persona || 'company',
      "disabled": _vm.isExternal,
      "hide-details": false,
      "dense": true
    },
    on: {
      "input": function input($event) {
        _vm.customer.persona = $event;
        _vm.$forceUpdate();
      }
    }
  })], 1), _vm.customer.type == 'company' || !_vm.customer.type ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "error-messages": _vm.customer_saving_errors.siren,
      "disabled": _vm.isExternal,
      "label": _vm.$gettext('SIREN'),
      "name": "siren",
      "dense": true
    },
    model: {
      value: _vm.customer.siren,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "siren", $$v);
      },
      expression: "customer.siren"
    }
  })], 1) : _vm._e(), _vm.customer.type == 'company' || !_vm.customer.type ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "error-messages": _vm.customer_saving_errors.vat_number,
      "label": _vm.$gettext('VAT number'),
      "disabled": _vm.isExternal,
      "name": "vat_number",
      "dense": true
    },
    model: {
      value: _vm.customer.vat_number,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "vat_number", $$v);
      },
      expression: "customer.vat_number"
    }
  })], 1) : _vm._e(), _vm.catalogs ? [_vm.isOperator ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('Select', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "required": "",
      "label": _vm.$gettext('Catalog'),
      "error-messages": _vm.customer_saving_errors.catalog,
      "options": _vm.catalogs.map(function (c) {
        return {
          text: c.name + (c["default"] ? " (".concat(_vm.$gettext('default catalog'), ")") : ''),
          value: c.id
        };
      }),
      "value": _vm.allowed_catalogs ? _vm.allowed_catalogs[0] : null,
      "dense": true
    },
    on: {
      "input": function input($event) {
        _vm.updateAllowedCatalogs([$event]);
        _vm.$forceUpdate();
      }
    }
  })], 1)] : _vm._e()] : _vm._e()], 2), [_c('h5', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold mt-4 mb-4"
  }, [_vm._v(" Billing information ")]), _c('v-row', [_vm.billingaccounts.length ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Select', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "required": "",
      "label": _vm.$gettext('Billing account'),
      "error-messages": _vm.customer_saving_errors.billingaccount,
      "options": _vm.billingaccountsChoices,
      "value": _vm.customer.billingaccount,
      "loading": _vm.billingaccounts_loading,
      "disabled": _vm.billingaccounts_loading,
      "dense": true
    },
    on: {
      "input": function input($event) {
        return _vm.changeBillingAccountId($event);
      }
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "value": Number.isFinite(parseFloat(_vm.customer.force_vat_rate)) ? parseFloat((_vm.customer.force_vat_rate * 100).toFixed(2)) : null,
      "error-messages": _vm.customer_saving_errors.force_vat_rate,
      "label": _vm.$gettext('VAT rate (%)'),
      "name": "force_vat_rate",
      "dense": true
    },
    on: {
      "focusout": function focusout($event) {
        _vm.customer.force_vat_rate = $event === null ? null : parseFloat(($event / 100).toFixed(2));
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "flex-grow-0 w-sm-100",
    attrs: {
      "error-messages": _vm.customer_saving_errors.default_payment_delay,
      "label": _vm.$gettext('Payment delay'),
      "name": "default_payment_delay",
      "dense": true
    },
    model: {
      value: _vm.customer.default_payment_delay,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "default_payment_delay", $$v);
      },
      expression: "customer.default_payment_delay"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('AddressForm', {
    attrs: {
      "title": _vm.$gettext('Billing address'),
      "readonly": _vm.isExternal,
      "errors": _vm.customer_saving_errors.billing_address
    },
    model: {
      value: _vm.customer.billing_address,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "billing_address", $$v);
      },
      expression: "customer.billing_address"
    }
  })], 1)], 1)], _vm.stocks && _vm.isOperator ? [_c('h5', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold mt-6"
  }, [_vm._v(" Warehouse(s) ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$gettext("Select the warehouses the customer is allowed to use. You cannot unselect a warehouse customer has at least one product stocked in it.")) + " ")]), _c('v-row', {
    staticClass: "mb-4"
  }, _vm._l(_vm.stocks, function (stock) {
    return _c('v-col', {
      key: stock.id,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "input-value": _vm.isStockAllowed(stock),
        "label": stock.name,
        "dense": "",
        "single-line": "",
        "outlined": ""
      },
      on: {
        "change": function change($event) {
          return _vm.toggleStock(stock);
        }
      }
    })], 1);
  }), 1)] : _vm._e(), !_vm.isOperator ? [_vm.myCatalogs.length ? [_c('h5', {
    staticClass: "text-overline font-weight-bold mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$translate("myCatalogsTitle")) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$translate("myCatalogsInstructions")) + " ")]), _c('v-row', {
    staticClass: "mb-8 mt-0"
  }, _vm._l(_vm.myCatalogs, function (catalog) {
    return _c('v-col', {
      key: catalog === null || catalog === void 0 ? void 0 : catalog.id,
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-checkbox', {
      staticClass: "py-0 text-body-2",
      attrs: {
        "input-value": _vm.isCatalogAllowed(catalog),
        "label": catalog.name,
        "dense": "",
        "single-line": "",
        "outlined": "",
        "hide-details": ""
      },
      on: {
        "change": function change($event) {
          return _vm.toggleCatalog(catalog);
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-body-2"
          }, [_c('b', [_vm._v(_vm._s(catalog.name))]), catalog.effective_default ? _c('span', [_vm._v(" (" + _vm._s(_vm.$translate("default")) + ") ")]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)] : _vm._e(), _vm.providersCatalogs.length ? [_c('h5', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold mt-4"
  }, [_vm._v(" Providers catalogs ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$translate("providersCatalogsInstructions")) + " ")]), _c('v-row', {
    staticClass: "mb-8 mt-2"
  }, _vm._l(_vm.providersCatalogs, function (provider_catalog) {
    return _c('v-col', {
      key: provider_catalog === null || provider_catalog === void 0 ? void 0 : provider_catalog.id,
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "lg": "12"
      }
    }, [_c('v-checkbox', {
      staticClass: "py-0 mb-2",
      attrs: {
        "input-value": _vm.isCatalogAllowed(provider_catalog),
        "label": provider_catalog.name,
        "dense": "",
        "single-line": "",
        "outlined": "",
        "hide-details": "",
        "disabled": _vm.isCatalogDisabled(provider_catalog)
      },
      on: {
        "change": function change($event) {
          return _vm.toggleCatalog(provider_catalog);
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-body-2"
          }, [_c('b', [_vm._v(" " + _vm._s(provider_catalog.owner_data.name))]), _vm._v(" - " + _vm._s(provider_catalog.name) + " "), provider_catalog.effective_default ? _c('span', [_vm._v(" (" + _vm._s(_vm.$translate("default")) + ") ")]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)] : _vm._e()] : _vm._e(), _c('h5', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "text-overline font-weight-bold mt-6"
  }, [_vm._v(" Additional information ")]), _c('v-row', {
    staticClass: "mb-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "error-messages": _vm.customer_saving_errors.comments,
      "label": _vm.$gettext('Comments'),
      "placeholder": _vm.$translate('commentsPlaceholder')
    },
    model: {
      value: _vm.customer.comments,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "comments", $$v);
      },
      expression: "customer.comments"
    }
  })], 1)], 1)], 2) : _vm._e(), _c('template', {
    slot: "bottom"
  }, [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Cancel'),
      "ghost": ""
    },
    on: {
      "click": _vm.cancelEditClicked
    }
  }), _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.save_text,
      "disabled": !_vm.canSave || _vm.customer_saving
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }