<template>
    <div>
        <SearchObject
            :additional-params="{ ordering: 'name', ...additionalParams }"
            :allow-create="allowCreate"
            :autofocus="autofocus"
            :base-url="baseUrl"
            :create-label="$translate('create_a_provider')"
            :disabled="disabled"
            :hide-details="hideDetails"
            :item-text="itemText"
            :label="label || $translate('select_provider')"
            :readonly="readonly"
            :required="required"
            :value="value"
            @create="createProvider"
            @input="$emit('input', $event)"
            @select="$emit('select', $event)"
            display="provider"
            ref="search"
            :dense="dense"
        >
            <template slot="item" slot-scope="{ item }">
                <ProviderName :provider="item" :link="false" />
            </template>
        </SearchObject>
    </div>
</template>

<script>
import EditProvider from "@/modals/provider";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    name: "SearchProvider",
    props: {
        // The base URL to fetch items from.
        baseUrl: {
            type: String,
            default: "/api/providers/",
        },
        // Additional GET parameters to include in the API request.
        additionalParams: {
            type: Object,
            default: () => ({}),
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        // v-model binding (selected item ID).
        value: {
            type: [String, Number],
            default: null,
        },
        // Label for the autocomplete.
        label: {
            type: String,
            required: false,
        },
        // Optional limit per request.
        limit: {
            type: Number,
            default: 20,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async createProvider() {
            const result = await this.showModal({
                component: EditProvider,
                params: {
                    provider: {},
                },
            });
            if (result) {
                this.$emit("input", result);
                this.$refs.search.update();
            } else {
                this.$refs.search.reset();
            }
        },
        itemText(item) {
            return `${item.name}`;
        },
        focus() {
            this.$refs.search.focus();
        },
    },
    i18n: {
        messages: {
            en: {
                select_provider: "Select a provider",
                create_a_provider: "Create a provider",
            },
            fr: {
                select_provider: "Sélectionnez un fournisseur",
                create_a_provider: "Créer un fournisseur",
            },
        },
    },
};
</script>
