var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('infinite-autocomplete', {
    ref: "search",
    attrs: {
      "disabled": _vm.disabled,
      "items": _vm.allItems,
      "loading": _vm.loading,
      "hasMore": _vm.hasMore,
      "item-text": _vm.itemText,
      "label": _vm.labelText,
      "required": _vm.required,
      "autofocus": _vm.autofocus,
      "readonly": _vm.readonly,
      "hide-details": _vm.hideDetails,
      "dense": _vm.dense
    },
    on: {
      "menu-opened": _vm.onMenuOpened,
      "search-changed": _vm.onSearchChanged,
      "load-more": _vm.loadNextPage,
      "select": function select($event) {
        return _vm.$emit('select', $event);
      },
      "input": _vm.inputChanged
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._t("item", function () {
          return [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " ")])];
        }, {
          "item": item
        })];
      }
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }