var render = function render(){
  var _vm$billingaccount_sa, _vm$billingaccount_sa2, _vm$billingaccount_sa3, _vm$billingaccount_sa4, _vm$billingaccount_sa5, _vm$billingaccount_sa6, _vm$billingaccount_sa7, _vm$billingaccount_sa8, _vm$billingaccount_sa9, _vm$billingaccount_sa10, _vm$billingaccount_sa11, _vm$billingaccount_sa12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-billingaccount"
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$translate("title")))]), _vm.billingaccount ? _c('template', {
    slot: "content"
  }, [_c('Info', {
    attrs: {
      "elevation": 3
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("instructions")) + " ")]), _c('h6', {
    staticClass: "text-overline font-weight-bold mb-2"
  }, [_vm._v("Information")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "autofocus": "",
      "error-messages": (_vm$billingaccount_sa = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa === void 0 ? void 0 : _vm$billingaccount_sa.siren,
      "label": _vm.$translate('siren')
    },
    model: {
      value: _vm.billingaccount.siren,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount, "siren", $$v);
      },
      expression: "billingaccount.siren"
    }
  })], 1)], 1), _c('h6', {
    staticClass: "text-overline font-weight-bold mb-2"
  }, [_vm._v("Address")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "error-messages": (_vm$billingaccount_sa2 = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa2 === void 0 ? void 0 : _vm$billingaccount_sa2.name,
      "label": _vm.$translate('name')
    },
    model: {
      value: _vm.billingaccount.name,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount, "name", $$v);
      },
      expression: "billingaccount.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "error-messages": (_vm$billingaccount_sa3 = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa3 === void 0 ? void 0 : (_vm$billingaccount_sa4 = _vm$billingaccount_sa3.address) === null || _vm$billingaccount_sa4 === void 0 ? void 0 : _vm$billingaccount_sa4.email,
      "label": _vm.$translate('email')
    },
    model: {
      value: _vm.billingaccount.address.email,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount.address, "email", $$v);
      },
      expression: "billingaccount.address.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "error-messages": (_vm$billingaccount_sa5 = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa5 === void 0 ? void 0 : (_vm$billingaccount_sa6 = _vm$billingaccount_sa5.address) === null || _vm$billingaccount_sa6 === void 0 ? void 0 : _vm$billingaccount_sa6.line1,
      "label": _vm.$translate('line1')
    },
    model: {
      value: _vm.billingaccount.address.line1,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount.address, "line1", $$v);
      },
      expression: "billingaccount.address.line1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "pt-0",
    attrs: {
      "error-messages": (_vm$billingaccount_sa7 = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa7 === void 0 ? void 0 : (_vm$billingaccount_sa8 = _vm$billingaccount_sa7.address) === null || _vm$billingaccount_sa8 === void 0 ? void 0 : _vm$billingaccount_sa8.city,
      "label": _vm.$translate('city')
    },
    model: {
      value: _vm.billingaccount.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount.address, "city", $$v);
      },
      expression: "billingaccount.address.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$billingaccount_sa9 = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa9 === void 0 ? void 0 : (_vm$billingaccount_sa10 = _vm$billingaccount_sa9.address) === null || _vm$billingaccount_sa10 === void 0 ? void 0 : _vm$billingaccount_sa10.postal_code,
      "label": _vm.$translate('postalCode')
    },
    model: {
      value: _vm.billingaccount.address.postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount.address, "postal_code", $$v);
      },
      expression: "billingaccount.address.postal_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": (_vm$billingaccount_sa11 = _vm.billingaccount_saving_errors) === null || _vm$billingaccount_sa11 === void 0 ? void 0 : (_vm$billingaccount_sa12 = _vm$billingaccount_sa11.address) === null || _vm$billingaccount_sa12 === void 0 ? void 0 : _vm$billingaccount_sa12.country,
      "label": _vm.$translate('country')
    },
    model: {
      value: _vm.billingaccount.address.country,
      callback: function callback($$v) {
        _vm.$set(_vm.billingaccount.address, "country", $$v);
      },
      expression: "billingaccount.address.country"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('template', {
    slot: "bottom"
  }, [!_vm.is_readonly ? [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "ghost": "",
      "text": _vm.$gettext('Cancel')
    },
    on: {
      "click": _vm.hideModal
    }
  }), _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.$translate('continue'),
      "disabled": !_vm.canSave || _vm.billingaccount_saving,
      "loading": _vm.billingaccount_saving
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })] : [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "ghost": "",
      "text": _vm.$gettext('Close')
    },
    on: {
      "click": _vm.hideModal
    }
  })]], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }