var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-overline font-weight-bold"
  }, [_c('translate', [_vm._v("Providers and purchase prices")])], 1), _vm.editable ? _c('p', {
    staticClass: "text-body-2 mb-6"
  }, [_vm._v(" " + _vm._s(_vm.$gettext("You can add this product to the catalog of one or more provider that are managed by yourself.")) + " ")]) : _vm._e(), _vm.errors ? _vm._l(_vm.errors, function (error) {
    return _c('Error', {
      key: error,
      attrs: {
        "elevation": 1
      }
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  }) : _vm._e(), _vm._l(_vm.productproviders, function (productprovider, idx) {
    return _c('div', {
      key: JSON.stringify(productprovider),
      staticClass: "mb-3"
    }, [_c('ProductProvidersItem', {
      attrs: {
        "productprovider": productprovider,
        "productproviders": _vm.productproviders,
        "errors": _vm.providerErrors(idx),
        "providers": _vm.filteredProviders(idx),
        "editable": _vm.itemEditable(productprovider),
        "allow-create-provider": _vm.allowCreateProvider
      },
      on: {
        "remove": function remove($event) {
          return _vm.removeProvider(idx);
        },
        "update": function update($event) {
          return _vm.$emit('update');
        },
        "input": function input($event) {
          return _vm.updateCatalogProviderItem(idx, $event);
        },
        "provider-created": _vm.providerCreated
      }
    })], 1);
  }), _vm.editable && _vm.userHasPermission('catalog.add_catalogentry') && (_vm.productproviders.length < _vm.limit || _vm.limit === null) ? [_c('v-row', {
    staticClass: "mb-3"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary-ultralight-grey"
    },
    on: {
      "click": _vm.addProvider
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _c('translate', [_vm._v("Add a provider")])], 1)], 1)], 1), _vm.errors && _vm.errors.non_field_errors ? _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_vm._l(_vm.errors.non_field_errors, function (error) {
    return [_c('div', {
      key: error,
      staticClass: "col-xs col-md-12 col-lg-8 alert alert-danger mt-2",
      attrs: {
        "role": "alert"
      }
    }, [_vm._v(" " + _vm._s(error) + " ")])];
  })], 2)], 1) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }