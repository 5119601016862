<template>
    <ModalContent :help-content-id="`popup-order-${current_entity_profile}`">
        <template slot="title">{{ title }}</template>
        <template slot="content">
            <div class="b-block d-sm-flex">
                <div class="flex-shrink-1">
                    <DateTime
                        :key="delivery_date"
                        :value="delivery_date"
                        :error-messages="order_saving_errors?.delivery_date"
                        :label="$gettext('Delivery date')"
                        :only-date="true"
                        class="mb-3 justify-center d-flex"
                        fixed
                        @input="updateDeliveryDate($event)"
                    />
                </div>
                <div class="flex-grow-1 mt-4">
                    <v-row>
                        <v-col cols="12">
                            <SearchCustomer
                                :value="buyer_id"
                                :error-messages="order_saving_errors.customer"
                                :allow-create="true"
                                @input="setBuyerAction($event)"
                            />
                        </v-col>
                        <v-col cols="12" v-if="order_type == 'deliverysite' && buyer_id">
                            <v-select
                                :value="deliverysite_id"
                                @input="setDeliverySiteIdAction($event)"
                                :error-messages="order_saving_errors.deliverysite"
                                :label="$gettext('Delivery site')"
                                :disabled="
                                    !buyer_id ||
                                    deliverysiteschoices.length == 0 ||
                                    deliverysites_loading
                                "
                                :items="deliverysiteschoices"
                                :loading="deliverysites_loading"
                                :key="deliverysite_id"
                                :dense="true"
                            />
                            <div
                                v-if="deliverysiteschoices.length == 0"
                                class="grey--text font-italic"
                            >
                                {{ $translate("noDeliverySite") }}
                            </div>
                            <div v-else-if="buyer.entity" class="grey--text font-italic">
                                {{
                                    $translate("customer_connected_deliverysite_warning")
                                }}
                            </div>
                            <div
                                class="d-flex justify-end"
                                v-else-if="activeDeliverySite"
                            >
                                <a href="#" v-translate @click="editSite">Edit site</a>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <DateTime
                                :value="delivery_date"
                                @input="updateDeliveryDate($event)"
                                :key="order.delivery_date"
                                :error-messages="order_saving_errors?.delivery_date"
                                :label="$gettext('Delivery date')"
                                :only-date="true"
                                dense
                            />
                        </v-col>
                        <v-col cols="12">
                            <Field
                                v-model="order.comments"
                                :error-messages="order_saving_errors.comments"
                                :label="$gettext('Comments')"
                                :dense="true"
                            />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
        <template slot="bottom">
            <Button
                class="my-2 my-sm-4 w-100 w-sm-auto"
                :text="$gettext('Cancel')"
                ghost
                @click="hideModal"
            />
            <Button
                :text="saveText"
                class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                :disabled="!canSave"
                :key="JSON.stringify(order)"
                @click="saveInformationClicked"
            />
        </template>
    </ModalContent>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import AddCustomer from "@/modals/addcustomer";
import DeliverySiteModal from "@/modals/deliverysite";
import GlobalMixins from "@/mixins/global";
import SearchCustomer from "@/components/common/search-customer.vue";

export default {
    mixins: [GlobalMixins],
    props: {
        params: {
            type: Object,
            required: true,
        },
    },

    computed: Object.assign(
        mapState("editcustomerorder", [
            "order",
            "order_type",
            "order_saving",
            "order_saving_errors",
            "delivery_date",
            "deliverysites",
            "buyer",
            "buyers",
            "buyer_id",
            "seller_id",
            "deliverysite",
            "deliverysite_id",
            "buyers_loading",
            "deliverysites_loading",
        ]),
        mapState("settings", ["settings"]),
        {
            delivery_date_str() {
                if (this.delivery_date) {
                    return this.$moment(this.delivery_date).format("LL");
                }
                return this.$gettext("Select date");
            },
            saveText() {
                if (this.isNew) {
                    return this.$gettext("Create order");
                }
                return this.$gettext("Save order");
            },
            buyerschoices() {
                let choices = this.buyers.map((item) => ({
                    value: item.id,
                    text: item.name,
                }));
                return [
                    {
                        value: "new",
                        text: this.$gettext("Create new customer"),
                    },
                ].concat(choices);
            },
            deliverysiteschoices() {
                let choices = [];
                if (!this.buyer?.entity) {
                    choices.push({
                        value: "new",
                        text: this.$gettext("Create new site"),
                    });
                }

                if (this.deliverysites) {
                    return choices.concat(
                        this.deliverysites.map((item) => ({
                            value: item.id,
                            text: this.getDeliverySiteName(item),
                        }))
                    );
                }
                return choices;
            },
            activeDeliverySite() {
                if (!this.deliverysites) {
                    return null;
                }
                return this.deliverysites.find((item) => {
                    return item.id == this.deliverysite_id;
                });
            },
            order_typechoices() {
                return [
                    {
                        text: this.$gettext("Customer order"),
                        value: "deliverysite",
                    },
                    {
                        text: this.$gettext("Customer pickup"),
                        value: "customer",
                    },
                    {
                        text: this.$gettext("Stock withdrawal (pick up)"),
                        value: "pickup",
                    },
                ];
            },
            canSave() {
                if (
                    this.order_type == "deliverysite" &&
                    (!this.deliverysite_id || !this.buyer_id)
                ) {
                    return false;
                }

                if (this.order_type == "customer" && !this.buyer_id) {
                    return false;
                }
                if (this.delivery_date == null) {
                    return false;
                }
                return true;
            },
            title() {
                return this.params.order.id
                    ? this.$gettext("Edit order")
                    : this.$gettext("New order");
            },
            isNew() {
                return !this.order.id;
            },
        }
    ),

    mounted() {
        this.initOrder(this.params.order);
    },
    methods: Object.assign(
        mapActions("mcl", ["hideModal"]),
        mapMutations("editcustomerorder", [
            "updateDeliverySite",
            "updateDeliverySiteId",
            "updateDeliveryDate",
        ]),
        mapActions("editcustomerorder", [
            "setBuyer",
            "setDeliverySiteId",
            "initOrder",
            "fetchDeliverySites",
            "fetchDeliverySite",
            "fetchBuyers",
            "saveOrder",
        ]),
        {
            setBuyerAction(buyer) {
                this.setBuyer(buyer);
            },

            setDeliverySiteIdAction(deliverysite_id) {
                if (deliverysite_id == "new") {
                    this.showModal({
                        component: DeliverySiteModal,
                        params: {
                            deliverysite: {
                                customer: this.buyer_id,
                                customer_data: this.buyers.find(
                                    (customer) => customer.id == this.buyer_id
                                ),
                            },
                        },
                    }).then((result) => {
                        this.fetchDeliverySites();
                        if (result) {
                            this.setDeliverySiteId(result.id);
                        } else {
                            return false;
                        }
                    });
                } else {
                    if (deliverysite_id) {
                        this.updateDeliverySite(
                            this.deliverysites.find(
                                (deliverysite) => deliverysite.id == deliverysite_id
                            )
                        );
                    }
                }
            },
            editSite() {
                this.showModal({
                    component: DeliverySiteModal,
                    params: { deliverysite: this.activeDeliverySite },
                }).then((result) => {
                    if (result) {
                        this.fetchDeliverySites();
                    }
                });
            },

            saveInformationClicked() {
                this.saveOrder()
                    .then((response) => {
                        if (response.status == 201) {
                            this.addAlert(this.$gettext("Order created"));
                        } else {
                            this.addAlert(this.$gettext("Order updated"));
                        }
                        this.hideModal({ result: response.data });
                    })
                    .catch((error) => {
                        this.$swal({
                            title: this.$gettext("Failed saving order"),
                            html: this.$utils.resultToSwalContent(this, error),
                            type: "error",
                        });
                    });
            },
        }
    ),
    i18n: {
        messages: {
            en: {
                customer_connected_deliverysite_warning:
                    "This customer is connected to Safetee and defines its own delivery sites. You cannot create new ones.",
                noDeliverySite:
                    "No delivery address available. Please contact the customer to provide a delivery address.",
            },

            fr: {
                customer_connected_deliverysite_warning:
                    "Ce client est connecté à Safetee et définit ses propres adresses de livraison. Vous ne pouvez pas en créer de nouvelle.",
                noDeliverySite:
                    "Aucune adresse de livraison disponible. Veuillez contacter le client pour qu'il renseigne son adresse de livraison.",
            },
        },
    },
};
</script>
