<template>
    <ModalContent :help-content-id="`popup-customer-${current_entity_profile}`">
        <template slot="title">{{ title }}</template>
        <template slot="content">
            <div class="mb-4">
                {{ $translate("instructions") }}
            </div>
            <v-row class="d-flex align-stretch">
                <v-col cols="12" sm="6" class="d-flex">
                    <div class="pa-4 primary-lighter3 flex-grow-1 d-flex flex-column">
                        <div class="font-weight-bold mb-4">
                            {{ $translate("inviteCustomer") }}
                        </div>
                        <div
                            class="flex-grow-1"
                            v-html="$translate('inviteCustomerDescription')"
                        />
                        <v-btn
                            @click="inviteCustomerClicked"
                            class="mt-6 w-100"
                            color="primary"
                        >
                            {{ $translate("invite") }}
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex">
                    <div class="pa-4 primary-lighter3 flex-grow-1 d-flex flex-column">
                        <div class="font-weight-bold mb-4">
                            {{ $translate("createCustomer") }}
                        </div>
                        <div
                            class="flex-grow-1"
                            v-html="$translate('createCustomerDescription')"
                        />
                        <v-btn
                            @click="createCustomerClicked"
                            class="mt-6 w-100"
                            color="primary"
                        >
                            {{ $translate("create") }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template slot="bottom">
            <Button
                :text="$gettext('Cancel')"
                @click="cancelEditClicked"
                class="my-2 my-sm-4 w-100 w-sm-auto"
                ghost
            />
        </template>
    </ModalContent>
</template>

<script>
import GlobalMixins from "@/mixins/global";
import EditCustomer from "@/modals/customer.vue";
import InviteCustomer from "@/modals/invitecustomer.vue";

export default {
    mixins: [GlobalMixins],
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return this.$gettext("Add a customer");
        },
    },
    methods: {
        cancelEditClicked() {
            this.hideModal();
        },
        createCustomerClicked() {
            this.showModal({
                component: EditCustomer,
                params: {
                    customer: {},
                },
            }).then((result) => {
                this.hideModal({ result });
            });
        },
        inviteCustomerClicked() {
            this.showModal({
                component: InviteCustomer,
                params: {
                    customer: {},
                },
            }).then((result) => {
                this.hideModal({ result });
            });
        },
    },
    i18n: {
        messages: {
            en: {
                inviteCustomer: "Invite the customer to connect to you (recommended)",
                inviteCustomerDescription:
                    "Speed up your sales process and boost your customer engagement by leveraging the full power of Safetee.<br>\
                    <br>\
                    <u>Benefits for your customer:</u><br>\
                    <ul>\
                        <li>Ordering <b>24/7</b>.</li>\
                        <li><b>Real-time</b> tracking of orders and deliveries.</li>\
                        <li><b>Centralization</b> of supplier invoices.</li>\
                        <li><b>Automatic</b> stock updates.</li>\
                    </ul><br>\
                    <i>Once connected, your customer will be able to place their first order without any data entry.</i>",

                createCustomer: "Manage the customer yourself",
                createCustomerDescription:
                    "Manage your customer manually by controlling every step of your exchanges, from ordering to invoicing.<br>\
                    <br>\
                    <u>You will be able to:</u><br>\
                    <ul>\
                        <li>Enter their information.</li>\
                        <li>Enter their orders yourself.</li>\
                        <li>Trigger the sending of generated documents.</li>\
                    </ul><br/>\
                    <br class='d-none d-sm-block'>\
                    <i>You will be able to invite your customer to join you on Safetee later.</i>",
                instructions: "Choose how to add your customer.",
                cancel: "Cancel",
                invite: "Invite customer",
                create: "Create customer",
            },
            fr: {
                inviteCustomer: "Invitez votre client sur Safetee (recommandé)",

                inviteCustomerDescription:
                    "Accélérez votre processus de vente et décuplez l'engagement de votre client en profitant de toute la puissance de Safetee dans votre collaboration.<br>\
                    <br>\
                    <u>Avantages pour votre client :</u><br>\
                    <ul>\
                        <li>Passage de commande <b>24h/24 et 7j/7.</b></li>\
                        <li>Suivi de ses commandes et livraisons <b>en temps réel</b>.</li>\
                        <li>Centralisation de <b>ses factures fournisseurs</b>.</li>\
                        <li><b>Mise à jour automatique</b> de ses stocks.</li>\
                    </ul><br>\
                    <i>Dès sa connexion, votre client pourra passer sa première commande sans aucune saisie de données.</i>",
                createCustomer: "Gérez le client vous-même",
                createCustomerDescription:
                    "Gérez votre client manuellement en contrôlant chaque étape de vos échanges, de la commnade à la facturation.<br>\
                    <br>\
                    <u>Vous pourrez :</u><br>\
                    <ul>\
                        <li>Renseigner ses informations.</li>\
                        <li>Saisir vous-même ses commandes.</li>\
                        <li>Déclencher l'envoi des documents générés.</li>\
                    </ul><br/>\
                    <br class='d-none d-sm-block'>\
                    <i>Vous pourrez inviter votre client à vous rejoindre sur Safetee ultérieurement.</i>",
                instructions: "Choisissez comment ajouter votre client.",
                cancel: "Annuler",
                invite: "Inviter le client",
                create: "Créer le client",
            },
        },
    },
};
</script>
