<template>
    <SearchObject
        :additional-params="{ ordering: 'name', ...additionalParams }"
        :allow-create="allowCreate"
        :autofocus="autofocus"
        :base-url="baseUrl"
        :create-label="$translate('create_a_billingaccount')"
        :disabled="disabled"
        :hide-details="hideDetails"
        :item-text="itemText"
        :label="label || $translate('select_billingaccount')"
        :readonly="readonly"
        :required="required"
        :value="value"
        @create="createBillingAccount"
        @input="$emit('input', $event)"
        ref="search"
        :dense="dense"
    >
        <template slot="item" slot-scope="{ item }">
            {{ item.name }}
            <template v-if="item.default"> ({{ $translate("default") }})</template>
        </template>
    </SearchObject>
</template>

<script>
import EditBillingAccount from "@/modals/billingaccount";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    name: "SearchBillingAccount",
    props: {
        // The base URL to fetch items from.
        baseUrl: {
            type: String,
            default: "/api/billingaccounts/",
        },
        // Additional GET parameters to include in the API request.
        additionalParams: {
            type: Object,
            default: () => ({}),
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        // v-model binding (selected item ID).
        value: {
            type: [String, Number],
            default: null,
        },
        // Label for the autocomplete.
        label: {
            type: String,
            required: false,
        },
        // Optional limit per request.
        limit: {
            type: Number,
            default: 20,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async createBillingAccount() {
            const result = await this.showModal({
                component: EditBillingAccount,
                params: {
                    billingaccount: {},
                },
            });
            if (result) {
                this.$emit("input", result);
                this.$refs.search.update();
            } else {
                this.$refs.search.reset();
            }
        },
        itemText(item) {
            return `${item.name}`;
        },
        focus() {
            this.$refs.search.focus();
        },
    },
    i18n: {
        messages: {
            en: {
                select_billingaccount: "Select a billing account",
                create_a_billingaccount: "Create a billing account",
                default: "by default",
            },
            fr: {
                select_billingaccount: "Sélectionnez un compte de facturation",
                create_a_billingaccount: "Créer un compte de facturation",
                default: "par défaut",
            },
        },
    },
};
</script>
