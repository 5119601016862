var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.provider ? _c('div', {
    staticClass: "d-flex align-center providername"
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "d-flex align-center"
        }, _vm.isConnected ? on : null), [!_vm.link ? _c('span', [_vm._v(_vm._s(_vm.provider.name))]) : _vm.lookup ? _c('lookup', {
          attrs: {
            "object": "provider",
            "params": _vm.lookup
          }
        }, [_vm._v(" " + _vm._s(_vm.provider.name) + " ")]) : _vm.routeExists('provider') ? _c('router-link', {
          attrs: {
            "to": {
              name: 'provider',
              params: {
                provider_id: _vm.provider.id
              }
            }
          }
        }, [_c('Stroke', {
          attrs: {
            "strike": _vm.provider.archived
          }
        }, [_vm._v(" " + _vm._s(_vm.provider.name) + " ")])], 1) : _c('span', [_vm._v(_vm._s(_vm.provider.name))]), _vm.isConnected ? _c('v-icon', {
          staticClass: "ml-1 icon",
          attrs: {
            "color": _vm.link ? 'primary' : null,
            "size": "20"
          }
        }, [_vm._v("mdi-link")]) : _vm._e()], 1)];
      }
    }], null, false, 3407811777)
  }, [_vm.isConnected ? [_vm._v(" " + _vm._s(_vm.$translate("providerIsConnected")) + " ")] : _vm._e()], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }