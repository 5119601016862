<template>
    <div>
        <div class="text-overline font-weight-bold">
            <translate>Providers and purchase prices</translate>
        </div>
        <p class="text-body-2 mb-6" v-if="editable">
            {{
                $gettext(
                    "You can add this product to the catalog of one or more provider that are managed by yourself."
                )
            }}
        </p>
        <template v-if="errors">
            <Error :elevation="1" :key="error" v-for="error in errors">
                {{ error }}
            </Error>
        </template>
        <div
            class="mb-3"
            v-for="(productprovider, idx) in productproviders"
            :key="JSON.stringify(productprovider)"
        >
            <ProductProvidersItem
                :productprovider="productprovider"
                :productproviders="productproviders"
                :errors="providerErrors(idx)"
                :providers="filteredProviders(idx)"
                :editable="itemEditable(productprovider)"
                @remove="removeProvider(idx)"
                @update="$emit('update')"
                @input="updateCatalogProviderItem(idx, $event)"
                @provider-created="providerCreated"
                :allow-create-provider="allowCreateProvider"
            />
        </div>
        <template
            v-if="
                editable &&
                userHasPermission('catalog.add_catalogentry') &&
                (productproviders.length < limit || limit === null)
            "
        >
            <v-row class="mb-3">
                <v-col>
                    <v-btn @click="addProvider" small color="primary-ultralight-grey"
                        ><v-icon>mdi-plus</v-icon>
                        <translate>Add a provider</translate>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-5" v-if="errors && errors.non_field_errors">
                <v-col>
                    <template v-for="error in errors.non_field_errors">
                        <div
                            class="col-xs col-md-12 col-lg-8 alert alert-danger mt-2"
                            role="alert"
                            :key="error"
                        >
                            {{ error }}
                        </div>
                    </template>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import { readonly } from "vue";
import ProductProvidersItem from "./productproviders-item";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    components: {
        ProductProvidersItem,
    },
    props: {
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
        value: {
            type: Array,
            required: true,
            default: null,
        },
        providers: {
            required: true,
            type: Array,
        },
        errors: {
            type: Array,
            required: false,
        },
        allowCreateProvider: {
            type: Boolean,
            default: true,
        },
        limit: {
            type: Number,
            default: null,
        },
    },
    data() {
        return { productproviders: [] };
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.productproviders = this.value;
            },
        },
    },
    methods: {
        itemEditable(item) {
            if (!this.editable) {
                return false;
            }
            return !item.provider_data?.entity;
        },
        providerCreated(provider) {
            this.$emit("provider-created", provider);
        },
        providerErrors(idx) {
            if (!this.errors || this.errors.length < idx) {
                return {};
            }
            return this.errors[idx] || {};
        },
        addProvider() {
            this.productproviders = (this.productproviders || []).concat([{}]);
            this.$emit("input", this.productproviders);
        },
        removeProvider(index) {
            this.productproviders.splice(index, 1);
            this.$emit("input", this.productproviders);
        },
        updateCatalogProviderItem(idx, provider) {
            // make a copy of value, find provider occurrence in it and replace it with provider
            this.productproviders = this.productproviders.map((item, i) =>
                i === idx ? provider : item
            );
            this.$emit("input", this.productproviders);
        },
        filteredProviders(existing_idx) {
            let filtered_providers = this.providers.filter((provider) => {
                return !this.productproviders.find(
                    (existing_productprovider, idx) =>
                        existing_productprovider.provider == provider.id &&
                        idx != existing_idx
                );
            });
            return filtered_providers;
        },
    },
};
</script>
