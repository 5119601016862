var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    ref: "activator",
    attrs: {
      "value": _vm.value,
      "items": _vm.allItems,
      "loading": _vm.isLoading,
      "label": _vm.labelText,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "required": _vm.required,
      "autofocus": _vm.autofocus,
      "hide-details": _vm.hideDetails,
      "clearable": true,
      "search-input": _vm.localSearch,
      "no-filter": true,
      "item-text": _vm.itemText,
      "item-value": _vm.selectedKey,
      "attach": _vm.attach,
      "menu-props": {
        closeOnClick: false,
        closeOnContentClick: false
      },
      "return-object": "",
      "dense": _vm.dense
    },
    on: {
      "input": _vm.onSearchChange,
      "update:searchInput": function updateSearchInput($event) {
        _vm.localSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.localSearch = $event;
      },
      "update:menu": _vm.onMenuChange,
      "change": _vm.selectItem,
      "click:clear": _vm.clearSelection
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function fn() {
        return [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$translate("no_results")) + " ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "append-item",
      fn: function fn() {
        return [_vm.hasMore && !_vm.isLoading ? _c('v-list-item', {
          on: {
            "click": _vm.loadMore
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "text-body-2"
        }, [_c('b', [_vm._v(_vm._s(_vm.$translate("load_more")))])])], 1)], 1) : _vm._e(), _vm.isLoading ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._t("item", function () {
          return [_c('v-list-item-title', [_vm._v(_vm._s(_vm.itemText(item)) + "__")])];
        }, {
          "item": item
        })];
      }
    }, {
      key: "content",
      fn: function fn() {
        return [_vm._v(" fasdfasdfas "), _c('v-card', {
          staticClass: "overflow-y-auto",
          style: _vm.cardStyle,
          on: {
            "&scroll": function scroll($event) {
              return _vm.onScroll.apply(null, arguments);
            }
          }
        }, [_c('v-list', _vm._l(_vm.items, function (item, index) {
          return _c('v-list-item', {
            key: item[_vm.selectedKey] || index,
            on: {
              "click": function click($event) {
                return _vm.selectItem(item);
              }
            }
          }, [_vm._v("LLL "), _c('v-list-item-content', [_c('v-list-item-title')], 1)], 1);
        }), 1)], 1)];
      },
      proxy: true
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }