<template>
    <ModalContent :help-content-id="`popup-customer-${current_entity_profile}`">
        <template slot="title">{{ $translate("title") }}</template>
        <template slot="content">
            <template v-if="catalogs_loading">
                <Loading :absolute="false" :size="130" class="py-0" />
            </template>
            <template v-else>
                <template v-if="usemail">
                    <div class="mb-4 link grey--text" @click="searchClicked">
                        <v-icon>mdi-arrow-left</v-icon
                        ><span>{{ $translate("backToSearch") }}</span>
                    </div>
                    <div class="text-overline font-weight-bold">
                        {{ $translate("youSelected") }}
                    </div>

                    <div class="mb-4">{{ $translate("emailInstructions") }}</div>
                    <div class="d-flex mt-8">
                        <v-text-field
                            class="flex-grow-1 mt-0 pt-0"
                            :value="email"
                            @input="updateEmail"
                            :label="$translate('labels.email')"
                            autocomplete="new-password"
                            required
                            hide-details
                            :autofocus="true"
                            :clearable="true"
                            ref="email"
                            name="no-auto-fill"
                        />
                    </div>
                    <div class="d-flex mt-8" v-if="!customer?.id">
                        <v-text-field
                            class="flex-grow-1 mt-0 pt-0"
                            :value="name"
                            type="email"
                            @input="updateName"
                            :label="$translate('labels.name')"
                            autocomplete="new-password"
                            required
                            hide-details
                            :clearable="true"
                            ref="name"
                        />
                    </div>
                </template>
                <template v-else-if="!entity">
                    <div class="mb-4">{{ $translate("instructions") }}</div>
                    <div class="d-flex mt-8">
                        <v-text-field
                            class="flex-grow-1 mt-0 pt-0"
                            :value="search"
                            @input="onSearchInput"
                            :label="$translate('labels.search')"
                            autocomplete="new-password"
                            autofocus
                            required
                            hide-details
                            :loading="searching"
                            :clearable="true"
                            ref="search"
                        />
                    </div>
                    <div v-if="!search" class="mt-6 font-italic">
                        {{ $translate("safeteeIdUnknown") }}
                        <span class="link" @click="useMailClicked()">
                            {{ $translate("inviteByEmail") }}</span
                        >
                    </div>
                    <div class="mt-4" v-else>
                        <template v-if="results.length == 10">
                            <span class="font-italic mb-8 mt-6">{{
                                $translate("limitedResults")
                            }}</span>
                        </template>

                        <template v-if="results.length">
                            <List :items="results">
                                <template slot="item" slot-scope="scope">
                                    <div class="pa-2 d-sm-flex">
                                        <div class="flex-grow-1 align-center mb-4">
                                            <span class="font-weight-bold"
                                                >{{ scope.item.name }}
                                            </span>
                                            <template v-if="scope.item.persona"
                                                >-
                                                {{
                                                    $utils.getPersonaLabel(
                                                        scope.item.persona
                                                    )
                                                }}</template
                                            >
                                            -
                                            <span class="font-italic grey--text">{{
                                                scope.item.safetee_id
                                            }}</span>
                                        </div>
                                        <div>
                                            <v-btn
                                                :disabled="
                                                    scope.item.customer ||
                                                    scope.item.customer_invited
                                                "
                                                @click="updateEntity(scope.item)"
                                                color="primary"
                                            >
                                                <template v-if="scope.item.customer">
                                                    {{
                                                        $translate(
                                                            "buttons.selectEntity.connected"
                                                        )
                                                    }}
                                                </template>
                                                <template
                                                    v-else-if="
                                                        scope.item.customer_invited
                                                    "
                                                >
                                                    {{
                                                        $translate(
                                                            "buttons.selectEntity.invited"
                                                        )
                                                    }}
                                                </template>
                                                <template v-else>
                                                    {{
                                                        $translate(
                                                            "buttons.selectEntity.select"
                                                        )
                                                    }}
                                                </template>
                                            </v-btn>
                                        </div>
                                    </div>
                                </template>
                            </List>
                            <span class="font-italic">{{ $translate("cantFind") }}</span
                            >&nbsp;
                            <span class="link font-italic" @click="useMailClicked">
                                {{ $translate("inviteByEmail") }}
                            </span>
                        </template>
                        <div v-else class="mt-6 font-italic">
                            <span class="font-italic font-weight-bold warning--text">
                                <template v-if="search.length < 4">
                                    {{ $translate("moreCharsNeeded") }}
                                </template>
                                <template v-else>
                                    {{ $translate("noResults") }}
                                </template>
                            </span>
                            &nbsp;
                            <span class="link font-italic" @click="useMailClicked">
                                {{ $translate("inviteByEmail") }}
                            </span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="mb-4 link grey--text"
                        @click="updateEntity(null)"
                        v-if="!params.entity"
                    >
                        <v-icon>mdi-arrow-left</v-icon
                        ><span>{{ $translate("backToSearch") }}</span>
                    </div>
                    <div class="text-overline font-weight-bold">
                        {{ $translate("youSelected") }}
                    </div>
                    <div class="text-h7">
                        <v-text-field
                            :value="entity.name"
                            :label="$translate('labels.name')"
                            :disabled="true"
                            :hide-details="true"
                        />
                        <v-text-field
                            class="mt-4"
                            :value="entity.safetee_id"
                            :label="$translate('labels.safetee_id')"
                            :disabled="true"
                            :hide-details="true"
                        />
                    </div>
                </template>
                <template v-if="usemail || entity">
                    <template v-if="isCustomer">
                        <div class="text-overline font-weight-bold mt-7">
                            {{ $translate("catalogsTitle") }}
                        </div>
                        <div>{{ $translate("chooseCatalogs") }}</div>
                        <v-row class="mb-4 mt-2">
                            <v-col
                                cols="12"
                                lg="6"
                                class="py-0"
                                :key="catalog?.id"
                                v-for="catalog in myCatalogs"
                            >
                                <v-checkbox
                                    :input-value="isCatalogAllowed(catalog)"
                                    :label="catalog.name"
                                    class="py-0"
                                    hide-details
                                    dense
                                    single-line
                                    outlined
                                    @change="toggleCatalog(catalog)"
                                >
                                    <template v-slot:label>
                                        <div class="text-body-2">
                                            {{ catalog.name }}
                                            <span v-if="catalog.default"
                                                >&nbsp;({{ $translate("default") }})
                                            </span>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <template v-if="providersCatalogs.length">
                            <div class="text-overline font-weight-bold mt-7">
                                {{ $translate("providersCatalogsTitle") }}
                            </div>
                            <div>{{ $translate("providersCatalogsInstructions") }}</div>
                            <v-row class="mb-4 mt-2">
                                <v-col
                                    cols="12"
                                    class="py-0"
                                    :key="provider_catalog?.id"
                                    v-for="provider_catalog in providersCatalogs"
                                >
                                    <v-checkbox
                                        :input-value="isCatalogAllowed(provider_catalog)"
                                        :label="provider_catalog.name"
                                        class="py-0"
                                        hide-details
                                        dense
                                        single-line
                                        outlined
                                        @change="toggleCatalog(provider_catalog)"
                                    >
                                        <template v-slot:label>
                                            <div class="text-body-2">
                                                <b>
                                                    {{
                                                        provider_catalog.owner_data.name
                                                    }}</b
                                                >
                                                -
                                                {{ provider_catalog.name }}
                                                <span
                                                    v-if="
                                                        provider_catalog.effective_default
                                                    "
                                                    >&nbsp;({{ $translate("default") }})
                                                </span>
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </template>
                    </template>
                    <template v-else>
                        <Select
                            class="mt-4"
                            :options="catalogChoices"
                            :label="$translate('labels.catalog')"
                            :value="catalog"
                            @input="updateCatalog"
                            :hide-details="true"
                        />
                    </template>
                    <!-- <div>
                        <div class="text-overline font-weight-bold">
                            {{ $translate("message") }}
                        </div>
                        <v-textarea
                            class="mt-4"
                            v-model="message"
                            :label="$translate('labels.message')"
                            :placeholder="$translate('placeholders.message')"
                            @input="updateMessage"
                            outlined
                            rows="3"
                            hide-details
                        >
                        </v-textarea>
                    </div> -->
                </template>
            </template>
        </template>
        <template slot="bottom">
            <Button
                :text="$gettext('Cancel')"
                @click="cancelClicked"
                class="my-2 my-sm-4 w-100 w-sm-auto"
                ghost
            />
            <Button
                v-if="entity || usemail"
                class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                :text="$translate('buttons.inviteCustomer')"
                :disabled="!canSave"
                :loading="customer_inviting"
                @click="inviteClicked"
            />
        </template>
    </ModalContent>
</template>

<script>
import debounce from "debounce";

import { mapState, mapActions, mapMutations } from "vuex";
import GlobalMixins from "@/mixins/global";
import EditEntity from "@/modals/entity";
import utils from "@/utils";

export default {
    mixins: [GlobalMixins],
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState("invitecustomer", [
            "customer_inviting",
            "entity",
            "customer",
            "search",
            "results",
            "searching",
            "usemail",
            "email",
            "name",
            "catalog",
            "allowed_catalogs",
        ]),
        ...mapState("invitecustomer/catalogs", ["catalogs", "catalogs_loading"]),

        canSave() {
            if (this.customer_inviting) {
                return false;
            }

            if (!this.allowed_catalogs?.length && !this.providersCatalogs.length) {
                return false;
            }
            if (this.usemail) {
                if (this.customer) {
                    return this.$utils.emailIsValid(this.email);
                }
                return this.$utils.emailIsValid(this.email) && !!this.name;
            }
            return !!this.entity;
        },
        catalogChoices() {
            return this.catalogs.map((catalog) => {
                return {
                    value: catalog.id,
                    text: catalog.name,
                };
            });
        },
        providersCatalogs() {
            return this.catalogs.filter(
                (catalog) => catalog.owner != this.current_entity_id
            );
        },
        myCatalogs() {
            return this.catalogs.filter(
                (catalog) => catalog.owner == this.current_entity_id
            );
        },
    },
    methods: {
        ...mapActions("invitecustomer", ["init", "searchCustomer", "inviteCustomer"]),
        ...mapMutations("invitecustomer", [
            "updateAllowedCatalogs",
            "updateSearch",
            "updateEntity",
            "updateUsemail",
            "updateEmail",
            "updateName",
            "updateMessage",
            "updateCatalog",
            "updateCatalogs",
        ]),
        searchClicked() {
            this.updateSearch(this.name);
            this.updateName(null);
            this.updateEntity(null);
            this.updateUsemail(false);
            this.$nextTick(() => {
                this.$refs.search.focus();
            });
        },
        useMailClicked() {
            if (this.search && this.search.includes("@")) {
                this.updateEmail(this.search);
                this.updateName(null);
            } else {
                this.updateName(this.search);
            }
            this.updateUsemail(true);
            this.$nextTick(() => {
                if (this.email) {
                    this.$refs.name?.focus();
                } else {
                    this.$refs.email.focus();
                }
            });
        },
        isCatalogAllowed(catalog) {
            if (!this.allowed_catalogs) {
                return false;
            }
            return this.allowed_catalogs.indexOf(catalog.id) != -1;
        },
        toggleCatalog(catalog) {
            let allowed_catalogs = this.allowed_catalogs || [];
            let index = allowed_catalogs.indexOf(catalog.id);
            if (index == -1) {
                allowed_catalogs.push(catalog.id);
            } else {
                allowed_catalogs.splice(index, 1);
            }
            this.updateAllowedCatalogs(allowed_catalogs);
        },
        cancelClicked() {
            this.hideModal();
        },

        async inviteClicked() {
            if (!utils.isEntityInformationComplete(this.current_entity)) {
                const result = await this.showModal({
                    component: EditEntity,
                    params: {
                        entity: this.current_entity,
                    },
                });
                if (!result) {
                    return;
                }
            }
            try {
                let customer = await this.inviteCustomer();
                this.hideModal({ result: customer });
                this.addAlert(this.$translate("alerts.customerInvited"));
            } catch (error) {
                this.$swal({
                    title: this.$translate("errorInviting"),
                    html: this.$utils.resultToSwalContent(this, error),
                    type: "error",
                });
            }
        },
        async searchCustomerClicked() {
            try {
                let response = await this.searchCustomer(this.search);
            } catch (error) {
                this.$swal({
                    title: this.$translate("errorSearching"),
                    html: this.$utils.resultToSwalContent(this, error),
                    type: "error",
                });
            }
        },
        onSearchInput: debounce(function (value) {
            this.updateSearch(value);
            this.searchCustomer();
        }, 300),
    },
    created() {
        this.init(this.params);
    },
    i18n: {
        messages: {
            en: {
                title: "Invite a customer",
                instructions:
                    "Please search for your customer by entering their name or Safetee ID.",
                emailInstructions:
                    "Please enter the email address of the customer you want to invite.",
                buttons: {
                    inviteCustomer: "Invite customer",
                    search: "Search for my customer",
                    selectEntity: {
                        connected: "Already connected",
                        invited: "Already invited",
                        select: "Select",
                    },
                },
                labels: {
                    name: "Customer name (*)",
                    catalog: "Sales catalog (*)",
                    search: "Name or Safetee ID (*)",
                    email: "Customer email (*)",
                    safetee_id: "Safetee ID",
                },
                alerts: {
                    customerInvited: "Invitation sent",
                },
                catalogsTitle: "Sales catalogs",
                errorInviting: "Error inviting customer",
                errorSearching: "Error searching for customer",
                safeteeId: "Safetee ID:",
                name: "Name:",
                limitedResults: "Results limited to 10 customers",
                youSelected: "Customer information",
                chooseCatalogs:
                    "Please choose at least one sales catalog to assign to the customer",
                inviteByEmail: "Invite by email.",
                searchCustomers: "Search for a customer",
                noResults: "No customers found.",
                backToSearch: "Back to search",
                safeteeIdUnknown: "Can't find your customer?",
                cantFind: "Can't find your customer?",
                moreCharsNeeded: "Please enter at least 4 characters",
                providersCatalogsTitle: "Providers catalogs",
                providersCatalogsInstructions:
                    "By default, all sales catalogs from your providers are accessible to all your customers. However, if a provider shares several sales catalogs with you and designates one as default, only this one will be accessible to your customers, unless you decide to give them access to another catalog from this provider. This operation allows your providers to share several price catalogs, one of which is the default, while allowing you to choose which customers can access the other catalogs with preferential rates.",
            },
            fr: {
                title: "Inviter un client",
                instructions:
                    "Veuilez rechercher votre client en entrant son nom ou son identifiant Safetee.",
                emailInstructions:
                    "Veuillez entrer l'adresse email du client que vous souhaitez inviter.",
                buttons: {
                    inviteCustomer: "Inviter le client",
                    search: "Rechercher mon client",
                    selectEntity: {
                        connected: "Déjà connecté",
                        invited: "Déjà invité",
                        select: "Sélectionner",
                    },
                },
                labels: {
                    name: "Nom du client (*)",
                    catalog: "Catalogue de vente (*)",
                    search: "Nom ou identifiant Safetee (*)",
                    email: "Email du client (*)",
                    safetee_id: "Identifiant Safetee",
                    message: "Message",
                },
                placeholders: {
                    message: "Ce message sera envoyé au client avec l'invitation.",
                },
                alerts: {
                    customerInvited: "Invitation envoyée",
                },
                catalogsTitle: "Catalogues de vente",
                errorInviting: "Erreur lors de l'invitation du client",
                errorSearching: "Erreur lors de la recherche du client",
                safeteeId: "Identifiant Safetee :",
                name: "Nom :",
                limitedResults: "Résultats limités à 10 clients",
                youSelected: "Informations du client",
                chooseCatalogs:
                    "Veuillez choisir le(s) catalogue(s) de vente à assigner au client",
                inviteByEmail: "Invitez-le par email.",
                searchCustomers: "Effectuez une recherche",
                noResults: "Aucun client trouvé.",
                backToSearch: "Retour à la recherche",
                safeteeIdUnknown: "Vous ne trouvez pas votre client ?",
                cantFind: "Vous ne trouvez pas votre client ?",
                moreCharsNeeded: "Veuillez entrer au moins 4 caractères",
                providersCatalogsTitle: "Catalogues fournisseurs",
                providersCatalogsInstructions:
                    "Par défaut, tous les catalogues de vente de vos fournisseurs sont accessibles à l'ensemble de vos clients. Cependant, si un fournisseur vous partage plusieurs catalogues et en désigne un par défaut, seul celui-ci sera accessible à vos clients, à moins que vous ne décidiez de leur donner accès à un autre catalogue de ce fournisseur. Ce fonctionnement permet à vos fournisseurs de partager plusieurs catalogues de prix, dont un par défaut, tout en vous laissant la possibilité de choisir quels clients peuvent accéder aux autres catalogues avec des tarifs préférentiels.",
            },
        },
    },
};
</script>
