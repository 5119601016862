<template>
    <ModalContent :help-content-id="`popup-billingaccount`">
        <template slot="title">{{ $translate("title") }}</template>
        <template slot="content" v-if="billingaccount">
            <Info :elevation="3">
                {{ $translate("instructions") }}
            </Info>
            <h6 class="text-overline font-weight-bold mb-2">Information</h6>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        class="pt-0"
                        v-model="billingaccount.siren"
                        autofocus
                        :error-messages="billingaccount_saving_errors?.siren"
                        :label="$translate('siren')"
                    />
                </v-col>
            </v-row>
            <h6 class="text-overline font-weight-bold mb-2">Address</h6>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        class="pt-0"
                        v-model="billingaccount.name"
                        :error-messages="billingaccount_saving_errors?.name"
                        :label="$translate('name')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        class="pt-0"
                        v-model="billingaccount.address.email"
                        :error-messages="billingaccount_saving_errors?.address?.email"
                        :label="$translate('email')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        class="pt-0"
                        v-model="billingaccount.address.line1"
                        :error-messages="billingaccount_saving_errors?.address?.line1"
                        :label="$translate('line1')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        class="pt-0"
                        v-model="billingaccount.address.city"
                        :error-messages="billingaccount_saving_errors?.address?.city"
                        :label="$translate('city')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="billingaccount.address.postal_code"
                        :error-messages="
                            billingaccount_saving_errors?.address?.postal_code
                        "
                        :label="$translate('postalCode')"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="billingaccount.address.country"
                        :error-messages="billingaccount_saving_errors?.address?.country"
                        :label="$translate('country')"
                    />
                </v-col>
            </v-row>
        </template>
        <template slot="bottom">
            <template v-if="!is_readonly">
                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                    ghost
                    :text="$gettext('Cancel')"
                    @click="hideModal"
                />
                <Button
                    class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                    :text="$translate('continue')"
                    :disabled="!canSave || billingaccount_saving"
                    :loading="billingaccount_saving"
                    @click="saveInformationClicked"
                />
            </template>
            <template v-else>
                <Button
                    ghost
                    :text="$gettext('Close')"
                    @click="hideModal"
                    class="my-2 my-sm-4 w-100 w-sm-auto"
                />
            </template>
        </template>
    </ModalContent>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    computed: Object.assign(
        mapState("editbillingaccount", [
            "billingaccount",
            "billingaccount_saving",
            "billingaccount_saving_errors",
        ]),
        {
            canSave() {
                return (
                    this.billingaccount.siren &&
                    this.billingaccount.address?.city &&
                    this.billingaccount.address?.country &&
                    this.billingaccount.address?.email &&
                    this.billingaccount.address?.line1 &&
                    this.billingaccount.address?.postal_code
                );
            },
        }
    ),
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.init({ billingaccount: this.params.billingaccount });
    },
    methods: Object.assign(
        mapMutations("editbillingaccount", ["updateBillingAccount"]),
        mapActions("editbillingaccount", ["saveBillingAccount", "init"]),
        {
            saveInformationClicked() {
                this.saveBillingAccount({ instance: this.billingaccount })
                    .then((response) => {
                        this.hideModal({ result: response.data });
                    })
                    .catch((error) => {
                        this.$swal({
                            title: this.$translate("failed"),
                            html: this.$utils.resultToSwalContent(this, error),
                            type: "error",
                        });
                    });
            },
        }
    ),
    i18n: {
        messages: {
            en: {
                title: "Complete your informations",
                instructions:
                    "Please complete the billing account informations assigned to the customer",
                failed: "Failed saving informations",
                save: "Save informations",
                siren: "Your company SIREN",
                email: "Contact email",
                line1: "Address",
                city: "City",
                postalCode: "Postal code",
                country: "Country",
                continue: "Continue",
                required: "This field is required",
                name: "Name",
            },
            fr: {
                title: "Complétez vos informations",
                instructions:
                    "Veuillez compléter les informations du compte de facturation.",
                failed: "Échec de l'enregistrement des informations",
                save: "Enregistrer les informations",
                siren: "SIREN de votre entreprise",
                email: "Email de contact",
                line1: "Adresse",
                city: "Ville",
                postalCode: "Code postal",
                country: "Pays",
                continue: "Continuer",
                required: "Ce champ est requis",
                name: "Nom",
            },
        },
    },
};
</script>
