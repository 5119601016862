var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-provider-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('template', {
    slot: "content"
  }, [_vm.isExternal ? _c('Warning', {
    staticClass: "mb-2",
    attrs: {
      "elevation": 2
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("instructionsExternal")) + " ")]) : _vm._e(), _c('h5', {
    staticClass: "text-overline font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$translate("providerConfiguration")) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.provider_saving_errors.name,
      "label": _vm.$translate('labels.name'),
      "autofocus": "",
      "required": "",
      "dense": true
    },
    model: {
      value: _vm.provider.name,
      callback: function callback($$v) {
        _vm.$set(_vm.provider, "name", $$v);
      },
      expression: "provider.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.provider_saving_errors.code,
      "label": _vm.$translate('labels.code'),
      "placeholder": _vm.$translate('codePlaceholder'),
      "dense": true,
      "name": "code"
    },
    model: {
      value: _vm.provider.code,
      callback: function callback($$v) {
        _vm.$set(_vm.provider, "code", $$v);
      },
      expression: "provider.code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.provider_saving_errors.siren,
      "disabled": _vm.isExternal,
      "label": _vm.$gettext('SIREN'),
      "dense": true
    },
    model: {
      value: _vm.provider.siren,
      callback: function callback($$v) {
        _vm.$set(_vm.provider, "siren", $$v);
      },
      expression: "provider.siren"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.provider_saving_errors.vat_number,
      "label": _vm.$gettext('VAT number'),
      "disabled": _vm.isExternal,
      "dense": true
    },
    model: {
      value: _vm.provider.vat_number,
      callback: function callback($$v) {
        _vm.$set(_vm.provider, "vat_number", $$v);
      },
      expression: "provider.vat_number"
    }
  })], 1), !_vm.isExternal ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": Number.isFinite(_vm.provider.vat_rate) ? _vm.provider.vat_rate * 100 : null,
      "error-messages": _vm.provider_saving_errors.vat_rate,
      "label": _vm.$gettext('VAT rate (%)'),
      "disabled": _vm.isExternal,
      "dense": true
    },
    on: {
      "input": function input($event) {
        _vm.provider.vat_rate = Number.isFinite(parseFloat($event)) ? $event / 100 : null;
      }
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('AddressForm', {
    attrs: {
      "title": _vm.$gettext('Address'),
      "value": _vm.provider.address || {},
      "errors": _vm.provider_saving_errors.address,
      "readonly": _vm.isExternal,
      "dense": true
    },
    on: {
      "input": function input($event) {
        _vm.provider.address = $event;
      }
    }
  })], 1)], 1)], 1), _c('template', {
    slot: "bottom"
  }, [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Cancel'),
      "ghost": ""
    },
    on: {
      "click": _vm.cancelEditClicked
    }
  }), _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.saveText,
      "disabled": _vm.canSave || _vm.provider_saving,
      "loading": _vm.provider_saving
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }