var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-customer-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('template', {
    slot: "content"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$translate("instructions")) + " ")]), _c('v-row', {
    staticClass: "d-flex align-stretch"
  }, [_c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "pa-4 primary-lighter3 flex-grow-1 d-flex flex-column"
  }, [_c('div', {
    staticClass: "font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$translate("inviteCustomer")) + " ")]), _c('div', {
    staticClass: "flex-grow-1",
    domProps: {
      "innerHTML": _vm._s(_vm.$translate('inviteCustomerDescription'))
    }
  }), _c('v-btn', {
    staticClass: "mt-6 w-100",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.inviteCustomerClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("invite")) + " ")])], 1)]), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "pa-4 primary-lighter3 flex-grow-1 d-flex flex-column"
  }, [_c('div', {
    staticClass: "font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$translate("createCustomer")) + " ")]), _c('div', {
    staticClass: "flex-grow-1",
    domProps: {
      "innerHTML": _vm._s(_vm.$translate('createCustomerDescription'))
    }
  }), _c('v-btn', {
    staticClass: "mt-6 w-100",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.createCustomerClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("create")) + " ")])], 1)])], 1)], 1), _c('template', {
    slot: "bottom"
  }, [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Cancel'),
      "ghost": ""
    },
    on: {
      "click": _vm.cancelEditClicked
    }
  })], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }