<template>
    <ModalContent :help-content-id="`popup-customer-${current_entity_profile}`">
        <template slot="title">{{ title }}</template>
        <template slot="content" v-if="customer !== null">
            <Warning :elevation="2" v-if="isExternal">
                {{ $translate("instructionsExternal") }}
            </Warning>

            <h5 class="text-overline font-weight-bold mb-4">
                {{ $translate("customerConfiguration") }}
            </h5>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="customer.name"
                        :error-messages="customer_saving_errors.name"
                        :label="$gettext('Name')"
                        name="name"
                        required
                        autofocus
                        :dense="true"
                    />
                </v-col>

                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="customer.code"
                        :error-messages="customer_saving_errors.code"
                        :label="
                            $gettext('Code (automatically generated if not provided)')
                        "
                        name="code"
                        :dense="true"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        required
                        class="flex-grow-0 w-sm-100"
                        :label="$gettext('Type')"
                        :error-messages="customer_saving_errors.type"
                        :items="typesChoices"
                        :value="customer.type || 'company'"
                        :disabled="isExternal"
                        @input="
                            customer.type = $event;
                            $forceUpdate();
                        "
                        :hide-details="false"
                        :dense="true"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-select
                        required
                        class="flex-grow-0 w-sm-100"
                        :label="$gettext('Business activity')"
                        :error-messages="customer_saving_errors.persona"
                        :items="personasChoices"
                        :value="customer.persona || 'company'"
                        :disabled="isExternal"
                        @input="
                            customer.persona = $event;
                            $forceUpdate();
                        "
                        :hide-details="false"
                        :dense="true"
                    />
                </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    v-if="customer.type == 'company' || !customer.type"
                >
                    <v-text-field
                        class="flex-grow-0 w-sm-100"
                        v-model="customer.siren"
                        :error-messages="customer_saving_errors.siren"
                        :disabled="isExternal"
                        :label="$gettext('SIREN')"
                        name="siren"
                        :dense="true"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    v-if="customer.type == 'company' || !customer.type"
                >
                    <v-text-field
                        class="flex-grow-0 w-sm-100"
                        v-model="customer.vat_number"
                        :error-messages="customer_saving_errors.vat_number"
                        :label="$gettext('VAT number')"
                        :disabled="isExternal"
                        name="vat_number"
                        :dense="true"
                    />
                </v-col>

                <template v-if="catalogs">
                    <template v-if="isOperator">
                        <v-col cols="12" lg="6">
                            <Select
                                required
                                class="flex-grow-0 w-sm-100"
                                :label="$gettext('Catalog')"
                                :error-messages="customer_saving_errors.catalog"
                                :options="
                                    catalogs.map((c) => ({
                                        text:
                                            c.name +
                                            (c.default
                                                ? ` (${$gettext('default catalog')})`
                                                : ''),
                                        value: c.id,
                                    }))
                                "
                                :value="allowed_catalogs ? allowed_catalogs[0] : null"
                                @input="
                                    updateAllowedCatalogs([$event]);
                                    $forceUpdate();
                                "
                                :dense="true"
                            />
                        </v-col>
                    </template>
                </template>
            </v-row>

            <template>
                <h5 class="text-overline font-weight-bold mt-4 mb-4" v-translate>
                    Billing information
                </h5>
                <v-row>
                    <v-col cols="12" sm="6" v-if="billingaccounts.length">
                        <Select
                            required
                            class="flex-grow-0 w-sm-100"
                            :label="$gettext('Billing account')"
                            :error-messages="customer_saving_errors.billingaccount"
                            :options="billingaccountsChoices"
                            :value="customer.billingaccount"
                            :loading="billingaccounts_loading"
                            :disabled="billingaccounts_loading"
                            @input="changeBillingAccountId($event)"
                            :dense="true"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            class="flex-grow-0 w-sm-100"
                            :value="
                                Number.isFinite(parseFloat(customer.force_vat_rate))
                                    ? parseFloat(
                                          (customer.force_vat_rate * 100).toFixed(2)
                                      )
                                    : null
                            "
                            @focusout="
                                customer.force_vat_rate =
                                    $event === null
                                        ? null
                                        : parseFloat(($event / 100).toFixed(2))
                            "
                            :error-messages="customer_saving_errors.force_vat_rate"
                            :label="$gettext('VAT rate (%)')"
                            name="force_vat_rate"
                            :dense="true"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            class="flex-grow-0 w-sm-100"
                            v-model="customer.default_payment_delay"
                            :error-messages="customer_saving_errors.default_payment_delay"
                            :label="$gettext('Payment delay')"
                            name="default_payment_delay"
                            :dense="true"
                        />
                    </v-col>

                    <v-col cols="12">
                        <AddressForm
                            :title="$gettext('Billing address')"
                            v-model="customer.billing_address"
                            :readonly="isExternal"
                            :errors="customer_saving_errors.billing_address"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-if="stocks && isOperator">
                <h5 class="text-overline font-weight-bold mt-6" v-translate>
                    Warehouse(s)
                </h5>
                <div>
                    {{
                        $gettext(
                            "Select the warehouses the customer is allowed to use. You cannot unselect a warehouse customer has at least one product stocked in it."
                        )
                    }}
                </div>
                <v-row class="mb-4">
                    <v-col cols="12" lg="6" :key="stock.id" v-for="stock in stocks">
                        <v-checkbox
                            :input-value="isStockAllowed(stock)"
                            :label="stock.name"
                            dense
                            single-line
                            outlined
                            @change="toggleStock(stock)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </template>

            <template v-if="!isOperator">
                <template v-if="myCatalogs.length">
                    <h5 class="text-overline font-weight-bold mt-4">
                        {{ $translate("myCatalogsTitle") }}
                    </h5>
                    <p>
                        {{ $translate("myCatalogsInstructions") }}
                    </p>

                    <v-row class="mb-8 mt-0">
                        <v-col
                            cols="12"
                            lg="6"
                            class="py-0"
                            :key="catalog?.id"
                            v-for="catalog in myCatalogs"
                        >
                            <v-checkbox
                                :input-value="isCatalogAllowed(catalog)"
                                :label="catalog.name"
                                class="py-0 text-body-2"
                                dense
                                single-line
                                outlined
                                @change="toggleCatalog(catalog)"
                                hide-details
                            >
                                <template v-slot:label>
                                    <div class="text-body-2">
                                        <b>{{ catalog.name }}</b>
                                        <span v-if="catalog.effective_default"
                                            >&nbsp;({{ $translate("default") }})
                                        </span>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="providersCatalogs.length">
                    <h5 class="text-overline font-weight-bold mt-4" v-translate>
                        Providers catalogs
                    </h5>
                    <p>
                        {{ $translate("providersCatalogsInstructions") }}
                    </p>
                    <v-row class="mb-8 mt-2">
                        <v-col
                            cols="12"
                            lg="12"
                            class="py-0"
                            :key="provider_catalog?.id"
                            v-for="provider_catalog in providersCatalogs"
                        >
                            <v-checkbox
                                :input-value="isCatalogAllowed(provider_catalog)"
                                :label="provider_catalog.name"
                                class="py-0 mb-2"
                                dense
                                single-line
                                outlined
                                @change="toggleCatalog(provider_catalog)"
                                hide-details
                                :disabled="isCatalogDisabled(provider_catalog)"
                            >
                                <template v-slot:label>
                                    <div class="text-body-2">
                                        <b> {{ provider_catalog.owner_data.name }}</b> -
                                        {{ provider_catalog.name }}
                                        <span v-if="provider_catalog.effective_default"
                                            >&nbsp;({{ $translate("default") }})
                                        </span>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </template>
            </template>
            <h5 class="text-overline font-weight-bold mt-6" v-translate>
                Additional information
            </h5>
            <v-row class="mb-4">
                <v-col cols="12">
                    <v-textarea
                        v-model="customer.comments"
                        :error-messages="customer_saving_errors.comments"
                        :label="$gettext('Comments')"
                        :placeholder="$translate('commentsPlaceholder')"
                    />
                </v-col>
            </v-row>
        </template>
        <template slot="bottom">
            <Button
                :text="$gettext('Cancel')"
                class="my-2 my-sm-4 w-100 w-sm-auto"
                ghost
                @click="cancelEditClicked"
            />
            <Button
                class="my-2 my-sm-4 w-100 w-sm-auto ml-sm-3"
                :text="save_text"
                :disabled="!canSave || customer_saving"
                @click="saveInformationClicked"
            />
        </template>
    </ModalContent>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState("editcustomer/stocks", ["stocks", "stocks_loaded"]),
        ...mapState("editcustomer/catalogs", ["catalogs", "catalogs_loaded"]),
        ...mapState("editcustomer", [
            "customer",
            "allowed_stocks",
            "allowed_catalogs",
            "customer_saving",
            "customer_saving_errors",
        ]),
        ...mapState("editcustomer/billingaccounts", [
            "billingaccounts",
            "billingaccounts_loading",
        ]),
        myCatalogs() {
            return this.catalogs.filter(
                (catalog) => catalog.owner == this.current_entity_id && !catalog.provider
            );
        },
        providersCatalogs() {
            return this.catalogs.filter(
                (catalog) => catalog.owner != this.current_entity_id || catalog.provider
            );
        },
        isExternal() {
            return !!this.customer?.entity;
        },
        canSave() {
            if (!this.catalogs_loaded || !this.stocks_loaded) {
                return false;
            }
            return this.customer.name;
        },
        typesChoices() {
            return [
                { text: this.$gettext("Company"), value: "company" },
                { text: this.$gettext("Individual"), value: "individual" },
            ];
        },
        personasChoices() {
            // Sort by text except 'other' code that remains at last
            return this.$utils
                .getPersonasChoices()
                .sort((a, b) => (a.value === "other" ? 1 : a.text.localeCompare(b.text)));
        },
        isNew() {
            return !this.customer?.id;
        },
        save_text() {
            if (this.isNew) {
                return this.$gettext("Create customer");
            }
            return this.$gettext("Save informations");
        },
        title() {
            return this.params.customer.id
                ? Vue.prototype.$gettext("Edit a customer")
                : Vue.prototype.$gettext("Add a customer");
        },
        billingaccountsChoices() {
            let defaultvalue = [
                {
                    text: this.$gettext("Default billing account"),
                    value: null,
                },
            ];

            if (!this.billingaccounts) {
                return defaultvalue;
            }
            return defaultvalue.concat(
                this.billingaccounts
                    .map((item) => {
                        return {
                            text:
                                item.name +
                                (item.default ? " " + this.$gettext("(default)") : ""),
                            value: item.id,
                        };
                    })
                    .sort((a, b) => {
                        if (a.text < b.text) {
                            return -1;
                        }
                        if (a.text > b.text) {
                            return 1;
                        }
                        return 0;
                    })
            );
        },
    },
    methods: Object.assign(
        mapMutations("editcustomer", [
            "updateAllowedCatalogs",
            "updateAllowedStocks",
            "updateCustomerSavingErrors",
        ]),
        mapActions("mcl", ["hideModal"]),
        mapActions("editcustomer", ["saveCustomer", "init", "changeBillingAccountId"]),
        {
            toggleStock(stock) {
                let allowed_stocks = this.allowed_stocks || [];
                if (!allowed_stocks) {
                    allowed_stocks = [];
                }
                let index = allowed_stocks.indexOf(stock.id);
                if (index == -1) {
                    allowed_stocks.push(stock.id);
                } else {
                    allowed_stocks.splice(index, 1);
                }
                this.updateAllowedStocks(allowed_stocks);
            },
            toggleCatalog(catalog) {
                let allowed_catalogs = this.allowed_catalogs || [];
                if (!allowed_catalogs) {
                    allowed_catalogs = [];
                }
                let index = allowed_catalogs.indexOf(catalog.id);
                if (index == -1) {
                    allowed_catalogs.push(catalog.id);
                } else {
                    allowed_catalogs.splice(index, 1);
                }
                this.updateAllowedCatalogs(allowed_catalogs);
            },
            isCatalogAllowed(catalog) {
                if (this.isCatalogDisabled(catalog)) {
                    return true;
                }
                if (!this.allowed_catalogs) {
                    return false;
                }
                return this.allowed_catalogs.indexOf(catalog.id) != -1;
            },
            isCatalogDisabled(catalog) {
                const provider = catalog.owner;
                const providerCatalogs = this.providersCatalogs.filter(
                    (c) => c.owner == provider
                );
                if (providerCatalogs.length == 1) {
                    return true;
                }
                const defaultProviderCatalogs = providerCatalogs.filter(
                    (c) => c.effective_default
                );
                if (defaultProviderCatalogs.length == 0) {
                    return false;
                }
                return false; //catalog.effective_default;
            },
            isProviderCatalogAllowed(catalog) {
                if (!this.allowed_catalogs) {
                    return false;
                }
                return this.allowed_catalogs.indexOf(catalog.id) != -1;
            },
            isStockAllowed(stock) {
                if (!this.allowed_stocks) {
                    return false;
                }
                return this.allowed_stocks.indexOf(stock.id) != -1;
            },
            failedSavingCustomer(error) {
                this.$swal({
                    title: this.$gettext("Error"),
                    html: this.$utils.resultToSwalContent(this, error),
                    type: "error",
                });
            },
            informationsSaved(response) {
                if (response.status == 201) {
                    this.addAlert(this.$gettext("Customer created"));
                } else {
                    this.addAlert(this.$gettext("Customer updated"));
                }
                this.hideModal({ result: response.data });
            },
            cancelEditClicked() {
                this.hideModal();
            },
            async saveInformationClicked() {
                let errors = {};
                if (!this.customer.name) {
                    errors.name = [this.$gettext("This field is required")];
                }

                if (Object.keys(errors).length) {
                    this.updateCustomerSavingErrors(errors);
                    return;
                }

                this.saveCustomer({
                    instance: this.customer,
                })
                    .then(this.informationsSaved)
                    .catch(this.failedSavingCustomer);
            },
        }
    ),
    created() {
        var customer = JSON.parse(JSON.stringify(this.params.customer || {}));
        customer.billing_address = customer.billing_address || {};

        this.init({ customer });
    },
    i18n: {
        messages: {
            en: {
                inviteCustomer: "Invite customer to connect",
                createCustomer: "Manage customer myself or invite later",
                operatorCreateCustomer: "Invite customer later",

                instructionsExternal:
                    "The customer is linked to an external entity. Some information can only be updated by the customer.",
                instructionsNewCreate:
                    "You will be able to invite the customer to connect to you to automate your exchanges later.",
                instructionsNewInvite:
                    "The customer will receive an email to connect to you. Once the invitation is accepted, you will be able to schedule orders or shipments to its warehouses. It will also be able to order your products, track its shipments and access its invoices from its account.",
                operatorInstructionsNewCreate:
                    "Once created, you will have to invite the customer to connect to you so that it can send you its receipts and shipments.",
                operatorInstructionsNewInvite:
                    "The customer will receive an email to connect to you. It will define its billing information and delivery addresses and will be able to send you its receipts and shipments.",
                customerConfiguration: "Customer configuration",

                emailLabel: "Email or Safetee ID",
                emailPlaceholder: "Email or Safetee ID of your customer",
                invitationBlockTitle: "Invitation",
                commentsPlaceholder:
                    "Additional information about the customer. This information is not visible to your logistics partners.",
                myCatalogsTitle: "Sales catalogs",
                myCatalogsInstructions:
                    "Please choose the sales catalog(s) you want to assign to the customer.",
                providersCatalogsInstructions:
                    "By default, all sales catalogs from your providers are accessible to all your customers. However, if a provider shares several sales catalogs with you and designates one as default, only this one will be accessible to your customers, unless you decide to give them access to another catalog from this provider. This operation allows your providers to share several price catalogs, one of which is the default, while allowing you to choose which customers can access the other catalogs with preferential rates.",
                default: "default",
            },
            fr: {
                inviteCustomer: "Inviter le client à se connecter à vous",
                createCustomer: "Gérer le client moi-même ou l'inviter plus tard",
                operatorCreateCustomer: "Inviter le client plus tard",

                instructionsExternal:
                    "Le client est lié à une entité externe. Certaines informations ne peuvent être mises à jour que par le client lui-même.",
                instructionsNewCreate:
                    "Vous pourrez inviter le client à se connecter à vous pour automatiser vos échanges dans un second temps.",
                instructionsNewInvite:
                    "Le client recevra un email pour se connecter à vous. Une fois l'invitation acceptée, vous pourrez programmer des commandes ou expéditions vers ses entrepôts. Il pourra aussi passer commande de vos produits, suivre ses expéditions et accéder à ses factures depuis son compte.",
                operatorInstructionsNewCreate:
                    "Une fois créé, vous devrez l'inviter à se connecter à vous pour qu'il puisse vous transmettre ses réceptions et expéditions.",
                operatorInstructionsNewInvite:
                    "Le client recevra un email pour se connecter à vous. Il définira lui-même ses informations de facturation et ses adresses de livraison et pourra vous transmettre ses réceptions et expéditions.",

                emailLabel: "Email ou identifiant Safetee",
                emailPlaceholder: "Email ou identifiant Safetee de votre fournisseur",
                invitationBlockTitle: "Invitation",
                commentsPlaceholder:
                    "Informations supplémentaires sur le client. Ces informations ne sont pas visibles par vos partenaires logistiques.",
                myCatalogsTitle: "Catalogues de vente",
                myCatalogsInstructions:
                    "Veuillez choisir le(s) catalogue(s) de vente vous souhaitez assigner au client.",
                providersCatalogsInstructions:
                    "Par défaut, tous les catalogues de vente de vos fournisseurs sont accessibles à l'ensemble de vos clients. Cependant, si un fournisseur vous partage plusieurs catalogues et en désigne un par défaut, seul celui-ci sera accessible à vos clients, à moins que vous ne décidiez de leur donner accès à un autre catalogue de ce fournisseur. Ce fonctionnement permet à vos fournisseurs de partager plusieurs catalogues de prix, dont un par défaut, tout en vous laissant la possibilité de choisir quels clients peuvent accéder aux autres catalogues avec des tarifs préférentiels.",
                default: "par défaut",
            },
        },
    },
};
</script>
