var render = function render(){
  var _vm$productprovider, _vm$productprovider$p;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.editable ? _c('v-autocomplete', {
    ref: "provider",
    staticClass: "flex-grow-1",
    attrs: {
      "clearable": _vm.canPriceBeEdited,
      "disabled": !!_vm.productprovider.id,
      "error-messages": _vm.errors.provider,
      "items": _vm.providersChoices,
      "label": _vm.$gettext('Provider'),
      "menu-props": {
        closeOnClick: true
      },
      "required": true,
      "value": _vm.productprovider.provider,
      "hide-details": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateProviderId($event);
      }
    }
  }) : _c('Field', {
    staticClass: "flex-grow-1",
    attrs: {
      "distabled": true,
      "value": (_vm$productprovider = _vm.productprovider) === null || _vm$productprovider === void 0 ? void 0 : (_vm$productprovider$p = _vm$productprovider.provider_data) === null || _vm$productprovider$p === void 0 ? void 0 : _vm$productprovider$p.name,
      "label": _vm.$gettext('Provider'),
      "disabled": true,
      "error-messages": _vm.errors.provider,
      "options": _vm.providersChoices
    }
  }), _vm.productprovider.provider ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.routerPush({
          name: 'provider',
          params: {
            provider_id: _vm.productprovider.provider
          }
        });
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1) : _vm._e()], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": _vm.productprovider.price,
      "label": _vm.$gettext('Buying price (in euros, optional)'),
      "type": "number",
      "step": "0.1",
      "clearable": _vm.canPriceBeEdited,
      "hide-details": "",
      "disabled": !_vm.canPriceBeEdited,
      "error-messages": _vm.errors.price
    },
    on: {
      "change": function change($event) {
        _vm.updateProviderProductPrice(parseFloat($event));
      }
    }
  }), _vm.canBeDeleted ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1) : _vm._e()], 1)]), _vm.errors.non_field_errors ? _vm._l(_vm.errors.non_field_errors, function (error) {
    return _c('Warning', {
      key: error
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }