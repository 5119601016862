<template>
    <div>
        <v-card :elevation="1" class="flex-grow-1 d-flex flex-column">
            <v-card-title class="text-h2 error--text">
                {{ $gettext("This page does not exist") }}
            </v-card-title>
            <v-card-text>
                <template v-if="error?.code == 404">
                    <span class="text-h7">
                        {{
                            $gettext(
                                "This page does not exist. It may have been deleted or it does not belong to your organization."
                            )
                        }}
                    </span>
                </template>
                <template v-else-if="error?.code == 403">
                    <span class="text-h7">
                        {{
                            $gettext(
                                "You don't have the permission to access this ressource. Please contact your administrator."
                            )
                        }}
                    </span>
                </template>
                <template v-else>
                    <span class="text-h7">
                        {{
                            $gettext(
                                "An error occured while loading this ressource. Please contact your administrator."
                            )
                        }}
                    </span>
                </template>
                <div class="mt-5">
                    <Button
                        icon="back"
                        :text="$gettext('Back')"
                        @click="$router.back()"
                        class="mr-4"
                    />
                    <Button
                        :text="$gettext('Go to home')"
                        icon="home"
                        @click="routerPush('/')"
                        ghost
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    name: "ressource-error",
    mixins: [GlobalMixins],
    components: {},
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
</script>
