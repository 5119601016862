var render = function render(){
  var _vm$customer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-customer-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$translate("title")))]), _c('template', {
    slot: "content"
  }, [_vm.catalogs_loading ? [_c('Loading', {
    staticClass: "py-0",
    attrs: {
      "absolute": false,
      "size": 130
    }
  })] : [_vm.usemail ? [_c('div', {
    staticClass: "mb-4 link grey--text",
    on: {
      "click": _vm.searchClicked
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")]), _c('span', [_vm._v(_vm._s(_vm.$translate("backToSearch")))])], 1), _c('div', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$translate("youSelected")) + " ")]), _c('div', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$translate("emailInstructions")))]), _c('div', {
    staticClass: "d-flex mt-8"
  }, [_c('v-text-field', {
    ref: "email",
    staticClass: "flex-grow-1 mt-0 pt-0",
    attrs: {
      "value": _vm.email,
      "label": _vm.$translate('labels.email'),
      "autocomplete": "new-password",
      "required": "",
      "hide-details": "",
      "autofocus": true,
      "clearable": true,
      "name": "no-auto-fill"
    },
    on: {
      "input": _vm.updateEmail
    }
  })], 1), !((_vm$customer = _vm.customer) !== null && _vm$customer !== void 0 && _vm$customer.id) ? _c('div', {
    staticClass: "d-flex mt-8"
  }, [_c('v-text-field', {
    ref: "name",
    staticClass: "flex-grow-1 mt-0 pt-0",
    attrs: {
      "value": _vm.name,
      "type": "email",
      "label": _vm.$translate('labels.name'),
      "autocomplete": "new-password",
      "required": "",
      "hide-details": "",
      "clearable": true
    },
    on: {
      "input": _vm.updateName
    }
  })], 1) : _vm._e()] : !_vm.entity ? [_c('div', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$translate("instructions")))]), _c('div', {
    staticClass: "d-flex mt-8"
  }, [_c('v-text-field', {
    ref: "search",
    staticClass: "flex-grow-1 mt-0 pt-0",
    attrs: {
      "value": _vm.search,
      "label": _vm.$translate('labels.search'),
      "autocomplete": "new-password",
      "autofocus": "",
      "required": "",
      "hide-details": "",
      "loading": _vm.searching,
      "clearable": true
    },
    on: {
      "input": _vm.onSearchInput
    }
  })], 1), !_vm.search ? _c('div', {
    staticClass: "mt-6 font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$translate("safeteeIdUnknown")) + " "), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.useMailClicked();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("inviteByEmail")))])]) : _c('div', {
    staticClass: "mt-4"
  }, [_vm.results.length == 10 ? [_c('span', {
    staticClass: "font-italic mb-8 mt-6"
  }, [_vm._v(_vm._s(_vm.$translate("limitedResults")))])] : _vm._e(), _vm.results.length ? [_c('List', {
    attrs: {
      "items": _vm.results
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "pa-2 d-sm-flex"
        }, [_c('div', {
          staticClass: "flex-grow-1 align-center mb-4"
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(scope.item.name) + " ")]), scope.item.persona ? [_vm._v("- " + _vm._s(_vm.$utils.getPersonaLabel(scope.item.persona)))] : _vm._e(), _vm._v(" - "), _c('span', {
          staticClass: "font-italic grey--text"
        }, [_vm._v(_vm._s(scope.item.safetee_id))])], 2), _c('div', [_c('v-btn', {
          attrs: {
            "disabled": scope.item.customer || scope.item.customer_invited,
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.updateEntity(scope.item);
            }
          }
        }, [scope.item.customer ? [_vm._v(" " + _vm._s(_vm.$translate("buttons.selectEntity.connected")) + " ")] : scope.item.customer_invited ? [_vm._v(" " + _vm._s(_vm.$translate("buttons.selectEntity.invited")) + " ")] : [_vm._v(" " + _vm._s(_vm.$translate("buttons.selectEntity.select")) + " ")]], 2)], 1)])];
      }
    }], null, false, 650889449)
  }), _c('span', {
    staticClass: "font-italic"
  }, [_vm._v(_vm._s(_vm.$translate("cantFind")))]), _vm._v("  "), _c('span', {
    staticClass: "link font-italic",
    on: {
      "click": _vm.useMailClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("inviteByEmail")) + " ")])] : _c('div', {
    staticClass: "mt-6 font-italic"
  }, [_c('span', {
    staticClass: "font-italic font-weight-bold warning--text"
  }, [_vm.search.length < 4 ? [_vm._v(" " + _vm._s(_vm.$translate("moreCharsNeeded")) + " ")] : [_vm._v(" " + _vm._s(_vm.$translate("noResults")) + " ")]], 2), _vm._v("   "), _c('span', {
    staticClass: "link font-italic",
    on: {
      "click": _vm.useMailClicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("inviteByEmail")) + " ")])])], 2)] : [!_vm.params.entity ? _c('div', {
    staticClass: "mb-4 link grey--text",
    on: {
      "click": function click($event) {
        return _vm.updateEntity(null);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")]), _c('span', [_vm._v(_vm._s(_vm.$translate("backToSearch")))])], 1) : _vm._e(), _c('div', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$translate("youSelected")) + " ")]), _c('div', {
    staticClass: "text-h7"
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.entity.name,
      "label": _vm.$translate('labels.name'),
      "disabled": true,
      "hide-details": true
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.entity.safetee_id,
      "label": _vm.$translate('labels.safetee_id'),
      "disabled": true,
      "hide-details": true
    }
  })], 1)], _vm.usemail || _vm.entity ? [_vm.isCustomer ? [_c('div', {
    staticClass: "text-overline font-weight-bold mt-7"
  }, [_vm._v(" " + _vm._s(_vm.$translate("catalogsTitle")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$translate("chooseCatalogs")))]), _c('v-row', {
    staticClass: "mb-4 mt-2"
  }, _vm._l(_vm.myCatalogs, function (catalog) {
    return _c('v-col', {
      key: catalog === null || catalog === void 0 ? void 0 : catalog.id,
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-checkbox', {
      staticClass: "py-0",
      attrs: {
        "input-value": _vm.isCatalogAllowed(catalog),
        "label": catalog.name,
        "hide-details": "",
        "dense": "",
        "single-line": "",
        "outlined": ""
      },
      on: {
        "change": function change($event) {
          return _vm.toggleCatalog(catalog);
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-body-2"
          }, [_vm._v(" " + _vm._s(catalog.name) + " "), catalog["default"] ? _c('span', [_vm._v(" (" + _vm._s(_vm.$translate("default")) + ") ")]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1), _vm.providersCatalogs.length ? [_c('div', {
    staticClass: "text-overline font-weight-bold mt-7"
  }, [_vm._v(" " + _vm._s(_vm.$translate("providersCatalogsTitle")) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$translate("providersCatalogsInstructions")))]), _c('v-row', {
    staticClass: "mb-4 mt-2"
  }, _vm._l(_vm.providersCatalogs, function (provider_catalog) {
    return _c('v-col', {
      key: provider_catalog === null || provider_catalog === void 0 ? void 0 : provider_catalog.id,
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-checkbox', {
      staticClass: "py-0",
      attrs: {
        "input-value": _vm.isCatalogAllowed(provider_catalog),
        "label": provider_catalog.name,
        "hide-details": "",
        "dense": "",
        "single-line": "",
        "outlined": ""
      },
      on: {
        "change": function change($event) {
          return _vm.toggleCatalog(provider_catalog);
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', {
            staticClass: "text-body-2"
          }, [_c('b', [_vm._v(" " + _vm._s(provider_catalog.owner_data.name))]), _vm._v(" - " + _vm._s(provider_catalog.name) + " "), provider_catalog.effective_default ? _c('span', [_vm._v(" (" + _vm._s(_vm.$translate("default")) + ") ")]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)] : _vm._e()] : [_c('Select', {
    staticClass: "mt-4",
    attrs: {
      "options": _vm.catalogChoices,
      "label": _vm.$translate('labels.catalog'),
      "value": _vm.catalog,
      "hide-details": true
    },
    on: {
      "input": _vm.updateCatalog
    }
  })]] : _vm._e()]], 2), _c('template', {
    slot: "bottom"
  }, [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "text": _vm.$gettext('Cancel'),
      "ghost": ""
    },
    on: {
      "click": _vm.cancelClicked
    }
  }), _vm.entity || _vm.usemail ? _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.$translate('buttons.inviteCustomer'),
      "disabled": !_vm.canSave,
      "loading": _vm.customer_inviting
    },
    on: {
      "click": _vm.inviteClicked
    }
  }) : _vm._e()], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }