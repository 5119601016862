var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchObject', {
    ref: "search",
    attrs: {
      "additional-params": Object.assign({
        ordering: 'name'
      }, _vm.additionalParams),
      "allow-create": _vm.allowCreate,
      "autofocus": _vm.autofocus,
      "base-url": _vm.baseUrl,
      "create-label": _vm.$translate('create_a_billingaccount'),
      "disabled": _vm.disabled,
      "hide-details": _vm.hideDetails,
      "item-text": _vm.itemText,
      "label": _vm.label || _vm.$translate('select_billingaccount'),
      "readonly": _vm.readonly,
      "required": _vm.required,
      "value": _vm.value,
      "dense": _vm.dense
    },
    on: {
      "create": _vm.createBillingAccount,
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " "), item["default"] ? [_vm._v(" (" + _vm._s(_vm.$translate("default")) + ")")] : _vm._e()];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }