import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    order: {},
    order_type: 'deliverysite',
    order_saving: false,
    order_saving_errors: {},

    order_loading: false,
    order_loading_errors: {},

    delivery_date: null,

    buyer_id: null,
    buyer: null,
    buyer_loading: false,
    buyer_loading_errors: {},

    deliverysite_id: null,
    deliverysite: null,
    deliverysite_loading: false,
    deliverysite_loading_errors: {},

    updating_order_status: false,

    buyers: [],
    buyers_count: 0,
    buyers_loading: false,
    buyers_loading_errors: {},

    deliverysites: [],
    deliverysites_count: 0,
    deliverysites_loading: false,
    deliverysites_loading_errors: {},
};


const mutations = {
    updateOrder(state, order) {
        state.order = JSON.parse(JSON.stringify(order || {}));
        state.buyer_id = order.buyer;
        state.buyer = order.buyer_data;
        state.deliverysite = order.deliverysite_data;
        state.deliverysite_id = order.deliverysite;
        state.delivery_date = order.delivery_date;
    },
    updateOrderSaving(state, saving) {
        state.order_saving = saving;
    },
    updateOrderSavingErrors(state, errors) {
        state.order_saving_errors = errors;
    },
    updateOrderLoading(state, loading) {
        state.order_loading = loading;
    },
    updateOrderLoadingErrors(state, errors) {
        state.order_loading_errors = errors;
    },
    updateBuyer(state, buyer) {
        state.buyer = buyer;
        state.buyer_id = buyer?.id;
        state.order.buyer = buyer?.id;
        state.order.recipient = buyer?.id;
        state.deliverysite_id = null;
        state.deliverysite = null;
    },
    updateBuyerId(state, buyer_id) {
        state.buyer_id = buyer_id;
        state.order.buyer = buyer_id;
        state.order.recipient = buyer_id;
        state.deliverysite_id = null;
        state.deliverysite = null;
    },

    updateDeliverySiteId(state, deliverysite_id) {
        state.deliverysite_id = deliverysite_id;
        state.order.deliverysite = deliverysite_id;
    },
    updateDeliverySiteLoadingErrors(state, errors) {
        state.deliverysite_loading_errors = errors;
    },
    updateDeliverySiteLoading(state, loading) {
        state.deliverysite_loading = loading;
    },
    updateDeliverySite(state, deliverysite) {
        state.deliverysite = deliverysite;
        state.deliverysite_id = deliverysite.id;
        state.order.deliverysite = deliverysite.id;
    },

    updateBuyerLoadingErrors(state, errors) {
        state.buyer_loading_errors = errors;
    },
    updateBuyerLoading(state, loading) {
        state.buyer_loading = loading;
    },



    updateBuyers(state, buyers) {
        state.buyers = buyers;
    },
    updateBuyersCount(state, count) {
        state.buyers_count = count;
    },
    updateBuyersLoading(state, loading) {
        state.buyers_loading = loading;
    },
    updateBuyersLoadingErrors(state, errors) {
        state.buyers_loading_errors = errors;
    },

    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
        if (!state.order.deliverysite && deliverysites.length == 1) {
            state.deliverysite_id = deliverysites[0].id;
            state.order.deliverysite = deliverysites[0].id;
        }
    },
    updateDeliverySitesCount(state, count) {
        state.deliverysites_count = count;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },
    updateDeliveryDate(state, delivery_date) {
        state.order.delivery_date = delivery_date;
        state.delivery_date = delivery_date;
    },

};

const actions = {
    setBuyer({ commit, dispatch, state }, buyer) {
        commit('updateBuyer', buyer);
        dispatch('fetchDeliverySites');
    },

    saveOrder({ commit, dispatch, state }, params) {
        commit('updateOrderSaving', true);
        commit('updateOrderSavingErrors', {});

        let url = '/api/customerorders/';
        let method = axios.post;

        if (state.order.id) {
            url = `/api/customerorders/${state.order.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {

            method(url, state.order)
                .then((response) => {
                    commit('updateOrderSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);

                    commit('updateOrderSaving', false);
                    commit('updateOrderSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                });
        })

    },

    fetchBuyers({ commit, dispatch, state }, params) {
        commit('updateBuyersLoading', true);
        commit('updateBuyersLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get('/api/customers/', {
                params: { limit: 1000, ordering: "name", profile: "customer" },
            })
                .then((response) => {
                    commit('updateBuyers', response.data.results);
                    commit('updateBuyersCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {

                    let error = utils.handleError(xhr_error);
                    commit('updateBuyersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBuyersLoading', false);
                });
        });
    },

    fetchDeliverySite({ commit, dispatch, state }, params) {
        commit('updateDeliverySiteLoading', true);
        commit('updateDeliverySiteLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get(`/api/customers/${state.buyer_id}/deliverysites//${state.deliverysite_id}/`
            )
                .then((response) => {
                    commit('updateDeliverySite', response.data);
                    dispatch('fetchDeliverySites');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateDeliverySiteLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateDeliverySiteLoading', false);
                });
        });
    },

    fetchBuyer({ commit, dispatch, state }, params) {
        commit('updateDeliverySites', []);
        commit('updateBuyerLoading', true);
        commit('updateBuyerLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get(`/api/entities/${state.buyer_id}/`, {
                params: { profile: "customer" },
            }
            )
                .then((response) => {
                    commit('updateBuyer', response.data);
                    dispatch('fetchDeliverySites');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateBuyerLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBuyerLoading', false);
                });
        });
    },

    fetchDeliverySites({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_deliverysite") == -1) {
            return;
        }
        if (!state.buyer_id) {
            return
        }

        commit('updateDeliverySitesLoading', true);
        commit('updateDeliverySitesLoadingErrors', {});

        return new Promise((resolve, reject) => {

            axios.get(`/api/customers/${state.buyer_id}/deliverysites/`, {
                params: { limit: 1000, ordering: "name" },
            })
                .then((response) => {
                    commit('updateDeliverySites', response.data.results);
                    commit('updateDeliverySitesCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {

                    let error = utils.handleError(xhr_error);
                    commit('updateDeliverySitesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateDeliverySitesLoading', false);
                });
        });
    },

    initOrder({ commit, dispatch, state }, order) {
        dispatch('fetchBuyers');
        commit('updateOrder', order);

        if (order.buyer) {
            dispatch('fetchDeliverySites');
        }
    },

    setBuyerId({ commit, dispatch, state }, buyer_id) {
        commit('updateBuyerId', buyer_id);
        dispatch('fetchBuyer');
        dispatch('fetchDeliverySites');
    },

    setDeliverySiteId({ commit, dispatch, state }, deliverysite_id) {
        commit('updateDeliverySiteId', deliverysite_id);
        dispatch('fetchDeliverySite');
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
