import Vue from "vue";
import Vuex from "vuex";
//import VuexPersistence from 'vuex-persist'

import account from './module-account';
import addinvoiceitems from './module-addinvoiceitems';
import addtocatalog from './module-addtocatalog';
import addtoorder from './module-addtoorder';
import addtoproviderorder from './module-addtoproviderorder';
import addtoreceipt from './module-addtoreceipt';
import addtoshipment from './module-addtoshipment';
import adjuststock from './module-adjuststock';
import agents from './module-agents';
import allocation from './module-allocation';
import allocations from './module-allocations';
import assigncatalog from './module-assigncatalog';
import assignedcatalog from './module-assignedcatalog';
import billingaccount from './module-billingaccount';
import billingaccounts from './module-billingaccounts';
import billingstats from './module-billingstats';
import browsecatalog from './module-browsecatalog';
import catalog from './module-catalog';
import catalogentry from './module-catalogentry';
import catalogs from './module-catalogs';
import completecustomer from './module-completecustomer';
import contractline from './module-contractline';
import createordershipment from './module-createordershipment';
import customer from './module-customer';
import customerinvoice from './module-customerinvoice';
import customerinvoices from './module-customerinvoices';
import customerorders from './module-customerorders';
import customers from './module-customers';
import deliverysite from './module-deliverysite';
import deliverysites from './module-deliverysites';
import editagent from './module-editagent';
import editbillingaccount from './module-editbillingaccount';
import editcustomer from './module-editcustomer';
import editcustomerinvoice from './module-editcustomerinvoice';
import editcustomerinvoiceitem from './module-editcustomerinvoiceitem';
import editcustomerproviderinvoice from './module-editcustomerproviderinvoice';
import editcustomerorder from './module-editcustomerorder';
import editdeliverysite from './module-editdeliverysite';
import editentity from './module-editentity';
import editorderitemcount from './module-editorderitemcount';
import editproduct from './module-editproduct';
import editproductlink from './module-editproductlink';
import editprospect from './module-editprospect';
import editprovider from './module-editprovider';
import editproviderinvoice from './module-editproviderinvoice';
import editproviderorder from './module-editproviderorder';
import editproviderproduct from './module-editproviderproduct';
import editreceipt from './module-editreceipt';
import editshipment from './module-editshipment';
import editstock from './module-editstock';
import events from './module-events';
import group from './module-group';
import help from './module-help';
import importproducts from './module-importproducts';
import invitation from './module-invitation';
import invitations from './module-invitations';
import invitecustomer from './module-invitecustomer';
import inviteprospect from './module-inviteprospect';
import inviteprovider from './module-inviteprovider';
import mcl from './module-mcl';
import missingreceiptitem from './module-missingreceiptitem';
import movements from './module-movements';
import operators from './module-operators';
import order from './module-order';
import printlabels from './module-printlabels';
import product from './module-product';
import products from './module-products';
import productstrategy from './module-productstrategy';
import producttype from './module-producttype';
import producttypes from './module-producttypes';
import provider from './module-provider';
import providerinvoice from './module-providerinvoice';
import providerinvoices from './module-providerinvoices';
import providerorder from './module-providerorder';
import providerorders from './module-providerorders';
import providerproduct from './module-providerproduct';
import providers from './module-providers';
import purchaseprices from './module-purchaseprices';
import radar from './module-radar';
import receipt from './module-receipt';
import receipts from './module-receipts';
import searchcatalog from './module-searchcatalog';
import sendinvoice from './module-sendinvoice';
import sendorderinvoice from './module-sendorderinvoice';
import session from './module-session';
import settings from './module-settings';
import shipment from './module-shipment';
import shipments from './module-shipments';
import stock from './module-stock';
import stocklocation from './module-stocklocation';
import stocks from './module-stocks';
import stockstats from './module-stockstats';
import synchronizeallocations from './module-synchronize-allocations';
import synchronizecatalog from './module-synchronize-catalog';
import synchronizecustomers from './module-synchronize-customers';
import synchronizeproducts from './module-synchronize-products';
import synchronizeproviderproducts from './module-synchronize-providerproducts';
import synchronizeproviders from './module-synchronize-providers';
import synchronizestockproducts from './module-synchronize-stockproducts';
import user from './module-user';
import users from './module-users';
import validateinvoice from './module-validateinvoice';
import zoneedit from './module-zone-edit';
import zones from './module-zones';

Vue.use(Vuex);

const modules = {
    account,
    editagent,
    agents,
    addinvoiceitems,
    addtocatalog,
    addtoorder,
    addtoproviderorder,
    addtoreceipt,
    addtoshipment,
    adjuststock,
    allocation,
    allocations,
    assigncatalog,
    assignedcatalog,
    billingaccount,
    billingaccounts,
    billingstats,
    browsecatalog,
    catalog,
    catalogentry,
    catalogs,
    completecustomer,
    contractline,
    createordershipment,
    customer,
    customerinvoice,
    customerinvoices,
    customerorders,
    customers,
    deliverysite,
    deliverysites,
    editbillingaccount,
    editcustomer,
    editcustomerorder,
    editdeliverysite,
    editentity,
    editcustomerinvoice,
    editcustomerinvoiceitem,
    editcustomerproviderinvoice,
    editorderitemcount,
    editproduct,
    editprovider,
    editproviderinvoice,
    editproviderorder,
    editproviderproduct,
    editreceipt,
    editshipment,
    editprospect,
    editproductlink,
    editstock,
    events,
    group,
    help,
    importproducts,
    invitation,
    invitations,
    invitecustomer,
    inviteprovider,
    inviteprospect,
    mcl,
    missingreceiptitem,
    movements,
    operators,
    order,
    printlabels,
    product,
    products,
    productstrategy,
    producttype,
    producttypes,
    provider,
    providerinvoice,
    providerinvoices,
    providerorder,
    providerorders,
    providerproduct,
    radar,
    providers,
    purchaseprices,
    receipt,
    receipts,
    searchcatalog,
    sendinvoice,
    sendorderinvoice,
    session,
    settings,
    shipment,
    shipments,
    stock,
    stocklocation,
    stocks,
    stockstats,
    synchronizeallocations,
    synchronizecatalog,
    synchronizecustomers,
    synchronizeproducts,
    synchronizeproviderproducts,
    synchronizeproviders,
    synchronizestockproducts,
    user,
    users,
    validateinvoice,
    zoneedit,
    zones,
};

const state = {};

const mutations = {};

const actions = {};

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })

const store = new Vuex.Store({
    modules,
    state,
    mutations,
    actions,
});

export default store;
