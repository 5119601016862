import utils from "@/stores/utils";
import axios from '@/plugins/axios';
import Decimal from 'decimal.js';

const state = {
    invoice: null,
    due_date: null,
    invoice_id: null,
    invoice_date: null,
    invoice_file: null,
    invoice_file_url: null,
    invoice_data: null,
    invoice_type: 'customerinvoice',

    settings: {},
    validating_invoice_errors: {},
    validating_invoice: false,
    parsing_invoice: false,
    parsing_invoice_errors: null,
    parsing_mismatch: false,

    billingaccount: null,
    billingaccount_loading: false,
};

const getters = {
};

function computeInvoiceTotalInclVat(invoice) {
    if (invoice.total_excl_vat && invoice.total_vat) {
        return (new Decimal(invoice.total_excl_vat).plus(new Decimal(invoice.total_vat))).toNumber();
    }
    return null;
}

const mutations = {

    updateInvoice(state, invoice) {
        state.invoice = invoice;
        state.invoice_data = null;
        state.invoice_file = null;
        state.invoice_file_url = invoice?.invoice_file;
        state.invoice_date = invoice?.invoice_date;
        state.due_date = invoice?.due_date;

        state.parse_invoice = false;
        state.parsing_invoice_errors = null;
        state.parsing_mismatch = false;

        state.validating_invoice = false;
        state.validating_invoice_errors = {};
    },

    updateInvoiceType(state, invoice_type) {
        state.invoice_type = invoice_type;
    },

    updateInvoiceId(state, invoice_id) {
        state.invoice_id = invoice_id;
    },

    updateInvoiceDate(state, invoice_date) {
        state.invoice_date = invoice_date;
    },

    updateDueDate(state, due_date) {
        state.due_date = due_date;
    },


    updateValidatingInvoice(state, validating) {
        state.validating_invoice = validating;
        if (!validating) {
            state.validating_invoice_errors = {};
        }
    },

    updateValidatingInvoiceErrors(state, validating_invoice_errors) {
        state.validating_invoice_errors = validating_invoice_errors;
        state.validating_invoice = false;
    },

    updateBillingAccountLoading(state, loading) {
        state.billingaccount_loading = loading;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },


    updateInvoiceFile(state, invoice_file) {
        if (invoice_file) {
            state.invoice_file = invoice_file;
            state.invoice_file_url = URL.createObjectURL(invoice_file);
        } else {
            state.invoice_file = null;
            state.invoice_file_url = null;
        }
    },

    updateParsingInvoice(state, parsing_invoice) {
        state.parsing_invoice = parsing_invoice;
    },

    updateParsingInvoiceErrors(state, parsing_invoice_errors) {
        state.parsing_invoice_errors = parsing_invoice_errors;
    },

    updateInvoiceData(state, invoice_data) {
        let newinvoice = { ...state.invoice };
        newinvoice[invoice_data.key] = invoice_data.value;
        newinvoice.total_incl_vat = computeInvoiceTotalInclVat(newinvoice);
        state.invoice = newinvoice;
    },

    forceupdateInvoiceWithParsedData(state) {
        let newinvoice = { ...state.invoice };
        if (state.invoice_data.invoice_id) {
            newinvoice.invoice_id = state.invoice_data.invoice_id;
            state.invoice_id = state.invoice_data.invoice_id;
        }
        if (state.invoice_data.invoice_date) {
            newinvoice.invoice_date = state.invoice_data.invoice_date;
            state.invoice_date = state.invoice_data.invoice_date;
        }
        if (state.invoice_data.due_date) {
            newinvoice.due_date = state.invoice_data.due_date;
            state.due_date = state.invoice_data.due_date;
        }
        if (state.invoice.provider) {
            if (state.invoice_data.total_excl_vat) {
                newinvoice.total_excl_vat = state.invoice_data.total_excl_vat;
            }
            if (state.invoice_data.total_vat) {
                newinvoice.total_vat = state.invoice_data.total_vat;
            }
            newinvoice.total_incl_vat = computeInvoiceTotalInclVat(newinvoice);
        }
        state.invoice = newinvoice;
    },

    async updateInvoiceWithParsedData(state, invoice_data) {
        state.invoice_data = invoice_data;
        state.parsing_mismatch = false;
        const keys = ['invoice_id', 'invoice_date', 'due_date', 'total_excl_vat', 'total_vat', 'total_incl_vat'];
        let newinvoice = { ...state.invoice };
        for (let key of keys) {
            if (invoice_data[key] && state.invoice[key]) {
                if (invoice_data[key] != state.invoice[key]) {
                    state.parsing_mismatch = true;
                }
            }
        }
        for (let key of ['invoice_id', 'invoice_date', 'due_date']) {
            if (invoice_data[key] && !state.invoice[key]) {
                newinvoice[key] = invoice_data[key];
            }
        }
        state.invoice = newinvoice;
    },
};


const actions = {

    async fetchBillingAccount({ commit, dispatch, state, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.view_billingaccount") === -1) {
            return;
        }
        if (!state.invoice.biller || !state.invoice.billingaccount) {
            return;
        }
        commit('updateBillingAccountLoading', true);
        try {
            const response = await axios.get(`/api/billingaccounts/${state.invoice.billingaccount}/`);
            commit('updateBillingAccount', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit('updateBillingAccountLoading', false);
        }
    },


    async validateInvoice({ commit, state }) {
        let url = `/api/customerinvoices/${state.invoice.id}/validate/`;
        if (this.is_customer_provider_invoice) {
            url = `/api/customerorders/${state.invoice.order}/invoices/${state.invoice.id}/validate/`;
        }

        let data = new FormData();
        let options = {};

        if (state.invoice_file) {
            options.headers = { 'Content-Type': 'multipart/form-data' };
            data.append('invoice_file', state.invoice_file);
        }
        data.append('invoice_date', state.invoice_date);
        data.append('due_date', state.due_date);
        data.append('invoice_id', state.invoice_id);


        try {
            commit('updateValidatingInvoice');
            return await axios.post(url, data, options);
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateValidatingInvoiceErrors", error.details);
            throw error;
        } finally {
            commit("updateValidatingInvoice", false);
        }
    },

    async parseInvoiceFile({ commit, dispatch, state }, params) {
        commit("updateInvoiceFile", params.invoice_file);
        commit("updateParsingInvoice", true);
        let url = "/api/billing/parseinvoice/";
        try {
            let data = new FormData();
            data.append("invoice_file", params.invoice_file);
            let response = await axios.post(url, data);
            commit("updateParsingInvoice", false);
            await commit("updateInvoiceWithParsedData", response.data);
            return state.parsing_mismatch;

        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateParsingInvoiceErrors", error.details);
            commit("updateParsingInvoice", false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        commit('updateInvoice', params?.invoice);
        commit('updateInvoiceType', params?.invoice_type || 'customerinvoice');
        dispatch('fetchBillingAccount');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
