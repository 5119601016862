var render = function render(){
  var _vm$deliverysite$cust, _vm$deliverysite, _vm$deliverysite2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalContent', {
    attrs: {
      "help-content-id": "popup-deliverysite-".concat(_vm.current_entity_profile)
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.deliverysite ? _c('template', {
    slot: "content"
  }, [(_vm$deliverysite$cust = _vm.deliverysite.customer_data) !== null && _vm$deliverysite$cust !== void 0 && _vm$deliverysite$cust.external && _vm.isNew ? _c('Warning', {
    attrs: {
      "elevation": 2
    }
  }, [_vm._v(" " + _vm._s(_vm.$translate("external_customer_deliverysite_instructions")) + " ")]) : _vm._e(), _vm.deliverysite.entity_data && _vm.deliverysite.entity_data.archived ? _c('v-alert', {
    staticClass: "pa-5",
    attrs: {
      "outlined": "",
      "border": "left",
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$gettext("Customer has been archived, this delivery site cannot be modified")) + " ")]) : _vm._e(), _vm.readonly ? _c('v-alert', {
    staticClass: "pa-5",
    attrs: {
      "outlined": "",
      "border": "left",
      "type": "warning"
    }
  }, [_vm.deliverysite.entity && _vm.deliverysite.entity !== _vm.current_entity_id ? [_vm._v(" " + _vm._s(_vm.$translate("readonly_other_entity")) + " ")] : _vm.deliverysite.customer && _vm.deliverysite.customer_data.owner !== _vm.current_entity_id ? [_vm._v(" " + _vm._s(_vm.$translate("readonly_customer")) + " ")] : (_vm$deliverysite = _vm.deliverysite) !== null && _vm$deliverysite !== void 0 && _vm$deliverysite.stock ? [_vm._v(" " + _vm._s(_vm.$translate("readonly_stock")) + " ")] : (_vm$deliverysite2 = _vm.deliverysite) !== null && _vm$deliverysite2 !== void 0 && _vm$deliverysite2.archived ? [_vm._v(" " + _vm._s(_vm.$translate("readonly_archived")) + " ")] : _vm._e()], 2) : _vm._e(), _c('h6', {
    staticClass: "text-overline font-weight-bold"
  }, [_vm._v("Information")]), _c('v-text-field', {
    attrs: {
      "autofocus": "",
      "error-messages": _vm.deliverysite_errors.name,
      "label": _vm.$gettext('Name (*)'),
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.deliverysite.name,
      callback: function callback($$v) {
        _vm.$set(_vm.deliverysite, "name", $$v);
      },
      expression: "deliverysite.name"
    }
  }), _c('v-textarea', {
    attrs: {
      "error-messages": _vm.deliverysite_errors.instructions,
      "label": _vm.$translate('instructions'),
      "name": "instruction",
      "rows": "3",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.deliverysite.instructions,
      callback: function callback($$v) {
        _vm.$set(_vm.deliverysite, "instructions", $$v);
      },
      expression: "deliverysite.instructions"
    }
  }), _c('AddressForm', {
    staticClass: "mt-8",
    attrs: {
      "title": _vm.$gettext('Address'),
      "errors": _vm.deliverysite_errors.address,
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.deliverysite.address,
      callback: function callback($$v) {
        _vm.$set(_vm.deliverysite, "address", $$v);
      },
      expression: "deliverysite.address"
    }
  })], 1) : _vm._e(), _c('template', {
    slot: "bottom"
  }, [!_vm.readonly ? [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "ghost": "",
      "text": _vm.$gettext('Cancel')
    },
    on: {
      "click": _vm.hideModal
    }
  }), _c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto ml-sm-3",
    attrs: {
      "text": _vm.save_text
    },
    on: {
      "click": _vm.saveInformationClicked
    }
  })] : [_c('Button', {
    staticClass: "my-2 my-sm-4 w-100 w-sm-auto",
    attrs: {
      "ghost": "",
      "text": _vm.$gettext('Close')
    },
    on: {
      "click": _vm.hideModal
    }
  })]], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }