import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    section: null,
    invoices: [],
    invoices_filters: { offset: 0, limit: 20, ordering: '-invoice_date' },
    invoices_count: 0,
    invoices_loading: false,
    invoices_loading_errors: null,
    invoices_cancel_source: null,

    invoices_stats: {},
    invoices_stats_loading: false,
    invoices_stats_loading_errors: null,
    invoices_stats_cancel_source: null,
    selected_items: [],
};


const modeFilters = {
    draft: {
        status: 'draft',
    },
    waitingpayment: {
        status: 'validated',
        paid: false,
        due: false,
    },
    due: {
        status: 'validated',
        due: true,
        paid: false,
        credit: false,
    },
    paid: {
        status: 'validated',
        paid: true,
    },
    tovalidate: {
        status: 'tovalidate',

    },

    all: {},
};


let getModeFilters = function (section) {
    return modeFilters[section] || {};
};

const mutations = {
    updateInvoices(state, invoices) {
        state.invoices = invoices;
        // Replace selected_items instances with newly fetched products based on id field
        state.selected_items = state.selected_items.map((selected_item) => {
            return invoices.find((invoice) => invoice.id === selected_item.id) || selected_item;
        });
    },
    updateInvoicesCount(state, invoices_count) {
        state.invoices_count = invoices_count;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, invoices_filters) {
        state.invoices_filters = Object.assign(
            {
                limit: state.invoices_filters.limit || 20,
                ordering: state.invoices_filters.ordering,
            },
            getModeFilters(state.section),
            invoices_filters);
    },
    updateInvoicesCancelSource(state, source) {
        state.invoices_cancel_source = source;
    },

    updateInvoicesStats(state, invoices_stats) {
        state.invoices_stats = invoices_stats;
    },
    updateInvoicesStatsLoading(state, loading) {
        state.invoices_stats_loading = loading;
    },
    updateInvoicesStatsLoadingErrors(state, errors) {
        state.invoices_stats_loading_errors = errors;
    },
    updateInvoicesStatsCancelSource(state, source) {
        state.invoices_stats_cancel_source = source;
    },
    updateSection(state, section) {
        state.section = section;
        state.invoices = [];
        state.invoices_count = 0;
        state.invoices_loaded = false;
        state.invoices_filters = {
            offset: 0,
            limit: state.invoices_filters.limit || 20,
            search: state.invoices_filters.search,
            ordering: state.invoices_filters.ordering,
            billed: state.invoices_filters.billed,
            provider: state.invoices_filters.provider,
        };
        state.invoices_filters = Object.assign({}, state.invoices_filters, getModeFilters(state.section));
    },
    updateSelectedItems(state, items) {
        state.selected_items = items;
    },
};

const actions = {

    async validateInvoice({ commit, dispatch, state }, invoice) {
        try {
            const response = await axios.post(`/api/providerinvoices/${invoice.id}/validate/`);
            dispatch("fetchInvoices");
            dispatch("session/fetchStats", null, { root: true });
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async markInvoiceAsPaid({ commit, dispatch, state }, invoice) {
        try {
            const response = await axios.post(`/api/providerinvoices/${invoice.id}/paid/`, { paid: true });
            dispatch("fetchInvoices");
            dispatch("session/fetchStats", null, { root: true });
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async bulkAction({ commit, dispatch, state }, params) {
        try {
            let data = {
                instances: state.selected_items.map((item) => item.id),
                action: params.action,
                payload: params.payload || {},
            };
            const response = await axios.post('/api/providerinvoices/bulk/', data);
            dispatch('fetchInvoices');
            dispatch("session/fetchStats", null, { root: true });
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async changeSection({ commit, dispatch, state }, mode) {
        if (mode == state.section) {
            return;
        }
        commit("updateSection", mode);
        await dispatch("fetchInvoices");
        dispatch("session/fetchStats", null, { root: true });
    },

    exportInvoicesAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.invoices_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/providerinvoices/?" + queryparams);
    },

    exportInvoiceItemsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.invoices_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        filters.ordering = 'billing_date';
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/providerinvoiceitems/?" + queryparams);
    },


    async fetchInvoicesStats({ commit, dispatch, state }, params) {
        commit("updateInvoicesStatsLoading", true);
        commit("updateInvoicesStatsLoadingErrors", null);

        if (state.invoices_stats_cancel_source) {
            state.invoices_stats_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit("updateInvoicesStatsCancelSource", cancelSource);

        try {
            const response = await axios.get("/api/providerinvoices/stats/", {
                params: state.invoices_filters,
                cancelToken: state.invoices_stats_cancel_source?.token,
            });
            commit("updateInvoicesStats", response.data);
            commit("updateInvoicesStatsCancelSource", null);
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }

            const error = utils.handleError(xhr_error);
            commit("updateInvoicesStatsLoadingErrors", error.details);
            throw utils.handleError(xhr_error);
        } finally {
            commit("updateInvoicesStatsLoading", false);
        }
    },

    async markInvoicedAsPaid({ commit, dispatch, state }, params) {
        try {
            const response = await axios.post(
                `/api/providerinvoices/${params.invoice.id}/paid/`,
                { paid: true }
            );
            dispatch("session/fetchStats", null, { root: true });
            dispatch("billingstats/fetchAllStats", null, { root: true });
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async fetchInvoices({ commit, dispatch, state }, params) {
        commit("updateInvoicesLoading", true);
        commit("updateInvoicesLoadingErrors", null);

        if (state.invoices_cancel_source) {
            state.invoices_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit("updateInvoicesCancelSource", cancelSource);

        try {
            const response = await axios.get("/api/providerinvoices/", {
                params: state.invoices_filters,
                cancelToken: state.invoices_cancel_source?.token,
            });
            commit("updateInvoices", response.data.results);
            commit("updateInvoicesCount", response.data.count);
            commit("updateInvoicesCancelSource", null);
            commit("updateInvoicesLoading", false);
            dispatch("fetchInvoicesStats");
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }

            const error = utils.handleError(xhr_error);
            commit("updateInvoicesLoadingErrors", error.details);
            commit("updateInvoicesLoading", false);
            throw utils.handleError(xhr_error);
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
