<template>
    <div class="d-flex align-center w-100 h-100">
        <File ref="file" @input="fileSelected($event)" />
        <DropZone
            :max-files="1"
            class="h-100 w-100"
            :dragText="dragText"
            @files-dropped="filesSelected"
            @drop-error="dropErrorMessage($event)"
        >
            <Loading
                v-if="loading"
                :absolute="false"
                :size="200"
                class="w-100"
                :label="loadingText"
            />
            <Placeholder
                v-else-if="!value"
                :no-padding="true"
                :title="placeholderTitle"
                :description="placeholderDescription"
                :actions="[
                    {
                        text: placeholderActionText,
                        action: 'selectfile',
                        main: true,
                    },
                ]"
                @selectfile="selectFileClicked"
            />
            <div v-else class="w-100">
                <iframe :src="value" class="w-100" height="100%" width="100%" />
            </div>
        </DropZone>
    </div>
</template>

<script>
import Vue from "vue";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Object],
            default: null,
        },
        dragText: {
            type: String,
            default: "Drop your file here",
        },
        placeholderTitle: {
            type: String,
            default: "No file selected",
        },
        placeholderDescription: {
            type: String,
            default: "Please select a file",
        },
        placeholderActionText: {
            type: String,
            default: "Select a file",
        },
        loadingText: {
            type: String,
            default: "Loading...",
        },
    },
    methods: Object.assign({
        selectFileClicked() {
            this.$refs.file.chooseFile();
        },
        async fileSelected(file) {
            this.$emit("input", file);
        },
        filesSelected(file) {
            this.fileSelected(file[0]);
        },
    }),
};
</script>

<style scoped></style>
